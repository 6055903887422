import React, {useState, useEffect} from 'react';
import TextViewer from "../../../../../helpers/textViewer";
import '../../PrayersDetails/PrayerDetails/PrayerDetails.scss';
import DONATION_CONFIG from "../config/donationConfig";
import {Select} from "antd";
import {message} from "antd";
import calcPaymentHistory from "../../PrayersDetails/PrayerDetails/HistoryPayments/calcPaymentHistory";

const { Option } = Select;


message.config({
    top: '90%',
    duration: 2,
    maxCount: 3,
    rtl: true
});

const DonationsPanel = (props)=>{

    const { users, close, updateData, donationIndex, cloneMode, onItemChange } = props;
    let itemToRender = {};

    const editMode = (props.index !== -1) && (donationIndex !== -1);
    if(editMode){
        itemToRender = users[props.index].donations[donationIndex];
    }

    const [itemToSave, setItemToSave] = useState({...itemToRender});
    const [index, setIndex] = useState(props.index);



    //console.log("DonationsPanel: ",{users, donationIndex, itemToRender, itemToSave, index, editMode});

    useEffect(() => {
        console.log("itemToSave: ",itemToSave, index);
        if(typeof onItemChange === 'function'){
            onItemChange({...itemToSave, userIndex: index})
        }
    }, [itemToSave, index]);


    const renderField = (key)=>{

        const {keyName, text_view_name } = DONATION_CONFIG[key];
        let { default: defaultVal } = DONATION_CONFIG[key];

        if((defaultVal) && !itemToSave[keyName]) {
            if(defaultVal === 'DATE_TODAY'){
                defaultVal = new Date().toISOString().substr(0, 10)
            }

            setItemToSave({...itemToSave, [keyName]: defaultVal});
        }

        const onTextInputChange = (event, validator)=>{
            if(validator){
                if(!validator(event.target.value)) return;
            }
            setItemToSave({...itemToSave, [keyName]: (event.target.value)});
        }

        const onChooseUserClicked = (value)=>{
            setIndex(value.split('-')[1])
        }

        const onDateChange = (event)=>{
            setItemToSave({...itemToSave, [keyName]: (new Date(event.target.value)).toJSON()});
        }

        const onCheckboxChange = (event)=>{
            setItemToSave({...itemToSave, [keyName]: event.target.checked});
        }
        let input;
        switch (DONATION_CONFIG[key].type) {
            case 'select_user':
                input = <Select className={'statics-select'} dropdownMatchSelectWidth={false} showSearch
                        value={index !== -1 ? `${users[index].name} ${users[index].family}` : ''}
                                placeholder={TextViewer({text: 'who_made_donation', returnString: true, addChar: '?'})}
                                onChange={onChooseUserClicked}>
                    {editMode ? <Option key={`user-${index}`}
                                        value={`${users[index].name} ${users[index].family}-${index}`}>{`${users[index].name} ${users[index].family}`}</Option> :
                        users.map((user, index) =>
                            <Option key={`user-${index}`}
                                    value={`${user.name} ${user.family}-${index}`}>{`${user.name} ${user.family}`}</Option>)
                    }
                </Select>
                break;
            case 'selectDate':
                input = <input type={'date'} max={new Date().toISOString().substr(0, 10)} onChange={onDateChange}
                               value={itemToSave[key] ? new Date(itemToSave[key]).toISOString().substr(0, 10) : null}/>
                break;
            case 'textarea':
                input = <textarea rows={'2'} onChange={onTextInputChange} value={itemToSave[key]}/>
                break;
            case 'number':
                input = <input type={'number'} min={"0"} onChange={onTextInputChange} value={itemToSave[key] || defaultVal}/>
                break;
            case 'checkbox':
                input = <input type={'checkbox'} onChange={onCheckboxChange} checked={itemToSave[key]}/>
                break;
            default:
                input = <input onChange={onTextInputChange} value={itemToSave[key]}/>

        }

        return <div className={'filed-container'}>
            <TextViewer text={text_view_name} addChar={DONATION_CONFIG[key].required ? '* ' : ''} addBefore/>
            {input}
        </div>
    }

    const isEnabledToSaved = ()=>{
        const hasChanged =  JSON.stringify(itemToRender) !== JSON.stringify(itemToSave);
        let allRequiredFields = true;
        Object.keys(DONATION_CONFIG).forEach(key=>{
            if(key === 'user'){
                if(index === -1) allRequiredFields = false;
                else return;

            }
            if(DONATION_CONFIG[key].required && !itemToSave[key]){
                allRequiredFields = false;
            }
        });
        return hasChanged && allRequiredFields;
    }

    const onSaveClicked = ()=>{
        const updatedItems = [...users];

        const itemToUpdate = updatedItems[index]

        let { user, ...donation } = itemToSave;
        if(editMode){
            if(cloneMode){
                itemToUpdate.donations.push(donation)
            }else{
                itemToUpdate.donations[donationIndex] = donation;
            }
        }else{
            if(!itemToUpdate.donations){
                itemToUpdate.donations = [donation]
            }else{
                itemToUpdate.donations.push(donation)
            }
        }
        calcPaymentHistory({
            itemToRender,
            itemToUpdate,
            isPayed: donation.isPayed,
            subPayment: Number(donation.donationSubPayment),
            totalPayment: Number(donation.donationTotalPayment),
            type: 'תרומה',
            details: donation.donationReason
        });
        console.log("updatedItems to saved: ",updatedItems)
        updateData({fieldName: 'manager', data: updatedItems});
        close();
        message.open({
            type: 'success',
            content: TextViewer({text: 'saved', returnString: true}),
        })
    }

    return <div className={'item-details-container'}>
        <div className={'title'}>
            <TextViewer text={'add_edit_donation'}/>
        </div>

        <div className={'body'}>
            {Object.keys(DONATION_CONFIG).map((key)=>renderField(key))}
        </div>
        <div className={'bottom-buttons'}>
            <button className={'bottom-buttons-save'} disabled={!isEnabledToSaved()} onClick={onSaveClicked}>{<TextViewer text={'save'}/>}</button>
            <button className={'bottom-buttons-back'} onClick={()=>close()}><TextViewer text={'return'}/></button>
        </div>

    </div>
}

export default DonationsPanel;