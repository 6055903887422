import React, {useEffect, useState} from 'react';
import './Plasma.scss';
import PlasmaDetailedScreen from './PlasmaDetailedScreen/PlasmaDetailedScreen';
import Loading from "../Loading/Loading";
import chabadImage from '../../icons/backgrounds/chabad_low.jpg';
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper";
import PlasmaMainTitle from "./PlasmaComponents/PlasmaMainTitle/PlasmaMainTitle";
import PlasmaMainSubTitle from "./PlasmaComponents/PlasmaMainSubTitle/PlasmaMainSubTitle";
import getPlasmaSlides from './PlasmaSlider/getPlasmaSlides';
import PlasmaHeader from "./PlasmaComponents/PlasmaHeader/PlasmaHeader";
import {analyticsAddLogEvent} from "../../../../firebase/firebase";
import utils from "../../../../helpers/utils";
import QRCode from "qrcode";
import PlasmaModesSetting from './PlasmaModesSetting';
import PlasmaBackgrounds from './PlasmaBackgrounds';
import SingletoneStoreClass from "../../../../Store/Store";
import tfilotParser from "../../../../helpers/TfilotOfTheDay/tfilotParser";
const store = SingletoneStoreClass.getInstance();


const AUTO_UPDATE_DURATION = 1000 * 60 * 60// every 1 hour

function refreshAt(hours, minutes, seconds) {
    var now = new Date();
    var then = new Date();

    if(now.getHours() > hours ||
        (now.getHours() == hours && now.getMinutes() > minutes) ||
        now.getHours() == hours && now.getMinutes() == minutes && now.getSeconds() >= seconds) {
        then.setDate(now.getDate() + 1);
    }
    then.setHours(hours);
    then.setMinutes(minutes);
    then.setSeconds(seconds);

    var timeout = (then.getTime() - now.getTime());
    setTimeout(function() { window.location.reload(); }, timeout);
}

const Plasma = (props)=>{
    const { loading, base64Logo, location, name, useYeshivaTimes, plasmaSetting, updates, menuOpen, times, shabatEnterMinBeforeSunset, shabatExitMinAfterStarsCameOut, prayersHebrewDatesEvents } = props;

    const [qrCodeBase64, setQrCodeBase64] = useState(null);

    const minyanNames = utils.getTimesStatics(times).minyanNames;
    const multiScreensByMinyan = plasmaSetting?.splitScreensByMinyan && minyanNames?.length > 1;


    // console.log("------minyanNames=",minyanNames)
    const plasmaModesSetting = plasmaSetting?.plasmaModesSetting || PlasmaModesSetting;

    const [currentDate, setDate] = useState(new Date());

    analyticsAddLogEvent({eventName: 'plasma_event', params: { name: utils.getSynagogueName()}});


    useEffect(() => {
        //auto refresh app metadata
        refreshAt(23,59,0); // auto refresh the app at 23:00 every day!
        const interval = setInterval(() => {
            console.info('updating date...');
            setDate(new Date());
        }, AUTO_UPDATE_DURATION);
        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect( () => {
        const getData = async ()=>{
            const br = await QRCode.toDataURL(`${window.location.origin}/${window.location.pathname.split("/")[1]}`, {type: "png"});
            setQrCodeBase64(br);
        }

        getData();
    }, []);

    const getAmountOMinyans = ({minyanName})=>{
        const nextDayNum = new Date((new Date()).setDate(currentDate.getDate() + 1));


        const todayEvents = tfilotParser.calcTimesV2({times, date: currentDate, location, useYeshivaTimes, types:['tfila','lesson'], minyanName, shabatExitMinAfterStarsCameOut, shabatEnterMinBeforeSunset});
        const nextDayEvents = tfilotParser.calcTimesV2({times, date: nextDayNum, location, useYeshivaTimes, types:['tfila','lesson'], minyanName, shabatExitMinAfterStarsCameOut, shabatEnterMinBeforeSunset})

        const filteredTimes =  [...todayEvents, ...nextDayEvents].filter(time=>{
            if(time?.minyan === minyanName) return true;
            return false;
        });

        return filteredTimes.length;
    }


    if(loading) return <Loading/>

    if(menuOpen) return null; //hack because the slider is overflow the menu when it open :(


    const slides = getPlasmaSlides({updates, location, currentDate, qrCodeBase64, prayersHebrewDatesEvents});

    const logicalName = store.getSynagogueName();

    const backgroundImage = plasmaSetting?.backgroundName ? PlasmaBackgrounds[plasmaSetting.backgroundName] : PlasmaBackgrounds.default;

    const getFontSize = (text)=>{
        if(!text || !text.length){
            return '7vh'
        }
        if(text.length < 100){
            return '7vh'
        }
        if(text.length < 200){
            return '6vh'
        }
        if(text.length < 300){
            return '5vh'
        }
        return '4vh'
    }

    if(loading) return <Loading/>

    return <div className={'plasma-container'}
                style={{ backgroundImage: `url(${logicalName === 'beismenachem' ? chabadImage : backgroundImage})`
    }}>

        <Swiper
            speed={3000}
            centeredSlides={true}
            loop={true}
            autoplay={{
                disableOnInteraction: false, //enable for debugging
            }}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
        >
            {(multiScreensByMinyan ?
                minyanNames.map(minyanName=>{
                    if(getAmountOMinyans({minyanName}) === 0) return null;
                    if(!plasmaModesSetting.centerPage.enable) return null;
                    return <SwiperSlide data-swiper-autoplay={1000 * plasmaModesSetting.centerPage.duration}><PlasmaDetailedScreen currentDate={currentDate} base64Logo={base64Logo} location={location} name={name} loading={loading} useYeshivaTimes={useYeshivaTimes} minyanName={minyanName} shabatEnterMinBeforeSunset={shabatEnterMinBeforeSunset} shabatExitMinAfterStarsCameOut={shabatExitMinAfterStarsCameOut}/></SwiperSlide>
                }).filter(a=>a)
                    :
                [plasmaModesSetting.centerPage.enable ? <SwiperSlide data-swiper-autoplay={1000 * plasmaModesSetting.centerPage.duration}><PlasmaDetailedScreen currentDate={currentDate} base64Logo={base64Logo} location={location} name={name} loading={loading} useYeshivaTimes={useYeshivaTimes} shabatEnterMinBeforeSunset={shabatEnterMinBeforeSunset} shabatExitMinAfterStarsCameOut={shabatExitMinAfterStarsCameOut}/></SwiperSlide> : null])
                .concat(slides.map(slide=>{
                    let setting;
                    if(!plasmaModesSetting[slide.slideId]){
                        //backward for new setting that will be added
                        setting = PlasmaModesSetting[slide.slideId];
                    }else{
                        setting = plasmaModesSetting[slide.slideId];
                    }
                    const {enable, duration} = setting;
                    if(!enable) return null;
                    return <SwiperSlide data-swiper-autoplay={1000 * (slide.duration || duration)}>
                        <div className={'plasma-slider-container'}>
                            <PlasmaHeader base64Logo={base64Logo} location={location} />
                            <PlasmaMainTitle title={slide.title}/>
                            <PlasmaMainSubTitle title={slide.subTitle}/>
                            <div className={slide.className || 'plasma-slide-body'} style={{fontSize: getFontSize(slide.body)}}>{slide.body}</div>
                        </div>


                    </SwiperSlide>
                }))
            }
        </Swiper>


        <div className={'plasma-bottom-donation'}>{plasmaSetting?.plasmaDonation}</div>
    </div>

}

export default Plasma;