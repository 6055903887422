import React from "react";
import './Support.scss';
import FloatButtonDonation from "../Main/Donation/FloatButtonDonation/FloatButtonDonation";
import {ReactComponent as support_logo} from "../../../components/v2/icons/support.svg";
import utils from "../../../helpers/utils";


const Support = (props) => {

    const {text} = props;

    const onSupportClick = ()=> {
        utils.feedbackApp(text || `שלום! %0A איך ניתן לעזור ?`);
    }

    return <FloatButtonDonation loading={false} genericObj={{onClick: onSupportClick, textViewer: 'needs_help', icon: support_logo}}/>
}

export default Support;