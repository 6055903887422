import React, {useEffect, useState} from 'react';
import './PersonalHebrewDatesManagerItem.scss';
import TextViewer from "../../../../helpers/textViewer";
import editIcon from "../../icons/edit.svg";
import deleteIcon from "../../icons/delete.svg";
import saveIcon from "../../icons/save.svg";

import PHDMConfig from '../config';
import config from "../../../../config";
import SingletoneStoreClass from "../../../../Store/Store";
const { EVENT_TYPES } = PHDMConfig;

const currentDate = new Date();
const store = SingletoneStoreClass.getInstance();


const PersonalHebrewDatesManagerItem = (props)=>{

    const {index, hebrewDatesManager, renderItems} = props

    const [type, setType] = useState(props.type);
    const [name, setName] = useState(props.name);
    const [hebrewNumToDay, setHebrewNumToDay] = useState(props.hebrewNumToDay);
    const [hebrewNumToMonth, setHebrewNumToMonth] = useState(props.hebrewNumToMonth);
    const [description, setDescription] = useState(props.description);

    const [editMode, setEditMode] = useState(false);

    const gregDate = store.convertHebrewDate({hebrewMonthName: hebrewNumToMonth, hebrewMonthDay: hebrewNumToDay});

    // useEffect(() => {
    //     console.log("[DEBUGGER - PersonalHebrewDatesManagerItem]: ",{type, name, hebrewNumToDay, hebrewNumToMonth, description, editMode, index, hebrewDatesManager})
    // }, [type, name, hebrewNumToDay, hebrewNumToMonth, description, editMode, index, hebrewDatesManager]);

    const renderValueEditMode = ({value, typeName})=>{

        switch (typeName){
            case 'EVENT_TYPES':
                return <select className={'item-value'} onChange={(event)=>setType(event.target.value)} value={type}>
                    {Object.values(EVENT_TYPES).map(option=><option value={option.id}>{TextViewer({text:option.text_view_name, returnString:true})}</option>)}
                </select>
                break;
            case 'HEBREW_DAY':
                return <select className={'item-value'} onChange={(event)=>setHebrewNumToDay(event.target.value)} value={hebrewNumToDay}>
                    {Object.values(config.hebrewNumToDay).map(option=><option value={option}>{option}</option>)}
                </select>
                break;
            case 'HEBREW_MONTH':
                return <select className={'item-value'} onChange={(event)=>setHebrewNumToMonth(event.target.value)} value={hebrewNumToMonth}>
                    {Object.values(config.hebrewNumToMonth).map(option=><option value={option}>{option}</option>)}
                </select>
                break;
            case 'STRING_NAME':
                return <input className={'item-value'} value={value} onChange={(event)=>setName(event.target.value)}/>
                break;
            case 'STRING_DESCRIPTION':
                return <input className={'item-value'} value={description} onChange={(event)=>setDescription(event.target.value)}/>
                break;
        }
    }

    const renderCalculationDaysLeft= ()=>{
        if(editMode) return null;

        let differenceInTime = gregDate.getTime() - currentDate.getTime();
        let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
        let value = '';
        if(differenceInDays < 0){
            const nextYearGreg = store.convertHebrewDate({hebrewMonthName: hebrewNumToMonth, hebrewMonthDay: hebrewNumToDay, nextYear:true});
            let differenceInTime2 = nextYearGreg.getTime() - currentDate.getTime();
            let differenceInDays2 = Math.floor(differenceInTime2 / (1000 * 3600 * 24));
            value = `${differenceInDays2} ימים`;
        }
        if(differenceInDays === 0){
            value = 'היום!'
        }
        if(differenceInDays > 0){
            value = `${differenceInDays} ימים`;
        }

        return <div className={'item-label-value'}>
            <div className={'item-label'}><TextViewer text={'days_left'} addChar={':'}/></div>
            <div className={'item-value'}>{value}</div>
        </div>
    }


    const renderKeyValue = ({value, text_view, typeName})=> <div className={'item-label-value'}>
        {value || editMode ? <div className={'item-label'}><TextViewer text={text_view} addChar={':'}/></div> : null}
        {editMode ? renderValueEditMode({value, typeName}) : <div className={'item-value'}>{EVENT_TYPES[value] ? <TextViewer text={EVENT_TYPES[value].text_view_name}/> : value}</div>}
    </div>

    const onEditClicked = ()=>{
        setEditMode(true);
    }

    const onSaveClicked = ()=>{
        setEditMode(false);
        hebrewDatesManager.saveEvent({index, event: {type, name, hebrewNumToDay, hebrewNumToMonth, description}});
    }

    const onDeleteClicked = ()=>{
        const text = TextViewer({text: 'are_u_sure_delete_date_item', addChar: '?', returnString: true});
        if (window.confirm(text.replace('X', TextViewer({text:EVENT_TYPES[type].text_view_name, returnString:true}))) == true) {
            hebrewDatesManager.deleteEvent({index});
            renderItems();
        }
    }

    return <div className={'personal-hebrew-dates-manager-item'}>
        <div className={'hebrew-dates-manager-item-actions-bar'}>
            <div className={'hebrew-dates-manager-item-action-bar'}>
                {editMode ? <React.Fragment><TextViewer text={'save'} onClick={onSaveClicked}/>
                    <img src={saveIcon}/></React.Fragment> :
                    <React.Fragment><TextViewer text={'edit'} onClick={onEditClicked}/>
                    <img src={editIcon}/></React.Fragment>}

            </div>
            <div className={'hebrew-dates-manager-item-action-bar'} onClick={onDeleteClicked}>
                <TextViewer text={'delete'}/>
                <img src={deleteIcon}/>
            </div>
        </div>
        <div className={'hebrew-dates-manager-item-key-values'}>
            {renderKeyValue({value: type, text_view: 'type_s', typeName: 'EVENT_TYPES'})}
            {renderKeyValue({value: name, text_view: 'name', typeName: 'STRING_NAME'})}
            {renderKeyValue({value: hebrewNumToDay, text_view: 'day', typeName: 'HEBREW_DAY'})}
            {renderKeyValue({value: hebrewNumToMonth, text_view: 'month', typeName: 'HEBREW_MONTH'})}
            {renderKeyValue({value: description, text_view: 'description', typeName: 'STRING_DESCRIPTION'})}
            {renderCalculationDaysLeft()}
        </div>

    </div>
}

export default PersonalHebrewDatesManagerItem;