import React, {useEffect, useState} from 'react';
import './Kids.scss';
import TextViewer from "../../../helpers/textViewer";
import Matrix from "./Matrix";
import SingletoneStoreClass from "../../../Store/Store";
import config from './config';
import Loading from "../Main/Loading/Loading";
import championshipIcon from '../icons/championship.svg';
import Scores from './Scores/Scores'
import utils from "../../../helpers/utils";
import {analyticsAddLogEvent} from "../../../firebase/firebase";
const successSound = require('../icons/music/success.mp3');
const clickSound = require('../icons/music/click.wav');

let defaultWords = ["מצוות","דברים","מסע","שבטים","לוי","שבטים","ישראל","אהרון","משה"]


const store = SingletoneStoreClass.getInstance();

const Kids = (props)=> {

    const successSoundAudio = new Audio(successSound);
    const clickSoundAudio = new Audio(clickSound);

    const currentParasha = store.getParasha(null, new Date());
    let words;
    if(config[currentParasha]) words = JSON.parse(JSON.stringify([...config[currentParasha].words]));
    else words = JSON.parse(JSON.stringify(defaultWords));
    //debugger:
    // const words = ["אבא"];

    let matrix = Matrix.getInstance({words});

    const [matrixMap, setMatrixMap] = useState(matrix.getMatrixMap());
    const [unMatchedWords, setUnMatchedWords] = useState([]);
    const [seconds, setSeconds] = useState(matrix.seconds);
    const [loading, setLoading] = useState(false);
    const [viewScores, setViewScores] = useState(false);
    const [scoreHaveSubmit, setScoreHaveSubmit] = useState(false);
    const [playerName, setPlayerName] = useState(localStorage.getItem('gameName') || '');

    const updateUnMatchedWords = ()=>{
        const updateUnMatchedWords = [];
        matrix.getUnMatchedWords().forEach(word=>{
            if(word.endsWith('כ')) word = word.slice(0, -1) + 'ך';
            if(word.endsWith('מ')) word = word.slice(0, -1) +  'ם';
            if(word.endsWith('נ')) word = word.slice(0, -1) +  'ן';
            if(word.endsWith('פ')) word = word.slice(0, -1) +  'ף';
            if(word.endsWith('צ')) word = word.slice(0, -1) +  'ץ';
            updateUnMatchedWords.push(word);
        })
        setUnMatchedWords(updateUnMatchedWords);
    }

    useEffect(() => {
        // console.log("matrixMap:",matrixMap);
        updateUnMatchedWords();
        setInterval(()=>{
            setSeconds(matrix.seconds)
        },1000)
    }, [matrixMap]);


    const onCellClicked = ({value, rowNum, columnNum, element})=>{
        if(unMatchedWords.length === 0) return;

        clickSoundAudio.play();


        const {allowed, matched} =  matrix.isAllowedToClick({rowNum, columnNum});

        if(allowed){
            const { matrixMap: newMatrixMap, newWordMatched } = matrix.onCellClicked({value, rowNum, columnNum});
            if(newWordMatched) successSoundAudio.play();
            setMatrixMap({...newMatrixMap});
        }if(!allowed && !matched){
            //refresh and start again
            matrix.refreshClick();
            onCellClicked({value, rowNum, columnNum, element});
        }

        updateUnMatchedWords();
    }


    if(words.length === 0){
        return <div>
            לא זמין כרגע.
        </div>
    }

    const renderTime = ()=>{
        let string = TextViewer({text: "time", returnString: true, addChar: ': '});
        if(seconds < 60){
            string += seconds + TextViewer({text: "seconds", returnString: true, addChar: ' ', addBefore: true});
        }else{
            string += `${Math.floor(seconds/60)}:${Math.round(seconds%60) < 10 ? `0${Math.round(seconds%60)}`: Math.round(seconds%60)}` + TextViewer({text: "minutes", returnString: true, addChar: ' ', addBefore: true});
        }
        return string;
    }

    const onChangePlayerName = (event)=>{
        const name = event.target.value;
        if((name === '' || typeof name === 'string') && name.length <=15){
            setPlayerName(name);
            localStorage.setItem('gameName',name);
        }
    }

    const onSubmitGameScore = async ()=>{
        analyticsAddLogEvent({eventName: 'game_word_search_submit_score', params: { name: utils.getSynagogueName()}});

        setLoading(true);
        await store.postGameScore({
            synagogueId: store.getSynagogueId(),
            synagogueLogicName:store.getSynagogueName(),
            synagogueName: (await store.getSynagoguePublicValues({keys: ['name'] })).name,
            userId: utils.getUserId(),
            gameDurationSeconds: matrix.seconds,
            verificationCode:matrix.seconds * 5,
            parasha: currentParasha,
            name: playerName,
            type: "word-search"
        });
        setScoreHaveSubmit(true);
        setLoading(false);
        setViewScores(true);
    }
    const gameFinish = ()=>{
        if(unMatchedWords.length === 0){
            //game have finished:
            return <div className={'well-done'}>
                <TextViewer text={"well_done"} addChar={'!'}/>
                {!scoreHaveSubmit ? <React.Fragment>
                    <img className={'well-done-icon'} src={championshipIcon} alt="icon"/>
                    <input value={playerName}
                           onChange={onChangePlayerName}
                           placeholder={TextViewer({text: "player_name", returnString: true})}/>
                    <div className={'send-score'} onClick={onSubmitGameScore}>
                        <TextViewer text={"send"}/>
                    </div>
                </React.Fragment> : null}
            </div>
        }
    }

    const onNewGameClicked = ()=>{
        setLoading(true);
        setScoreHaveSubmit(false);
        // matrix.refreshGame({words: [...config[currentParasha].words]});
        matrix.refreshGame({words: JSON.parse(JSON.stringify(words))});
        setMatrixMap(matrix.getMatrixMap());
        setTimeout(()=>setLoading(false),500);
    }

    if(loading) return <Loading/>


    return <div>
        <div className={'welcome-title'}>
            <TextViewer text={"word_search"} addedString={currentParasha} addChar={' '}/>
        </div>
        <div className={'game-actions'}>
            <div className={'game-action'}>
                <TextViewer text={"new_game"} onClick={onNewGameClicked}/>
            </div>
            <div className={'game-action'} onClick={()=>{setViewScores(true)}}>
                <TextViewer text={"scores"}/>
            </div>
        </div>
        {gameFinish()}
        {<Scores isModalVisible={viewScores} setIsModalVisible={setViewScores} currentParasha={currentParasha} finishGameScoreSeconds={unMatchedWords.length === 0 ? matrix.seconds : null}/>}

        <div className={'top-level-container'}>
            <div className={'un-matched-words-container'}>
                {unMatchedWords.map(unMatchedWord=><div className={'un-matched-word'}>{unMatchedWord}</div>)}

            </div>
            <div className={'time-to-game'}>
                {renderTime()}
            </div>
        </div>


        <div className={'word-search-container'}>
            {matrix.getMatrix().map((rowArray, rowNum)=>
                <div className={'word-search-row'} id={`row-${rowNum}`}>
                    {matrix.getMatrix()[rowNum].map((value, columnNum)=>
                        <div className={`word-search-column ${matrixMap[`${rowNum},${columnNum}`]}`}
                             id={`${rowNum}-column-${columnNum}`}
                             onClick={(element)=>onCellClicked({value, rowNum, columnNum, element})}
                        >{value}
                        </div>)}
                </div>)}
        </div>
    </div>

}

export default Kids;