import React, {useState} from 'react';

import {Upload, message} from 'antd';
import {LoadingOutlined, PlusOutlined, DeleteOutlined} from '@ant-design/icons';
import './uploadAttachments.scss'
import {uploadFile} from '../../../../firebase/firebase';
import Button from "antd/es/button";

import SingletoneStoreClass from "../../../../Store/Store";
const store = SingletoneStoreClass.getInstance();

function resizeBase64Img(base64Str, maxWidth = 100, maxHeight = 100) {
    return new Promise((resolve) => {
        let img = new Image()
        img.src = base64Str
        img.onload = () => {
            let canvas = document.createElement('canvas')
            const MAX_WIDTH = maxWidth
            const MAX_HEIGHT = maxHeight
            let width = img.width
            let height = img.height

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width
                    width = MAX_WIDTH
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height
                    height = MAX_HEIGHT
                }
            }
            canvas.width = width
            canvas.height = height
            let ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0, width, height)
            resolve(canvas.toDataURL())
        }
    })
}

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

const UploadAttachments = (props) => {
    const [loading, setLoading] = useState(false);
    const [gotFailure, setGotFailure] = useState(false);

    const { onTextChange, attachmentUrl, base64Mode = false } = props;

    const beforeUpload = (file) =>{
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'|| file.type === 'application/pdf' || file.type === 'image/gif';
        if (!isJpgOrPng) {
            message.error('ניתן להעלות תמונות בפורמט JPG,PNG או PDF בלבד');
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error('גודל הקובץ חייב להיות פחות מ 5MB !');
        }
        setGotFailure(!(isJpgOrPng && isLt5M));
        return isJpgOrPng && isLt5M;
    }


    const handleChange = async info => {
        setLoading(true);
        if(base64Mode){
            getBase64(info.file.originFileObj, async base64Logo => {
                console.log("typeof base64Logo", typeof base64Logo)
                const lowResolutionImg = await resizeBase64Img(base64Logo)
                onTextChange(lowResolutionImg);
            });
        }else {
            let attachmentUrl = await uploadFile({file: info.file.originFileObj, name: `${store.getSynagogueName()}_${new Date().getTime()}_${info.file.name}`});
            onTextChange(attachmentUrl);
        }

        setLoading(false);
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div className="ant-upload-text">העלה קובץ (תמונה או pdf)</div>
        </div>
    );

    const onDeleteClick = async () => {
        setLoading(true);
        onTextChange(undefined);
        message.success('המודעה נמחקה בהצלחה');
        setLoading(false);
    };


    const renderImg = (url)=>{
        if(url && !loading && !gotFailure){
            if(url.includes('pdf')){
                return <iframe src={url}/>
            }else{
                return <img src={url} alt="avatar" style={{width: '100%'}}/>
            }
        }
        return uploadButton;
    }

    return (
        <div className={'upload-container'}>
            <Upload
                name="avatar"
                listType="picture-card"
                className="uploader-synagogue-logo"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
            >
                {renderImg(attachmentUrl)}
            </Upload>

            <Button disabled={!attachmentUrl} className={'delete-logo-btn'} onClick={onDeleteClick} icon={<DeleteOutlined/>}>מחק קובץ</Button>
        </div>
    );
};

export default UploadAttachments;