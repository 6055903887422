import React, {useEffect, useState} from 'react';
import './Calendar.scss';
import SingletoneStoreClass from "../../../../Store/Store";
import TextViewer from "../../../../helpers/textViewer";
import SelectDate from './SelectDate/SelectDate';
import getDateRanges from './DateUtils/dateRanges';
import Filter from './Filter/Filter';
import DisplayEventsByDate from './DisplayEventsByDate/DisplayEventsByDate';
import calendarConfig from './DateUtils/config';
import tfilotParser from "../../../../helpers/TfilotOfTheDay/tfilotParser";


const store = SingletoneStoreClass.getInstance();

const {currentDate, datesToShow} = getDateRanges();


const Calendar = (props)=>{

    const {loading, location} = props;

    const [selectedDateJSON, setSelectedDateJSON] = useState(currentDate.toJSON());
    const [filters, setFilters] = useState([calendarConfig.FILTERS.prayers.name, calendarConfig.FILTERS.studies.name]);


    useEffect(() => {
        const fetchData = async ()=>{
            let { times, extraDetails } = await store.getSynagoguePublicValues({keys: ['extraDetails','times']});
            const {useYeshivaTimes, shabatExitMinAfterStarsCameOut, shabatEnterMinBeforeSunset} = extraDetails
            const tfilotOfTheDay = tfilotParser.calcTimesV2({times, date : new Date(), location, useYeshivaTimes, types:['tfila'],shabatExitMinAfterStarsCameOut, shabatEnterMinBeforeSunset})
            if(tfilotOfTheDay.length === 0){
                setFilters([calendarConfig.FILTERS.dayTimes.name]);
            }else{
                setFilters([calendarConfig.FILTERS.prayers.name, calendarConfig.FILTERS.studies.name]);
            }
        };
        fetchData();
    }, []);


    if(loading){
        return  <TextViewer className={'calendar-loading'} text={"loading"} addedString={'...'}/>
    }

    return <div className={'calendar-container'}>
        <Filter filters={filters} setFilters={setFilters} setSelectedDateJSON={setSelectedDateJSON} currentDate={currentDate}/>
        <SelectDate dates={datesToShow} location={location} selectedDateJSON={selectedDateJSON} setSelectedDateJSON={setSelectedDateJSON} currentDate={currentDate}/>
        <DisplayEventsByDate filters={filters} date={new Date(selectedDateJSON)}/>
    </div>
}

export default Calendar;