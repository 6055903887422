import React, {useState, useEffect} from 'react';
import './HistoryPayments.scss';
import TextViewer from "../../../../../../helpers/textViewer";
import moment from "moment/moment";

const HistoryPayments = (props)=>{

    const { historyPayments } = props

    if(!historyPayments || !Array.isArray(historyPayments)) return null;

    console.log("---historyPayments:",historyPayments)
    return <React.Fragment>
        <div>
            <TextViewer text={'payment_history'} addChar={':'}/>
        </div>
        <div className={'history-payments-container'}>
            {historyPayments.sort((a,b)=>new Date(b.date) - new Date(a.date)).map(historyPayment=><div className={'history-payment'}>
                <div className={'key_value'}>
                    <div className={'key'}> <TextViewer text={'amount'} addChar={':'}/></div>
                    <div className={'value'}>{historyPayment.amount}</div>
                </div>
                <div className={'key_value'}>
                    <div className={'key'}> <TextViewer text={'payment_date'} addChar={':'}/></div>
                    <div className={'value'}>{moment(historyPayment.date).format("h:mm:ss, DD.MM.YYYY")}</div>
                </div>
                <div className={'key_value'}>
                    <div className={'key'}> <TextViewer text={'association_type'} addChar={':'}/></div>
                    <div className={'value'}>{historyPayment.type}</div>
                </div>
                <div className={'key_value'}>
                    <div className={'key'}> <TextViewer text={'extra_details'} addChar={':'}/></div>
                    <div className={'value'}>{historyPayment.details || '-'}</div>
                </div>

            </div>)}
        </div>
    </React.Fragment>
}

export default HistoryPayments;