import React, {useEffect, useState} from 'react';
import './FloatButtonDonation.scss';
import TextViewer from "../../../../../helpers/textViewer";
import utils from "../../../../../helpers/utils";
import {Link} from 'react-router-dom';
import {ReactComponent as donate_logo} from "../../../icons/donate.svg";
import Icon from "@ant-design/icons";

const FloatButtonDonation = (props)=>{

    const {donationObj, loading, genericObj} = props;

    const [minimizeButton, setMinimizeButton] = useState(false);

    document.addEventListener("scroll", (event) => {
        // console.log("-----scroll:",event)

        if(window.pageYOffset === 0) {
            //scroll to top page
            // console.log('I AM AT THE TOP');
            if(minimizeButton) setMinimizeButton(false);
        }else{
            //scroll (but not to the top of the page)
            if(!minimizeButton) setMinimizeButton(true);
        }
    });

    if(loading) return null;

    if(donationObj && (donationObj.url || donationObj.paybox || donationObj.extra)){
        return  <Link to={`/${utils.getSynagogueName()}/donation_v2`}>
            <div className={`float-button-donation-container ${minimizeButton ? 'minimize' : ''}`}>
                <Icon component={donate_logo} className={'donate-icon'}/>
                {minimizeButton ? null : <React.Fragment>
                    <div className={'empty-donate-area'}></div>
                    <TextViewer text={'donation'}/>
                </React.Fragment>}

            </div>
        </Link>
    }

    if(genericObj && genericObj.onClick && genericObj.textViewer && genericObj.icon){
        return  <a onClick={genericObj.onClick}>
            <div className={`float-button-donation-container ${minimizeButton ? 'minimize' : ''}`}>
                <Icon component={genericObj.icon} className={'donate-icon'}/>
                {minimizeButton ? null : <React.Fragment>
                    <div className={'empty-donate-area'}></div>
                    <TextViewer text={genericObj.textViewer}/>
                </React.Fragment>}

            </div>
        </a>
    }

    return null;


}

export default FloatButtonDonation;