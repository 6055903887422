import React, {useState, useEffect, useRef} from 'react';
import TextViewer from "../../../../../helpers/textViewer";
import INVOICE_CONFIG from "../../Invoices/config/invoiceConfig";
import {message, Select} from "antd";
import Loading from "../../../Main/Loading/Loading";
import SingletoneStoreClass from "../../../../../Store/Store";
import utils from "../../../../../helpers/utils";
import SignatureCanvas from 'react-signature-canvas'
import InvoiceGenerator from "../../../PDFGenerator/InvoiceGenerator";


const store = SingletoneStoreClass.getInstance();

const getDefaultInvoice = ()=>{
    let invoice = {};
    Object.keys(INVOICE_CONFIG).forEach(key=>{
        if(INVOICE_CONFIG[key].editable){
            invoice[key] = null;
        }
    })

    if(localStorage.getItem('defaultInvoice')){
        invoice = {...invoice, ...JSON.parse(localStorage.getItem('defaultInvoice'))};
    }

    return invoice;
}

const Invoice = (props)=>{
    const { setCreateNewInvoiceMode, users, synagogueName, fetchData, base64Logo } = props;
    const [invoice, setInvoice] = useState(getDefaultInvoice());
    const [dynamicOption, setDynamicOption] = useState(null);
    const [saveMsgWarn, setSaveMsgWarn] = useState('');
    const [loading, setLoading] = useState(false);

    const signatureRef = useRef(null);


    // useEffect(() => {
    //     console.log("---invoice:",invoice)
    // }, [invoice]);



    const onCreateInvoice = async ()=>{
        setLoading(true);
        try{
            const newInvoice = await store.createNewInvoice({invoice: {...invoice, synagogueLogicName: utils.getSynagogueName(), synagogueName, type: [invoice.type]}});
            message.open({
                type: 'success',
                duration: 10,
                content: TextViewer({text: 'new_invoice_x_have_successfully_created', returnString: true}).replace('x', ` ${newInvoice.id} `),
            })


            // console.log("----newInvoice:",newInvoice)

            const invoiceGenerator = new InvoiceGenerator({
                base64Logo,
                invoice: newInvoice
            });
            await invoiceGenerator.init();
            invoiceGenerator.makePDF();

            await fetchData();

            setCreateNewInvoiceMode(false);


            const defaultInvoice = JSON.parse(localStorage.getItem('defaultInvoice'));
            Object.keys(INVOICE_CONFIG).forEach(key=>{
                if(INVOICE_CONFIG[key].dontSave){
                    delete defaultInvoice[key];
                }
            })

            localStorage.setItem('defaultInvoice', JSON.stringify({...defaultInvoice}));




        }catch (e){
            console.error('got error while creating invoice: ',e)
            message.open({
                type: 'error',
                duration: 10,
                content: TextViewer({text: 'error', returnString: true}),
            })
        }finally {
            setLoading(false);
        }



    }

    useEffect(() => {
        let enable = true;
        let requiredField = '';
        Object.values(INVOICE_CONFIG).forEach(config=>{
            if(config.required && !invoice[config.keyName]){
                enable = false;
                if(!requiredField){
                    requiredField = TextViewer({text: config.text_view_name, returnString: true});
                    requiredField = TextViewer({text: 'please_fill_the_filed', returnString: true, addedString: requiredField});
                }
            }
            if(typeof config.validation === 'function' && !config.validation(invoice[config.keyName])){
                enable = false;
                if(!requiredField){
                    requiredField = TextViewer({text: config.text_view_name, returnString: true});
                    requiredField = TextViewer({text: 'please_fill_the_filed', returnString: true, addedString: requiredField});
                }
            }
        })
        setSaveMsgWarn(requiredField);

        }, [invoice]);

    const renderField = (key)=>{
        const {keyName, text_view_name, validator, additionalFieldType } = INVOICE_CONFIG[key];
        let { default: defaultVal , defaultMsg, textReviewReplace, warnMsg} = INVOICE_CONFIG[key];

        if(!INVOICE_CONFIG[key].editable){
            return null;
        }


        const onTextInputChange = (event, validator, chooseUser = false)=>{
            if(validator){
                if(!validator(event.target.value)) return;
            }
            let valueToSave = typeof event === 'string' ? event : event.target.value;
            if(chooseUser){
                const [userFullName, userEmail] = valueToSave.split('_')
                valueToSave = userFullName;
                setInvoice({...invoice, [keyName]: (valueToSave), donorEmail: userEmail || ''});
            }else{
                setInvoice({...invoice, [keyName]: (valueToSave)});
                localStorage.setItem('defaultInvoice', JSON.stringify({...invoice, [keyName]: (valueToSave)}))
            }

        }

        const onCheckboxChange = (event, validator)=>{
            if(validator){
                if(!validator(event.target.value)) return;
            }
            let valueToSave = event.target.checked
            setInvoice({...invoice, [keyName]: (valueToSave)});
            localStorage.setItem('defaultInvoice', JSON.stringify({...invoice, [keyName]: (valueToSave)}));
        }

        const onSignatureEnd = ()=>{
            let valueToSave = signatureRef.current.getTrimmedCanvas().toDataURL();
            setInvoice({...invoice, [keyName]: (valueToSave)});
            localStorage.setItem('defaultInvoice', JSON.stringify({...invoice, [keyName]: (valueToSave)}));
        }


        let input;
        switch (INVOICE_CONFIG[key].type) {
            case 'textarea':
                input = <textarea rows={'2'} onChange={(event)=>onTextInputChange(event, validator)} value={invoice[key]}/>
                break;
            case 'number':
                input = <input type={'number'} min={"0"} max={'20000'} onChange={(event)=>onTextInputChange(event, validator)} value={invoice[key]}/>
                break;
            case 'select':
                input = <select onChange={(event)=>onTextInputChange(event, validator)} value={invoice[key]}>
                    {<option disabled="disabled" selected="selected">{defaultMsg}</option>}
                    {INVOICE_CONFIG[key].options.map(option=><option>{option}</option>)}
                </select>
                break;
            case 'checkbox':
                input = <input type={'checkbox'} onChange={onCheckboxChange} checked={invoice[key]}/>
                break;
            case 'select_user':
                input = <Select className={'statics-select'} dropdownMatchSelectWidth={false} showSearch
                                value={invoice[key]}
                                onChange={(event)=>{onTextInputChange(event, validator, true)}}
                                onSearch={(event)=>{setDynamicOption(event)}}>
                    {users.map((user, index) =>
                        <Select.Option key={`user-${index}`}
                                value={`${user.name} ${user.family}${user.email ? `_${user.email}` : ''}`}>{`${user.name} ${user.family}`}</Select.Option>)}
                    {dynamicOption ?  <Select.Option key={`user-dynamicOption`} value={dynamicOption}>{dynamicOption}</Select.Option> : null}
                </Select>
                break;
            case 'signature':
                input = <React.Fragment>
                    <SignatureCanvas ref={signatureRef} penColor='#058ED9'
                                     canvasProps={{className: 'sign-digital'}}
                                     onEnd={onSignatureEnd}/>
                    { invoice[key] ?<div className={'clean-preview-signature'}>
                        <button className={'clean-signature'}>
                            <TextViewer text={'clean_signature'} onClick={()=>{
                                signatureRef.current.clear();

                                const currentInvoice = JSON.parse(JSON.stringify(invoice));
                                delete currentInvoice.signature;

                                setInvoice({...currentInvoice});
                                localStorage.setItem('defaultInvoice', JSON.stringify({...currentInvoice}));

                            }}/>
                        </button>
                        <img className={'saved-signature'} src={invoice[key]}/>
                    </div> : null}

                </React.Fragment>
                break;
            default:
                input = <input onChange={(event)=>onTextInputChange(event, validator)} value={invoice[key]}/>

        }

        let keyText = TextViewer({text:text_view_name, addChar: INVOICE_CONFIG[key].required ? '* ' : '', addBefore: true, returnString: true});

        if(textReviewReplace && invoice[textReviewReplace.by]){
            keyText = keyText.replace(textReviewReplace.first, invoice[textReviewReplace.by]);
        }

        return <div className={'filed-container'}>
            <TextViewer text={keyText}/>
            {input}
            {warnMsg ? <TextViewer className={'warn-msg'} text={warnMsg}/> : null}
        </div>
    }

    const isEnabledToSaved = ()=>{
        let allRequiredFields = true;
        Object.keys(INVOICE_CONFIG).forEach(key=>{
            if(INVOICE_CONFIG[key].required && !invoice[key]){
                allRequiredFields = false;
            }
        });
        return allRequiredFields &&!saveMsgWarn;
    }

    if(loading) return <Loading/>

    return <div className={'item-details-container'}>
        <div className={'title'}>
            <TextViewer text={'create_invoice'}/>
        </div>

        <div className={'body'}>
            {Object.keys(INVOICE_CONFIG).map((key)=>renderField(key))}

        </div>
        {saveMsgWarn ? <div className={'save-msg-warning-container'}>
            <TextViewer text={saveMsgWarn} addChar={' * '} addBefore/>
        </div> : null}

        <div className={'bottom-buttons'}>
            <button className={'bottom-buttons-save'} disabled={!isEnabledToSaved()} onClick={onCreateInvoice}>{<TextViewer text={'create_new_invoice'}/>}</button>
            <button className={'bottom-buttons-back'} onClick={()=>setCreateNewInvoiceMode(false)}><TextViewer text={'return'}/></button>
        </div>

    </div>
}

export default Invoice;