import config from '../../../../../config';

const UPDATE_CONFIG = {
    title: {
        keyName: 'title',
        text_view_name: 'title',
        required: false,
        placeholder: 'title',
        default: ''
    },
    text: {
        keyName: 'text',
        text_view_name: 'content',
        type: 'textarea',
        required: false,
        default: ''
    },
    startDate: {
        keyName: 'startDate',
        text_view_name: 'start_publish_date',
        type: 'selectDate',
        required: true,
        default: 'DATE_TODAY'
    },
    expirationDate: {
        keyName: 'expirationDate',
        text_view_name: 'expiration_publish_date',
        type: 'selectDate',
        required: true,
        default: 'DATE_NEXT_7_DAYS'
    },
    attachmentUrl: {
        keyName: 'attachmentUrl',
        text_view_name: 'attach_file',
        type: 'uploadFile',
        required: false,
        default: ''
    },
    giffAnimation: {
        keyName: 'attachmentUrl',
        text_view_name: 'or_choose_gif',
        type: 'chooseGifTemplate',
        gifTemplates: [
            {name: 'memorial_candle', id: 'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fcandle_memorial.gif?alt=media&token=b9872894-ec42-43a9-983c-be4f6ad52e67'},
            {name: 'ballons', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fballons.gif?alt=media&token=90279226-8572-45ea-8aa4-f08ab7155deb'},
            {name: 'happy-birthday-ballon', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fhappy-birthday-balloon.gif?alt=media&token=b829efee-63bc-490a-b37c-11e5c2940d7a'},
            {name: 'israel-flag', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fisrael-flag.gif?alt=media&token=8079be92-d2f0-4ad7-84fa-bb62ddd1f545'},
            {name: 'shabat-shalom', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fshabat-shalom.gif?alt=media&token=b54ac16d-b652-4164-b5eb-1940ec4873ef'},
            {name: 'baby-born', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fbaby-born.gif?alt=media&token=20e5b63b-57c9-4efb-8827-1fca92cdbe40'},
            {name: 'announcement', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fannouncement.gif?alt=media&token=ccb1d252-8925-4ce7-8cd8-57e23f1996cc'},
            {name: 'wine', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fwine.gif?alt=media&token=a2958cc3-cf54-43b7-94f2-ba0096ac7756'},
            {name: 'torha', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Ftorha.gif?alt=media&token=3736cf41-fc97-4f2a-aae2-9f681036c931'},
            {name: 'kids', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fkids.gif?alt=media&token=38320090-d99d-4941-b97b-b489c5a3c45a'},
            {name: 'celebration', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fcelebration.gif?alt=media&token=d1269efe-e02c-4d8e-8064-9aa5089005d0'},
            {name: 'candies', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fcandies.gif?alt=media&token=36cf02e6-ccde-4c11-8865-f0430f790d45'},
            {name: 'shofar', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fshofar.jpeg?alt=media&token=87ad8e59-8605-4e4a-9f73-1d5c1a9e10ed'},
            {name: '4minim', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2F4minim.jpeg?alt=media&token=3cd5ec2f-e1d4-4857-809d-82ef156db8b4'},
            {name: 'chanuka', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fchanuka.jpeg?alt=media&token=579df23b-c410-4bc6-a7ff-026994f3e0ef'},
            {name: 'raashan', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fraashan.jpeg?alt=media&token=4f1bfca1-a5d7-40f1-ac4f-31052811fa6b'},
            {name: 'wedding', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fwedding.jpeg?alt=media&token=c822852e-5aeb-4dde-bd53-9893f7f2c39f'},
            {name: 'happy_passover', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fhappy_passover.jpeg?alt=media&token=d7c7c53f-c943-4b64-90c1-96ff0f3b4788'},
            {name: 'toraha', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Ftoraha.jpeg?alt=media&token=27e0e3dc-2d7d-44de-ae49-9f68aa334ab5'},
            {name: 'idf', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fidf.jpeg?alt=media&token=fdbdd51c-4675-4ff1-8502-5c4a244d6618'},
            {name: 'purim', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fpurim.jpeg?alt=media&token=ab5db4b6-7d5f-4b5a-a228-8eaeb5d64369'},
            {name: 'dvar_torha', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fdvar_torha.jpeg?alt=media&token=8dbca358-c9dc-4da1-afe6-00858069feb2'},
            {name: 'medical', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fmedical.jpeg?alt=media&token=a1b49560-94ec-4b82-b102-ef677700ec88'},
            {name: 'first_aid', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Ffirst_aid.jpeg?alt=media&token=21c51e86-b09b-4030-ab5b-b812c4a13de5'},
            {name: 'welcome', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fwelcome.jpeg?alt=media&token=dba30ffc-8c79-4802-bd2e-a3c5d895f0ab'},
            {name: 'simhat_torha', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fsimhat_torha.jpeg?alt=media&token=b5a906ec-37bf-460f-aad3-b7642399f121'},
            {name: 'brit', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fbrit.jpeg?alt=media&token=fd15780a-b6bd-4ea8-b306-471ffc99f706'},
            {name: '12', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2F12.jpeg?alt=media&token=4c9a8da0-d85a-4c12-944b-91215e590ff5'},
            {name: 'bar_mitva', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fbar_mitva.jpeg?alt=media&token=30ea6935-afd4-4615-b79c-b5a5403f8daf'},
            {name: 'bar_mitva_2', id:'https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/gif%2Fbar_mitva_2.jpeg?alt=media&token=6b6a0fdb-bd76-4e75-a1a3-260122a2f083'}
        ],
        required: false,
        default: ''
    },
    youtubeUrl: {
        keyName: 'youtubeUrl',
        text_view_name: 'youtube_url_add',
        type: 'youtubeLink',
        required: false,
        default: ''
    },
    duration: {
        keyName: 'duration',
        text_view_name: 'duration_in_seconds',
        type: 'inputNumber',
        required: false,
        default: null
    }

}

export default UPDATE_CONFIG;