import React, {useState, useEffect} from 'react';
import './dvarTora.scss';
import '../PrayersDetails/PrayerDetails/PrayerDetails.scss';

import Loading from "../../Main/Loading/Loading";
import TextViewer from "../../../../helpers/textViewer";
import {useHistory} from "react-router-dom";
import {message} from "antd";

message.config({
    top: '90%',
    duration: 2,
    maxCount: 3,
    rtl: true
});
/**
 * plasmaSetting object:
 * tvarToraYoutubeLink - String
 * dvarTora - String
 */
const DvarTora = (props)=>{

    const { loading, dvarTora = '', tvarToraYoutubeLink = '', updateData, updateDataExtraDetails } = props;

    const [itemToSave, setItemToSave] = useState({ dvarTora, tvarToraYoutubeLink });

    // useEffect(() => {
    //     console.log("DvarTora: ",{ itemToSave, dvarTora, tvarToraYoutubeLink });
    // }, [itemToSave, dvarTora, tvarToraYoutubeLink]);


    const history = useHistory();

    const onTextInputChange = (event, keyName)=>{
        setItemToSave({...itemToSave, [keyName] : event.target.value})
    }

    if(loading) return <Loading/>

    const isEnabledToSaved = ()=>{
        const hasChanged =  JSON.stringify({ dvarTora, tvarToraYoutubeLink }) !== JSON.stringify(itemToSave);
        return hasChanged
    }

    const onSaveClicked = ()=>{
        updateData({fieldName: 'dvarTora', data: itemToSave.dvarTora});
        updateDataExtraDetails({fieldName: 'tvarToraYoutubeLink', data: itemToSave.tvarToraYoutubeLink});
        message.open({
            type: 'success',
            content: TextViewer({text: 'saved', returnString: true}),
        });
    }

    return <div className={'dvar-tora-edit-container'}>
        <TextViewer text={'dvar_tora'}/>
        <div className={'dvar-tora-edit-text'}>
            <textarea rows={'15'} onChange={(event)=>onTextInputChange(event, 'dvarTora')} value={itemToSave.dvarTora}/>
        </div>
        <div className={'dvar-tora-edit-text'}>
            <TextViewer text={'link_to_youtube'}/>
            <input allowClear={true}
                   onChange={(event)=>onTextInputChange(event, 'tvarToraYoutubeLink')}
                   value={itemToSave.tvarToraYoutubeLink}
                   placeholder={'https://www.youtube.com/embed/ccccccccc'}
            />
        </div>
        <div className={'bottom-buttons'}>
            <button className={'bottom-buttons-save'} disabled={!isEnabledToSaved()} onClick={onSaveClicked}>{<TextViewer text={'save'}/>}</button>
            <button className={'bottom-buttons-back'} onClick={()=>{
                history.push({
                    hash: 'main'
                });
            }}><TextViewer text={'return'}/></button>
        </div>
    </div>

}

export default DvarTora;