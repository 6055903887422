const ALIYAH_CONFIG = {
    user: {
        keyName: 'user',
        type: 'select_user',
        text_view_name: 'select_prayer',
        required: true,
        default: ''
    },
    aliyahType: {
        keyName: 'aliyahType',
        text_view_name: 'aliyah_type',
        type: 'select',
        selectDisable: 'choose_aliyah',
        options: ["כהן","לוי","שלישי","רביעי","חמישי","שישי","שביעי","מפטיר","אחר"],
        required: false,
        default: ''
    },
    aliyahTime: {
        keyName: 'aliyahTime',
        text_view_name: 'aliyah_time',
        type: 'selectDate',
        required: true,
        default: ''
    },
    aliyahReason: {
        keyName: 'aliyahReason',
        text_view_name: 'aliyah_reason',
        type: 'textarea',
        required: false,
        default: ''
    },
    aliyahPayment: {
        keyName: 'aliyahPayment',
        text_view_name: 'aliyah_payment',
        type: 'number',
        required: false,
    },
    aliyahSubPayment: {
        keyName: 'aliyahSubPayment',
        text_view_name: 'sub_payment',
        type: 'number',
        required: false,
    },
    isPayed: {
        keyName: 'isPayed',
        text_view_name: 'is_payed',
        type: 'checkbox',
        required: false,
        default: false,
    }
}

export default ALIYAH_CONFIG;