import React, {useState, useEffect} from 'react';

import './PrayersDetails.scss';
import TextViewer from "../../../../helpers/textViewer";
import Loading from "../../Main/Loading/Loading";
import PrayerDetails from './PrayerDetails/PrayerDetails';
import add_yellow_circle_logo from "../../icons/add_yellow_circle.svg";
import PRAYER_CONFIGS from './config/prayerConfig';
import {useHistory} from "react-router-dom";
import avatarLogo from '../../../v2/icons/avatar.svg';
import cloudLogo from '../../../v2/icons/cloud-check.svg';
import PDFGenerator from '../../../v2/PDFGenerator/PDFGenerator';
import SingletoneStoreClass from "../../../../Store/Store";
import config from "../../../../config";
import utils from "../../../../helpers/utils";
import pdf_icon from "../../icons/pdf.svg";
import delete_icon from "../../icons/delete.svg";

const store = SingletoneStoreClass.getInstance();

const PrayersDetails = (props) => {

    const { loading, users = [], updateData, synagogueName, base64Logo } = props;
    const [checkedItemsMap, setCheckedItemsMap] = useState({});
    const [itemToRender, setItemToRender] = useState(null);
    const [search, setSearch] = useState('');

    const history = useHistory();

    users.forEach((user, index)=>{
        user.index = index
    })


    // useEffect(() => {
    //     console.log("---checkedItemsMap=",checkedItemsMap)
    //
    // }, [checkedItemsMap]);



    useEffect(() => {
        const searchParams = window.location.search;

        if(searchParams.includes('?search=')){
            setSearch(decodeURI(searchParams.split('?search=')[1]))
        }

    }, []);



    const onAddItemClicked = ()=>{
        const newEmptyItem = {};
        Object.keys(PRAYER_CONFIGS).forEach(key=>{
            newEmptyItem[key] = PRAYER_CONFIGS[key].default;
        });

        setItemToRender(null);
        history.push({
            hash: 'prayers_details',
            search: 'display_one'
        })
    }


    const renderItem = (user)=>{
        const renderKeyValue = ({key, value})=>{
            return <div className={'key_value'}>
                <div className={'key'}> <TextViewer text={key} addChar={':'}/></div>
                <div className={'value'}>{value}</div>
            </div>
        }

        const onCheckClicked = (event)=>{
            console.log(event.target.checked);
            const obj = JSON.parse(JSON.stringify(checkedItemsMap));
            if(event.target.checked){
                obj[user.index] = true;
            }else{
                delete obj[user.index]
            }
            setCheckedItemsMap(obj);
        }

        const onItemClicked = ()=>{
            setItemToRender(user);

            history.push({
                hash: 'prayers_details',
                search: 'display_one'
            })
        }


        return <div className={'list-item'}>
            <div className={'checkbox'}>
                <input type="checkbox" onClick={(event)=>onCheckClicked(event, user.index)} checked={checkedItemsMap[user.index]}/>
            </div>
            <div className={'avatar-item'}>
                <img src={user.picture || avatarLogo} alt="icon"/>
            </div>
            <div className={'preview-item'} onClick={onItemClicked}>
                {renderKeyValue({key: 'name', value: `${user.family} ${user.name}`})}
                {renderKeyValue({key: 'type', value: user.type})}
                {user.userId ?  <div className={'cloud-item'}>
                    <img src={cloudLogo} alt="icon"/>
                </div> : null}
            </div>
        </div>
    }

    if(loading) return <Loading/>

    if(window.location.search.includes('display_one')){
        return <PrayerDetails itemsToRender={users} itemToRender={itemToRender} close={()=>{
            setItemToRender(null);
            history.push({
                hash: 'prayers_details',
            })
        }} updateData={updateData}/>
    }

    const filterUsers = ()=>{
        if(!search) return users;
        return users.filter(user=>{
            if(user?.name?.includes(search) || user?.family?.includes(search) || `${user?.name} ${user?.family}`.includes(search)) return true;
            return false;
        });
    }

    const sortUsers= (arr)=>{
        const sortedArray = arr.sort((a,b)=>`${a?.family}${a?.name}`.localeCompare(`${b?.family}${b?.name}`));
        return sortedArray;
    }

    const onSelectAllClicked = (event)=>{
        if(event.target.checked){
            const newCheckedItemsMap = {};
            users.forEach((user, index)=>{
                newCheckedItemsMap[index] = true;
            })
            setCheckedItemsMap(newCheckedItemsMap);
        }else{
            setCheckedItemsMap({});
        }
    }

    const onDeleteClicked = ()=>{
        let deleteAmount = 0;
        Object.keys(checkedItemsMap).forEach(key=>{
            if(checkedItemsMap[key]) deleteAmount++;
        })
        const text = TextViewer({text: 'are_u_sure_delete_items', addChar: '?', returnString: true});

        if (window.confirm(text.replace('X', deleteAmount)) == true) {
            let updatedItems = JSON.parse(JSON.stringify(users));

            Object.keys(checkedItemsMap).forEach(key=>{
                updatedItems[key] = null;
            })
            updatedItems = updatedItems.filter(ui=>ui);
            updateData({fieldName: 'manager', data: updatedItems});
            setCheckedItemsMap({});
        }
    }

    return <div className={'list-details-container'}>
        <div className={'list-details-container-title'}>
            <TextViewer text={'prayers_details_short'}/>
            <input value={search} placeholder={' 🔎 חיפוש'} onChange={(event)=>setSearch(event.target.value)}/>
        </div>
        <div className={'list-details-container-actions'}>
            <div className={'select-all-container'}>
                <input type="checkbox" name="scales" onClick={onSelectAllClicked}/>
                <label className={'select-all-label'}><TextViewer text={'choose_all'}/></label>
            </div>
            <button className={'delete-item-button'} onClick={onDeleteClicked} disabled={Object.keys(checkedItemsMap).length === 0}><TextViewer text={'delete'}/></button>
        </div>
        <div className={'add-item-button'} onClick={onAddItemClicked}>
            <img src={add_yellow_circle_logo} alt="icon"/>
        </div>

        <div className={'list-container'}>
            {sortUsers(filterUsers()).map((user)=>{
                return renderItem(user)
            })}
        </div>
    </div>
}

export default PrayersDetails;