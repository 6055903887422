const PlasmaModesSetting = {
    centerPage: {
        slideId: 'centerPage',
        text_view_name: 'center_page',
        duration: 30,
        enable: true
    },
    updates: {
        slideId: 'updates',
        text_view_name: 'customize_updates',
        duration: 10,
        enable: true
    },
    weeklySpecialEvents: {
        slideId: 'weeklySpecialEvents',
        text_view_name: 'weekly_special_events',
        duration: 10,
        enable: true
    },
    timesOfDay: {
        slideId: 'timesOfDay',
        text_view_name: 'times_of_the_day',
        duration: 10,
        enable: true
    },
    scanBarcode: {
        slideId: 'scanBarcode',
        text_view_name: 'scan_barcode',
        duration: 10,
        enable: true
    },
    mashivHaRuach: {
        slideId: 'mashivHaRuach',
        text_view_name: 'mashiv_haRuach_ten_bracha',
        duration: 10,
        enable: true
    },
    nezerAharon: {
        slideId: 'nezerAharon',
        text_view_name: 'nezer_aharon',
        duration: 10,
        enable: true
    },
    memoryBoard: {
        slideId: 'memoryBoard',
        text_view_name: 'memory_board',
        duration: 10,
        enable: true,
    },
    birthdayBoard: {
        slideId: 'birthdayBoard',
        text_view_name: 'birthday_board',
        duration: 10,
        enable: true,
    },
    generalEventsViewer: {
        slideId: 'generalEventsViewer',
        text_view_name: 'general_events_viewer',
        duration: 10,
        enable: true,
    }
}

export default PlasmaModesSetting;