import React, {useEffect, useState} from 'react';
import  "./JerusalemCompass.scss";
import TextViewer from '../../../../src/helpers/textViewer';
import arrow_navigation_logo from "../icons/arrow-up-navigation.svg";

const JerusalemCompass = (props)=>{


    const [deg, setDeg] = useState(0);
    const [pointDegree, setPointDegree] = useState(null);
    const [isPermissionsApproved, setIsPermissionsApproved] = useState(false);
    const [currentLocation, setCurrentLocation] = useState({});

    let compass;
    const isIOS = !(
        navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
        navigator.userAgent.match(/AppleWebKit/)
    );

    const init = ()=>{
        window.addEventListener("deviceorientationabsolute", handler);

        if (isIOS && typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function') {
            DeviceOrientationEvent?.requestPermission()
                .then((response) => {
                    if (response === "granted") {
                        window.addEventListener("deviceorientation", handler, true);
                    } else {
                        alert("has to be allowed!");
                    }
                })
                .catch(() => alert("not supported"));
        }
        navigator.geolocation.getCurrentPosition(locationHandler);
    }

    useEffect(() => {
        const hasPermissions = async ()=>{
            const gpsPermissionStatus = await navigator?.permissions?.query({name: 'geolocation'});
            if(gpsPermissionStatus?.state === 'granted'){
                setIsPermissionsApproved(true);
            }
        }
        hasPermissions();
    }, []);

    useEffect(() => {
        if(isPermissionsApproved){
            init()
        }
    }, [isPermissionsApproved]);

    useEffect(() => {
        if(isPermissionsApproved) navigator.geolocation.getCurrentPosition(locationHandler);
    }, [deg]);

    useEffect(() => {
        const { latitude, longitude } = currentLocation;
        if(!latitude || !longitude ) return;

        setPointDegree(calcDegreeToPoint(latitude, longitude));

    }, [currentLocation]);

    function locationHandler(position) {
        setCurrentLocation(position?.coords);
        setIsPermissionsApproved(true);
    }

    function calcDegreeToPoint(latitude, longitude) {
        // Temple Mount geolocation
        const point = {
            lat: 31.7779726,
            lng: 35.2356195,
        };

        const phiK = (point.lat * Math.PI) / 180.0;
        const lambdaK = (point.lng * Math.PI) / 180.0;
        const phi = (latitude * Math.PI) / 180.0;
        const lambda = (longitude * Math.PI) / 180.0;
        const psi =
            (180.0 / Math.PI) *
            Math.atan2(
                Math.sin(lambdaK - lambda),
                Math.cos(phi) * Math.tan(phiK) -
                Math.sin(phi) * Math.cos(lambdaK - lambda)
            );
        return Math.round(psi);
    }

    function handler(e) {
        compass = e.webkitCompassHeading || Math.abs(e.alpha - 360);
        setDeg(compass);
    }


    const {accuracy, latitude, longitude} = currentLocation;

    return <div className={'jerusalem-compass-container'}>
        <div className={'jerusalem-compass-title'}>
            <TextViewer text={'tfila_direction'}/>
        </div>

        {isPermissionsApproved ? <div className={'perfect-accuracy'}><TextViewer text={'perfect_accuracy'}/></div> :<div className={'approve-location-messaage'} onClick={init}>
            <TextViewer text={'click_permissions_location_message'}/>
        </div>}

        <div className="compass">
            <div className="jerusalem-indicator" style={{
                transform: (pointDegree === 0 || pointDegree === 360) ? '' : `rotate(${pointDegree + (360 - deg) }deg)`,
            }}>
                <img src={arrow_navigation_logo} alt="icon"/>
            </div>
            <div className="compass-circle" style={{
                transform: (deg === 0 || deg === 360) ? 'translate(-50%, -50%)' : `translate(-50%, -50%) rotate(${-deg}deg)`,
            }}></div>


        </div>
        <div className={'location-metadata-container'}>
            <TextViewer text={'degrees'} addChar={':'} addedString={Math.floor(deg)}/>
            <TextViewer text={'degrees_to_jerusalem'} addChar={':'} addedString={pointDegree}/>
            <TextViewer text={'accuracy'} addChar={':'} addedString={String(accuracy).slice(0,2)}/>
            <TextViewer text={'latitude'} addChar={':'} addedString={latitude}/>
            <TextViewer text={'longitude'} addChar={':'} addedString={longitude}/>
        </div>

    </div>
}

export default JerusalemCompass;