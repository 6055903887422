import React, {useState, useEffect} from 'react';
import TextViewer from "../../../../../helpers/textViewer";
import '../../PrayersDetails/PrayerDetails/PrayerDetails.scss';
import './update.scss';
import UPDATE_CONFIG from "../config/updateConfig";
import {message, Select} from "antd";
import UploadAttachment from '../../UploadAttachment/uploadAttachments'
import ReactPlayer from "react-player/youtube";
message.config({
    top: '90%',
    duration: 2,
    maxCount: 3,
    rtl: true
});
const { Option } = Select;

const Update = (props)=>{

    const { updates, index, close, updateData, cloneMode} = props;
    let itemToRender = {};

    const editMode = (props.index !== -1) && (index !== -1);
    if(editMode){
        itemToRender = updates[index];
    }

    const [itemToSave, setItemToSave] = useState({...itemToRender});
    const [durationVideo, setDurationVideo] = useState(null);

    // console.log("AliyahPanel: ",{updates, index, itemToRender, itemToSave, editMode});
    //
    // useEffect(() => {
    //     console.log("itemToSave: ",itemToSave);
    //
    // }, [itemToSave]);


    const renderField = (key)=>{

        const {keyName, text_view_name, placeholder = '', gifTemplates } = UPDATE_CONFIG[key];
        let {default: defaultVal } = UPDATE_CONFIG[key];

        if((defaultVal) && !itemToSave[keyName]) {
            if(defaultVal === 'DATE_TODAY'){
                defaultVal = new Date().toISOString().substr(0, 10)
            }else if(defaultVal === 'DATE_NEXT_7_DAYS'){
                const currentDatePlus7Days = new Date();
                currentDatePlus7Days.setDate(currentDatePlus7Days.getDate() + 7);
                defaultVal = currentDatePlus7Days.toISOString().substr(0, 10)
            }

            setItemToSave({...itemToSave, [keyName]: defaultVal});
        }

        const onMultiSelectChange = (event)=>{
            let values = Array.from(event.target.options).filter(o => o.selected).map(o=>o.value);
            setItemToSave({...itemToSave, [keyName]: values});
        }

        const onCheckboxChange = (event)=>{
            setItemToSave({...itemToSave, [keyName]: event.target.checked});
        }

        const onTextInputChange = (event, validator)=>{
            if(validator){
                if(!validator(event.target.value)) return;
            }
            try{
                JSON.parse(event.target.value);
                setItemToSave({...itemToSave, [keyName]: (event.target.value)});
            }catch (e){
                setItemToSave({...itemToSave, [keyName]: (event.target.value)});
            }
        }

        const onDateChange = (event)=>{
            setItemToSave({...itemToSave, [keyName]: (new Date(event.target.value)).toJSON()});
        }

        const onTextChange = (text)=>{
            setItemToSave({...itemToSave, [keyName]: text});
        }


        const onNumberChange = (number)=>{
            if(!isNaN(number.target.value)) setItemToSave({...itemToSave, [keyName]: number.target.value});
        }

        let input;
        switch (UPDATE_CONFIG[key].type) {
            case 'uploadFile':
                input = <UploadAttachment onTextChange={onTextChange} attachmentUrl={itemToSave[key]}/>
                break;
            case 'selectDate':
                input = <input type={'date'} onChange={onDateChange} value={itemToSave[key] ? new Date(itemToSave[key]).toISOString().substr(0, 10) : null}/>
                break;
            case 'inputNumber':
                input = <input type={'number'} onChange={onNumberChange} value={itemToSave[key] }/>
                break;
            case 'textarea':
                input = <textarea rows={'2'} onChange={onTextInputChange} value={itemToSave[key]}/>
                break;
            case 'checkbox':
                input = <input type={'checkbox'} onChange={onCheckboxChange} checked={itemToSave[key]}/>
                break;
            case 'time':
                input = <input type={'time'} onChange={onTextInputChange} value={itemToSave[key]} min={"00:00"} max={"23:59"} required/>
                break;
            case 'chooseGifTemplate':
                input = <div className={'select-giff-container'}>{gifTemplates.map(a=><div className={'select-giff'} onClick={()=>{
                    onTextChange(a.id)
                }}>
                    <img src={a.id}/>

                </div>)}</div>
                break;
            case 'youtubeLink':
                input = <div className={'add-youtube-link'}>
                    <input type={'text'} value={itemToSave[key]} onChange={onTextInputChange} placeholder={"https://www.youtube.com/watch?ID"}/>
                    {itemToSave[key] ? <ReactPlayer
                        className={'player-container'}
                        url={itemToSave[key]}
                        muted={true}
                        onDuration={duration=>{
                            setItemToSave({...itemToSave, duration})
                        }}
                    /> : null}
                </div>
                break;
            default:
                input = <input onChange={onTextInputChange} value={itemToSave[key]} placeholder={TextViewer({text: placeholder, returnString: true})}/>

        }

        return <div className={'filed-container'}>
            <TextViewer text={text_view_name} addChar={UPDATE_CONFIG[key].required ? '* ' : ''} addBefore/>
            {input}
        </div>
    }

    const isEnabledToSaved = ()=>{
        const hasChanged =  JSON.stringify(itemToRender) !== JSON.stringify(itemToSave);
        let allRequiredFields = true;
        Object.keys(UPDATE_CONFIG).forEach(key=>{
            if(UPDATE_CONFIG[key].required && (!itemToSave[key] && itemToSave[key] !== 0)){
                allRequiredFields = false;
            }
            if(!itemToSave.text && !itemToSave.title && !itemToSave.attachmentUrl && !itemToSave.youtubeUrl)  allRequiredFields = false;
        });
        return hasChanged && allRequiredFields;
    }

    const onSaveClicked = ()=>{
        const updatedItems = [...updates];
        const copyOfItemToSave = {...itemToSave};

        if(editMode){
            if(cloneMode){
                updatedItems.push(copyOfItemToSave);
            }else{
                updatedItems[index] = copyOfItemToSave;
            }
        }else{
            updatedItems.push(copyOfItemToSave);
        }
        updateData({fieldName: 'updates', data: updatedItems});
        close();
        message.open({
            type: 'success',
            content: TextViewer({text: 'saved', returnString: true}),
        });
    }

    return <div className={'item-details-container'}>
        <div className={'title'}>
            <TextViewer text={'add_edit_event'}/>
        </div>
        <div className={'body'}>
            {Object.keys(UPDATE_CONFIG).map((key)=>renderField(key))}
        </div>
        <div className={'bottom-buttons'}>
            <button className={'bottom-buttons-save'} disabled={!isEnabledToSaved()} onClick={onSaveClicked}>{<TextViewer text={'save'}/>}</button>
            <button className={'bottom-buttons-back'} onClick={()=>close()}><TextViewer text={'return'}/></button>
        </div>
    </div>
}

export default Update;