const { ComplexZmanimCalendar, getZmanimJson } = require("kosher-zmanim");
// demo: https://behindthemath.github.io/KosherZmanim/
//update occasions.json: https://www.yeshiva.org.il/events/memorial

const config = {
    timeZoneId: 'Israel' //full list can be found here: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
    // timeZoneId: 'Europe/London'
}

class HebrewZmanim{
    constructor({date, location = {}}) {
        this.date = date || new Date();
        this.location = location;
        this.zmanimJson = this._getZmanimJson();
    }

    _getZmanimJson(){
        return getZmanimJson({
            date: this.date,
            timeZoneId: config.timeZoneId,
            // locationName?: string,
            latitude: this?.location?.latitude || 32.0919291, // important: always set default location of isreal, otherwise the date may be "N/A"
            longitude: this?.location?.longitude || 34.9418544, // important: always set default location of isreal, otherwise the date may be "N/A"
            // elevation?: number = 0, //todo: is it required ?
            complexZmanim: true,
        })
    }

    getSunset(){
        return new Date(this.zmanimJson.Zmanim.Sunset);
    }

    getSunrise(){
        return new Date(this.zmanimJson.Zmanim.Sunrise);
    }

    getStarsCameOut(){
        // return new Date(this.zmanimJson.Zmanim.BainHasmashosRT2Stars === 'N/A' ? this.zmanimJson.Zmanim.BainHasmashosRT13Point5MinutesBefore7Point083Degrees : this.zmanimJson.Zmanim.BainHasmashosRT2Stars);
        return new Date(this.zmanimJson.Zmanim.Tzais || this.zmanimJson.Zmanim.TzaisGeonim4Point8Degrees);
    }

    getAlosHashachar(){
        return new Date(this.zmanimJson.Zmanim.AlosHashachar);
    }

    getSofZmanShma(){
        return new Date(this.zmanimJson.Zmanim.SofZmanShma3HoursBeforeChatzos);
    }

    getPlagHamincha(){
        return new Date(this.zmanimJson.Zmanim.PlagHamincha);
    }

    getSofZmanAchilasChametzBaalHatanya(){
        return new Date(this.zmanimJson.Zmanim.SofZmanAchilasChametzBaalHatanya);

    }
    getSofZmanAchilasChametzGRA(){
        return new Date(this.zmanimJson.Zmanim.SofZmanAchilasChametzGRA);

    }
    getSofZmanBiurChametzBaalHatanya(){
        return new Date(this.zmanimJson.Zmanim.SofZmanBiurChametzBaalHatanya);

    }
    getSofZmanBiurChametzGRA(){
        return new Date(this.zmanimJson.Zmanim.SofZmanBiurChametzGRA);

    }
}

export default HebrewZmanim;