const DB = require('./data.json');

const dbArray = Object.keys(DB);

const cache = {
    lastTimestamp: null,
    data: null
};

const getYeshivaTimes = (customDate)=>{
    const currentTimestamp = customDate ? customDate.getTime() : new Date().getTime() - (1000 * 60 * 60 * 5); // add 5 hours buffer
    if(!customDate && cache.lastTimestamp && ((currentTimestamp - cache.lastTimestamp) / (1000 * 60) < 60)){ //more than 60 minutes - important to use cache because it's huge data
        return cache.data;
    }
    for(let index =0; index < dbArray.length; index++){
        const dbTimeStamp = dbArray[index];
        if(dbTimeStamp > currentTimestamp){
            //we pass the current day, use the last day:
            // console.log(`the nearest timestamp for now (${currentTimestamp}) is: ${dbArray[index - 1]} - diff is: ${(currentTimestamp - dbArray[index - 1]) / (1000 * 3600 * 24)} days`);
            if(!customDate){
                cache.lastTimestamp = currentTimestamp;
                cache.data = DB[dbArray[index]];
            }
            return DB[dbArray[index]];
        }
    }
}

export { getYeshivaTimes };

/**
 * for local run:
 */
// (()=>{
//     console.log(getYeshivaTimes())
// })()