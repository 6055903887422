import React, {useEffect, useState} from "react";

import './advertisements.scss';
import { Modal } from 'antd';
import SingletoneStoreClass from "../../Store/Store";

import utils from '../../helpers/utils';
const store = SingletoneStoreClass.getInstance();


const Advertisements = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(true);

    const onModalClicked = ()=> {
        //todo: send stats whn user clicked on it!
        utils.sendWhatsAppMessage({msg: 'היי אור, אשמח לשמוע פרטים על פרסום ב my synagogue', phoneNumber: '+972549445909'})
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    if(store.getSynagogueName() !== 'habani-rosh-hayain#'){
        return null; //this for testing only!
    }

    return (
        <div>
            <Modal className={'advertisements-modal'}
                   title="פרסומת" visible={isModalVisible} onCancel={handleCancel} footer={null} maskClosable={false}>
                <div className={'advertisements-content'} onClick={onModalClicked}>
                    <p>רוצה לפרסם אצלנו ?</p>
                    <p>הפרסומת הזו יכולה להיות שלך!</p>
                    <p> לפרטים לחץ <a>כאן </a> </p>

                    <p>--- My Synagogue ---</p>
                </div>

            </Modal>
        </div>
    );

}

export default Advertisements;