import React, {useEffect, useState} from 'react';
import './PlasmaMainSubTitle.scss';

const PlasmaMainSubTitle = (props)=>{
    const {title} = props;
    if(!title) return null;
    return <div className={'plasma-sub-main-title-container'}>
        {title}
    </div>
}

export default PlasmaMainSubTitle;