import React, {useEffect, useState} from 'react';

import './PlasmaDetailedScreen.scss';
import Icon from "@ant-design/icons";
import {ReactComponent as black_main_logo} from "../../../icons/black_main_logo.svg";
import Clock from "react-live-clock";
import NextShbbat from "../../NextShbbat/NextShbbat";
import DisplayEventsByDate from "../../Calander/DisplayEventsByDate/DisplayEventsByDate";
import calendarConfig from '../../Calander/DateUtils/config';
import TextViewer from "../../../../../helpers/textViewer";
import SingletoneStoreClass from "../../../../../Store/Store";
import moment from "moment";
import utils from '../../../../../helpers/utils'

const store = SingletoneStoreClass.getInstance();

const daysToAlpha = {
    0: "sunday",
    1: "monday",
    2: "tuesday",
    3: "wednesday",
    4: "thursday",
    5: "friday",
    6: "saturday",
};

const PlasmaDetailedScreen = (props)=>{
    const { base64Logo, location, name, useYeshivaTimes, loading, currentDate, minyanName, shabatEnterMinBeforeSunset, shabatExitMinAfterStarsCameOut } = props;

    const nextDayDate = new Date((new Date()).setDate(currentDate.getDate() + 1));

    const events = store.getEventsPerDate(currentDate, location);

    return <div className={'plasma-detailed-screen'}>
        <div className={'plasma-detailed-screen-event-times'}>
            <div className={'plasma-detailed-screen-event-day-name'}><TextViewer text={'today'} addChar={','}/><TextViewer text={daysToAlpha[currentDate.getDay()]} addChar={':'}/></div>
            <DisplayEventsByDate plasmaMode={true} filters={[calendarConfig.FILTERS.prayers.name, calendarConfig.FILTERS.studies.name]} date={currentDate} id={'display-events-1'} minyanName={minyanName}/>

        </div>
        <div className={'plasma-detailed-screen-middle'}>
            <div className={'plasma-detailed-screen-middle-logo'}>
                {base64Logo ? <img src={base64Logo} alt="avatar" style={{width: '100%'}}/> : <Icon component={black_main_logo}/>}
            </div>
            <div className={'plasma-detailed-screen-middle-name'}>
                {`${name}${minyanName ? ` - ${minyanName}` : ''}`}
            </div>
            <div className={'plasma-detailed-screen-middle-next-shabat'}>
                <NextShbbat loading={loading} location={location} useYeshivaTimes={useYeshivaTimes} showOne={true} shabatEnterMinBeforeSunset={shabatEnterMinBeforeSunset} shabatExitMinAfterStarsCameOut={shabatExitMinAfterStarsCameOut}/>
            </div>
            <div className={'plasma-detailed-screen-middle-full-hebrew-date'}>
                {store.getHebrewDate({date: currentDate, location})}
            </div>
            <div className={'plasma-detailed-screen-middle-full-date'}>
                <TextViewer text={daysToAlpha[currentDate.getDay()]} addedString={moment(currentDate).format("DD.MM.YYYY")} addChar={','} />
            </div>
            <div className={'plasma-detailed-screen-middle-clock'}>
                <Clock format="HH:mm" interval={1000} ticking={true}/>
                <span className={'clock-seconds'}><Clock format="ss" interval={1000} ticking={true}/></span>
            </div>

            {events.length > 0 ? <div className={'plasma-detailed-screen-middle-date-events'}>
                {events.join(' \n ')}
            </div> : null}
        </div>
        <div className={'plasma-detailed-screen-event-times'}>
            <div className={'plasma-detailed-screen-event-day-name'}><TextViewer text={'tomorrow'} addChar={','}/><TextViewer text={daysToAlpha[nextDayDate.getDay()]} addChar={':'}/></div>

            <DisplayEventsByDate plasmaMode={true} filters={[calendarConfig.FILTERS.prayers.name, calendarConfig.FILTERS.studies.name]} date={nextDayDate} id={'display-events-2'} minyanName={minyanName}/>
        </div>
    </div>
}

export default PlasmaDetailedScreen;