import React, {useState, useEffect} from 'react';

import '../PrayersDetails/PrayersDetails.scss';
import TextViewer from "../../../../helpers/textViewer";
import Loading from "../../Main/Loading/Loading";
import Time from './Time/Time';
import add_yellow_circle_logo from "../../icons/add_yellow_circle.svg";
import {useHistory} from "react-router-dom";
import {Select} from "antd";
import config from "../../../../config";
import tfilotParser from "../../../../helpers/TfilotOfTheDay/tfilotParser";
import delete_icon from "../../icons/delete.svg";
import utils from "../../../../helpers/utils";

const { Option } = Select;

const FILTER_MODES = {
    REGULAR: 'REGULAR',
    SPECIAL: 'SPECIAL',
}


let snapshot = {
    filterMode: FILTER_MODES.REGULAR,
    filterHoliday: null
}

const Times = (props) => {

    const { loading, times = [], updateData } = props;

    const [checkedItemsMap, setCheckedItemsMap] = useState({});
    const [index, setIndex] = useState(-1);
    const [search, setSearch] = useState('');
    const [cloneMode, setCloneMode] = useState(false);
    const [filterMode, setFilterMode] = useState(snapshot.filterMode);
    const [filterHoliday, setFilterHoliday] = useState(snapshot.filterHoliday);

    const history = useHistory();

    times.forEach((time, index)=>{
        time.index = index;
    })


    // useEffect(() => {
    //     console.log("---Times=", {checkedItemsMap, times})
    //
    // }, [checkedItemsMap, times]);


    const onAddItemClicked = ()=>{
        setIndex(-1);
        setCloneMode(false);
        history.push({
            hash: 'times',
            search: 'display_one'
        });
    }


    const renderItem = (time)=>{
        const renderKeyValue = ({key, value})=>{
            return <div className={'key_value'}>
                <div className={'key'}> <TextViewer text={key} addChar={':'}/></div>
                <div className={'value'}>{value}</div>
            </div>
        }

        const onCheckClicked = (event)=>{
            const obj = JSON.parse(JSON.stringify(checkedItemsMap));
            if(event.target.checked){
                obj[time.index] = true;
            }else{
                delete obj[time.index]
            }
            setCheckedItemsMap(obj);
        }


        const onItemClicked = ()=>{
            setIndex(time.index);
            setCloneMode(false);
            history.push({
                hash: 'times',
                search: 'display_one'
            })
        }

        const onCloneClicked = ()=>{
            setIndex(time.index);
            setCloneMode(true);
            history.push({
                hash: 'times',
                search: 'display_one'
            });
        }



        return <div className={'list-item'}>
            <div className={'checkbox'}>
                <input type="checkbox" onClick={(event)=>onCheckClicked(event)} checked={checkedItemsMap[time.index]}/>
            </div>
            <div className={'preview-item'} onClick={onItemClicked}>
                {renderKeyValue({key: 'name', value: `${time.name}`})}
                {renderKeyValue({key: 'event_type', value: TextViewer({text: time.type, returnString: true})})}
                {renderKeyValue({key: 'days', value: time.importantDates ? time.importantDates.name : time.days.map(timeName=>TextViewer({text: `${timeName}_short`, returnString: true})).join(',')})}
                {renderKeyValue({key: 'when', value: time.isDynamic ? `דינאמי` : time.when})}
                {time.minyan ? renderKeyValue({key: 'minyan', value: time.minyan}) : null}
            </div>
            <div className={'list-item-divider'}></div>
            <button className={'clone-item-button'} onClick={onCloneClicked}><TextViewer text={'clone'}/></button>

        </div>
    }

    if(loading) return <Loading/>

    if(window.location.search.includes('display_one')){
        return <Time times={times} index={index} cloneMode={cloneMode} holidayMode={filterMode === FILTER_MODES.SPECIAL} close={()=>{
            setIndex(-1);
            history.push({
                hash: 'times',
            })
        }} updateData={updateData}/>
    }

    const onSelectAllClicked = (event)=>{
        if(event.target.checked){
            const newCheckedItemsMap = {};
            times.forEach((time, index)=>{
                newCheckedItemsMap[index] = true;
            })
            setCheckedItemsMap(newCheckedItemsMap);
        }else{
            setCheckedItemsMap({});
        }
    }

    const onDeleteClicked = ()=>{
        let deleteAmount = 0;
        Object.keys(checkedItemsMap).forEach(key=>{
            if(checkedItemsMap[key]) deleteAmount++;
        })
        const text = TextViewer({text: 'are_u_sure_delete_items', addChar: '?', returnString: true});

        if (window.confirm(text.replace('X', deleteAmount)) == true) {
            let updatedItems = JSON.parse(JSON.stringify(times));
            Object.keys(checkedItemsMap).forEach(key=>{
                updatedItems[key] = null;
            })
            updatedItems = updatedItems.filter(ui=>ui);
            updateData({fieldName: 'times', data: updatedItems});
            setCheckedItemsMap({});
        }

    }

    const filterTimes = ()=>{
        return times.filter(time=>{
            const searchableString = `${time.name}${time?.importantDates?.name}`
            if(search && !searchableString.includes(search)) return false;
            if(filterMode === FILTER_MODES.REGULAR){
                if(!time.importantDates) return true;
                else return false;
            }else if (filterMode === FILTER_MODES.SPECIAL){
                if(time.importantDates){
                    if(!filterHoliday) return true;
                    else {
                        if(time.importantDates.hebrewDayAtMonth.toString() === filterHoliday.hebrewDayAtMonth.toString() && time.importantDates.hebrewMonth === filterHoliday.hebrewMonth ) return true;
                        else return false;
                    }
                }
                else return false;
            }
        })
    }

    const sortItems = (items)=>{
        return items.sort((item1, item2)=>tfilotParser.timeObjV2ToDate({time: item1}).toJSON().split("T")[1].localeCompare(tfilotParser.timeObjV2ToDate({time: item2}).toJSON().split("T")[1]));
    }


    return <div className={'list-details-container'}>
        <div className={'list-details-container-title'}>
            <TextViewer text={'times'}/>
            <input value={search} placeholder={' 🔎 חיפוש'} onChange={(event)=>setSearch(event.target.value)}/>
        </div>
        <div className={'filter-container'}>
            <button className={filterMode === FILTER_MODES.REGULAR ? (utils.getLanguage() === 'heb' ? 'button-right clicked' : 'button-left clicked') : utils.getLanguage() === 'heb' ? 'button-right' : 'button-left'} disabled={filterMode === FILTER_MODES.REGULAR} onClick={()=>{setFilterMode(FILTER_MODES.REGULAR); snapshot.filterMode = FILTER_MODES.REGULAR}}><TextViewer text={'ימים רגילים'}/></button>
            <button className={filterMode === FILTER_MODES.SPECIAL ?  (utils.getLanguage() === 'heb' ? 'button-left clicked' : 'button-tight clicked') : utils.getLanguage() === 'heb' ? 'button-left' : 'button-right'} disabled={filterMode === FILTER_MODES.SPECIAL} onClick={()=>{setFilterMode(FILTER_MODES.SPECIAL); snapshot.filterMode = FILTER_MODES.SPECIAL}}><TextViewer text={'חגים'}/></button>
        </div>
        {filterMode === FILTER_MODES.SPECIAL ? <div className={'filter-by-holiday'}>
            <Select className={'statics-select'} dropdownMatchSelectWidth={false} showSearch allowClear
                    placeholder={TextViewer({text: 'סנן לי מועד', returnString: true})}
                    value={filterHoliday ? JSON.stringify(filterHoliday) : null}
                    onChange={(e)=>{
                        if(!e){
                            setFilterHoliday(null);
                            snapshot.filterHoliday = null;
                        }
                        else {
                            setFilterHoliday(JSON.parse(e));
                            snapshot.filterHoliday = JSON.parse(e);
                        }
                    }}
            >
                {config.importantDates.map(optionObj=><Option key={JSON.stringify(optionObj)} value={JSON.stringify(optionObj)}>{`${optionObj.name}${typeof optionObj.hebrewDayAtMonth === 'number' ? ` - ${config.hebrewNumToDay[optionObj.hebrewDayAtMonth]} ${config.englishMonthToHebrew[optionObj.hebrewMonth]}` : ''}`}</Option>)}
            </Select>
        </div> : null}
        <div className={'list-details-container-actions'}>
            <div className={'select-all-container'}>
                <input type="checkbox" name="scales" onClick={onSelectAllClicked}/>
                <label className={'select-all-label'}><TextViewer text={'choose_all'}/></label>
            </div>
            <button className={'delete-item-button'} onClick={onDeleteClicked} disabled={Object.keys(checkedItemsMap).length === 0}><TextViewer text={'delete'}/></button>

        </div>
        <div className={'add-item-button'} onClick={onAddItemClicked}>
            <img src={add_yellow_circle_logo} alt="icon"/>
        </div>

        <div className={'list-container'}>
            {sortItems(filterTimes()).map((time, index)=>{
                return renderItem(time, index)
            })}
        </div>
    </div>
}

export default Times;