import React, {useState, useEffect} from 'react';
import TextViewer from "../../../../../helpers/textViewer";
import '../../PrayersDetails/PrayerDetails/PrayerDetails.scss';
import ALIYAH_CONFIG from "../config/aliyahConfig";
import {Select} from "antd";
import {message} from "antd";
import calcPaymentHistory from "../../PrayersDetails/PrayerDetails/HistoryPayments/calcPaymentHistory";

const { Option } = Select;


message.config({
    top: '90%',
    duration: 2,
    maxCount: 3,
    rtl: true
});

const AliyahPanel = (props)=>{

    const { users, close, updateData, aliyahIndex, cloneMode, noTitle=true, noActions=false, aliyahTypeName=null, aliyahDate=null, onItemChange } = props;
    let itemToRender = {};
    if(aliyahTypeName) itemToRender.aliyahType = aliyahTypeName;
    if(aliyahDate) itemToRender.aliyahTime = new Date(aliyahDate).toJSON();

    const editMode = (props.index !== -1) && (aliyahIndex !== -1);
    if(editMode){
        itemToRender = users[props.index].aliyot[aliyahIndex];
    }

    const [itemToSave, setItemToSave] = useState({...itemToRender});
    const [index, setIndex] = useState(props.index);



    // console.log("AliyahPanel: ",{users, aliyahIndex, itemToRender, itemToSave, index, editMode});

    useEffect(() => {
        console.log("itemToSave: ",itemToSave, index);
        if(typeof onItemChange === 'function'){
            onItemChange({...itemToSave, userIndex: index})
        }
    }, [itemToSave, index]);


    const renderField = (key)=>{

        const {keyName, text_view_name, selectDisable, default: defaultVal } = ALIYAH_CONFIG[key];

        const onTextInputChange = (event, validator)=>{
            if(validator){
                if(!validator(event.target.value)) return;
            }
            setItemToSave({...itemToSave, [keyName]: (event.target.value)});
        }

        const onChooseUserClicked = (value)=>{
            setIndex(value.split('-')[1])
        }

        const onDateChange = (event)=>{
            setItemToSave({...itemToSave, [keyName]: (new Date(event.target.value)).toJSON()});
        }

        const onCheckboxChange = (event)=>{
            setItemToSave({...itemToSave, [keyName]: event.target.checked});
        }
        let input;
        switch (ALIYAH_CONFIG[key].type) {
            case 'select_user':
                input = <Select className={'statics-select'} dropdownMatchSelectWidth={false} showSearch
                        value={index !== -1 ? `${users[index].name} ${users[index].family}` : ''}
                                placeholder={TextViewer({text: 'who_made_aliyah', returnString: true, addChar: '?'})}
                                onChange={onChooseUserClicked}>
                    {editMode ? <Option key={`user-${index}`}
                                        value={`${users[index].name} ${users[index].family}-${index}`}>{`${users[index].name} ${users[index].family}`}</Option> :
                        users.map((user, index) =>
                            <Option key={`user-${index}`}
                                    value={`${user.name} ${user.family}-${index}`}>{`${user.name} ${user.family}`}</Option>)
                    }
                </Select>
                break;
            case 'select':
                input = <select onChange={onTextInputChange} value={aliyahTypeName ? aliyahTypeName : (itemToSave[key] || defaultVal)}>
                    {(selectDisable ? [<option disabled selected value>{TextViewer({text: selectDisable, returnString: true})}</option>] : []).concat(ALIYAH_CONFIG[key].options.map(option=><option value={option}>{option}</option>))}
                </select>
                break;
            case 'selectDate':
                input = <input type={'date'} max={new Date().toISOString().substr(0, 10)} onChange={onDateChange}
                               disabled={!!aliyahDate}
                               value={aliyahDate ? aliyahDate : (itemToSave[key] ? new Date(itemToSave[key]).toISOString().substr(0, 10) : null)}/>
                break;
            case 'textarea':
                input = <textarea rows={'2'} onChange={onTextInputChange} value={itemToSave[key]}/>
                break;
            case 'number':
                input = <input type={'number'} min={"0"} onChange={onTextInputChange} value={itemToSave[key] || defaultVal}/>
                break;
            case 'checkbox':
                input = <input type={'checkbox'} onChange={onCheckboxChange} checked={itemToSave[key]}/>
                break;
            default:
                input = <input onChange={onTextInputChange} value={itemToSave[key]}/>

        }

        return <div className={'filed-container'}>
            <TextViewer text={text_view_name} addChar={ALIYAH_CONFIG[key].required ? '* ' : ''} addBefore/>
            {input}
        </div>
    }

    const isEnabledToSaved = ()=>{
        const hasChanged =  JSON.stringify(itemToRender) !== JSON.stringify(itemToSave);
        let allRequiredFields = true;
        Object.keys(ALIYAH_CONFIG).forEach(key=>{
            if(key === 'user'){
                if(index === -1) allRequiredFields = false;
                else return;

            }
            if(ALIYAH_CONFIG[key].required && !itemToSave[key]){
                allRequiredFields = false;
            }
        });
        let isPaymentOK = true;
        if(itemToSave.aliyahSubPayment){
            if(!itemToSave.aliyahPayment){
                isPaymentOK = false;
            }
            else{
                if(Number(itemToSave.aliyahSubPayment) > Number(itemToSave.aliyahPayment)){
                    isPaymentOK = false;
                }
            }
        }
        return hasChanged && allRequiredFields && isPaymentOK;
    }

    const onSaveClicked = ()=>{
        const updatedItems = [...users];

        const itemToUpdate = updatedItems[index]

        let { user, ...aliyah } = itemToSave;
        if(editMode){
            if(cloneMode){
                itemToUpdate.aliyot.push(aliyah)
            }else{
                itemToUpdate.aliyot[aliyahIndex] = aliyah;
            }
        }else{
            if(!itemToUpdate.aliyot){
                itemToUpdate.aliyot = [aliyah]
            }else{
                itemToUpdate.aliyot.push(aliyah)
            }
        }
        calcPaymentHistory({
            itemToRender,
            itemToUpdate,
            isPayed: aliyah.isPayed,
            subPayment: Number(aliyah.aliyahSubPayment),
            totalPayment: Number(aliyah.aliyahPayment),
            type: 'עליה לתורה',
            details: aliyah.aliyahReason
        });

        updateData({fieldName: 'manager', data: updatedItems});
        close();
        message.open({
            type: 'success',
            content: TextViewer({text: 'saved', returnString: true}),
        })
    }

    return <div className={'item-details-container'}>
        {noTitle ? null : <div className={'title'}>
            <TextViewer text={'add_edit_aliyah'}/>
        </div>}

        <div className={'body'}>
            {Object.keys(ALIYAH_CONFIG).map((key)=>renderField(key))}
        </div>
        {noActions ? null : <div className={'bottom-buttons'}>
            <button className={'bottom-buttons-save'} disabled={!isEnabledToSaved()} onClick={onSaveClicked}>{<TextViewer text={'save'}/>}</button>
            <button className={'bottom-buttons-back'} onClick={()=>close()}><TextViewer text={'return'}/></button>
        </div>}

    </div>
}

export default AliyahPanel;