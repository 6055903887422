import {analyticsAddLogEvent} from "../../../firebase/firebase";
import utils from "../../../helpers/utils";

const WORDS = ["בהעלותך","תורה","טום","דוד","משה","גרשון","יצחק","אברהם","שלום"];
const ALPHA_BET = ['א','ב','ג','ד','ה','ו','ז','ח','ט','י','כ','ל','מ','נ','ס','ע','פ','צ','ק','ר','ש','ת']
const DEBUGGER = false;
class Matrix {
    constructor({rowsAmount = 10, columnAmount = 10, words=WORDS}) {
        this.rowsAmount = rowsAmount;
        this.columnAmount = columnAmount;
        this.matrix = [];
        this.words = [...words];
        this.unMatchedWords = words;
        this.clickedCellsMap = {}; //key-> [row,column] value = cell digit
        this.matrixMap = {};
        this.seconds = 0;
        this._init();
        this._setWords();
        this._fillTheRest();
        Matrix.singletone = true;


        this.secondInterval = setInterval(()=>{
            this.seconds++;
        },1000);
    }


    static createInstance(params) {
        var object = new Matrix(params);
        return object;
    }

    static getInstance (params) {
        if (!Matrix.instance) {
            Matrix.instance = Matrix.createInstance(params);
        }
        return Matrix.instance;
    }

    _init(){
        analyticsAddLogEvent({eventName: 'game_word_search_begin', params: { name: utils.getSynagogueName()}});

        //initial empty 2d array:
        for(let row=0; row< this.rowsAmount; row++){
            this.matrix[row] = [];
            for(let column=0; column<this.columnAmount; column++){
                this.matrix[row].push(null)
            }
        }
        for(let i =0; i<this.words.length; i++){
            this.words[i] = this.words[i] ? this.words[i].replaceAll('ך','כ') : this.words[i];
            this.words[i] = this.words[i] ? this.words[i].replaceAll('ם','מ') : this.words[i];
            this.words[i] = this.words[i] ? this.words[i].replaceAll('ן','נ') : this.words[i];
            this.words[i] = this.words[i] ? this.words[i].replaceAll('ף','פ') : this.words[i];
            this.words[i] = this.words[i] ? this.words[i].replaceAll('ץ','צ') : this.words[i];
        }

        for(let i =0; i<this.unMatchedWords.length; i++){
            this.unMatchedWords[i] = this.unMatchedWords[i] ? this.unMatchedWords[i].replaceAll('ך','כ') : this.unMatchedWords[i];
            this.unMatchedWords[i] = this.unMatchedWords[i] ? this.unMatchedWords[i].replaceAll('ם','מ') : this.unMatchedWords[i];
            this.unMatchedWords[i] = this.unMatchedWords[i] ? this.unMatchedWords[i].replaceAll('ן','נ') : this.unMatchedWords[i];
            this.unMatchedWords[i] = this.unMatchedWords[i] ? this.unMatchedWords[i].replaceAll('ף','פ') : this.unMatchedWords[i];
            this.unMatchedWords[i] = this.unMatchedWords[i] ? this.unMatchedWords[i].replaceAll('ץ','צ') : this.unMatchedWords[i];
        }

        this.matrixMap = this._getMatrixMap();

    }

    refreshGame = ({words})=>{
        clearInterval(this.secondInterval);
        this.words = [...words];
        this.matrix = [];
        this.unMatchedWords = this.words;
        this.clickedCellsMap = {}; //key-> [row,column] value = cell digit
        this.matrixMap = {};
        this.seconds = 0;
        this._init();
        this._setWords();
        this._fillTheRest();

        this.secondInterval = setInterval(()=>{
            this.seconds++;
        },1000);
    }

    getUnMatchedWords(){
        return this.unMatchedWords;
    }


    refreshClick(){
        this.clickedCellsMap = {};
        Object.keys(this.matrixMap).forEach(key=>{
            if(this.matrixMap[key] === 'clicked') this.matrixMap[key] = '';
        })
    }

    _getMatrixMap(){
        const matrixObj = {};
        this.matrix.map((rowArray, rowNum)=>
        {this.matrix[rowNum].map((value, columnNum)=> {
            matrixObj[`${rowNum},${columnNum}`] = '';
        })})
        return matrixObj;
    }

    getMatrixMap() {
        return this.matrixMap;
    }
    _setWords(){
        let wordsToSet = [...this.words];
        while (wordsToSet.length > 0){
            const haveSet = this._setWordRandomly({word: wordsToSet[0]});
            if(haveSet){
                wordsToSet.shift()
            }
        }
    }

    _setWordRandomly({word}){
        const randomIndex = Math.floor((Math.random() * this.rowsAmount * this.columnAmount) + 1);
        const row = Math.floor(randomIndex/10);
        const column = randomIndex % 10;
        //check if index is free:
        if(!this.matrix[row] || this.matrix[row][column]){
            // console.log(`spot: [${row},${column}] already exist (${this.matrix})`);
            return false;
        }else{
            const optionalSpots = this._getWordPathDirection({ word,row, column});
            if(optionalSpots.length > 0){
                const randomIndex = Math.floor((Math.random() * optionalSpots.length));
                //extra check:
                optionalSpots[randomIndex].forEach((spot,index)=>{
                    if(!this.matrix[spot.row][spot.column]){
                        return false;
                    }

                })
                optionalSpots[randomIndex].forEach((spot,index)=>{
                    this.matrix[spot.row][spot.column] = `${DEBUGGER ? '*' : ''}${word[index]}`;
                })
            }else{
                return false
            }
        }
        return true;
    }

    _fillTheRest(){
        for(let row=0; row< this.rowsAmount; row++){
            for(let column=0; column<this.columnAmount; column++){
                if(this.matrix[row][column] === null){
                    this.matrix[row][column] = ALPHA_BET[Math.floor((Math.random() * ALPHA_BET.length))];
                }
            }
        }
    }

    _getWordPathDirection({word, row, column}){
        const optionalSpots = [];


        const findSpots = ({direction})=>{
            let spot = [{row, column, char: word[0]}];

            let rowIndex,columnIndex;
            for(let index = 1; index < word.split('').length; index++){
                const char = word[index];
                switch (direction){
                    case 'up':
                        rowIndex = row - (index);
                        columnIndex = column;
                        break;
                    case 'down':
                        rowIndex = row + (index);
                        columnIndex = column;
                        break;
                    case 'right':
                        rowIndex = row;
                        columnIndex = column + (index);
                        break;
                    case 'left':
                        rowIndex = row;
                        columnIndex = column - (index);
                        break;
                    case 'right-up':
                        rowIndex = row - (index);
                        columnIndex =  column + (index);
                        break;
                    case 'right-down':
                        rowIndex = row + (index);
                        columnIndex =  column - (index);
                        break;
                    case 'left-up':
                        rowIndex = row - (index);
                        columnIndex =  column + (index);
                        break;
                    case 'left-down':
                        rowIndex = row + (index);
                        columnIndex =  column - (index);
                        break;
                }

                if(this.matrix[rowIndex] && this.matrix[rowIndex][columnIndex] === null){
                    //empty cell, lets continue :)
                    spot.push({row: rowIndex, column: columnIndex, char});
                }else{
                    //cell already has char
                    return;
                }
            }

            optionalSpots.push(spot);
        }

        findSpots({direction: 'up'});
        findSpots({direction: 'down'});
        findSpots({direction: 'right'});
        findSpots({direction: 'left'});
        findSpots({direction: 'right-up'});
        findSpots({direction: 'left-up'});
        findSpots({direction: 'right-down'});
        findSpots({direction: 'left-down'});

        return optionalSpots;
    }
    getMatrix(){
        return this.matrix;
    }

    onCellClicked({value, rowNum, columnNum}){
        // console.log(`onCellClicked, value: ${value}, [${rowNum},${columnNum}]`);
        if(this.clickedCellsMap[`${rowNum},${columnNum}`]){ //cell already choose
            delete this.clickedCellsMap[`${rowNum},${columnNum}`];
            this.matrixMap[`${rowNum},${columnNum}`] = '';
        }else{ //cell not choose
            this.clickedCellsMap[`${rowNum},${columnNum}`] = true;
            this.matrixMap[`${rowNum},${columnNum}`] = 'clicked';
        }
        return { matrixMap : this.getMatrixMap(), newWordMatched: this._calcMatchedWord() };

    }

    _calcMatchedWord(){
        // console.log("[_calcMatchedWord] - this.clickedCellsMap: ",this.clickedCellsMap);
        let clickedWord = '';
        Object.keys(this.clickedCellsMap).forEach(matrixKey=>{
            const [clickedRowNum, clickedColumnNum] = matrixKey.split(',');
            clickedWord += this.matrix[clickedRowNum][clickedColumnNum];
        })
        //for debug:
        clickedWord = clickedWord.replaceAll('*','');


        const _isStringsEqual = (str1,str2)=>{
            if(str1.length !== str2.length) return false;
            str1.split('').forEach(char=>{
                if(str2.includes(char)){
                    str2 = str2.replace(char,'');
                }
                else{
                    return;
                }
            })
            return str2.length === 0 ? true : false;
        }

        for(let i =0; i< this.unMatchedWords.length; i++){
            if(_isStringsEqual(this.unMatchedWords[i], clickedWord)){
                //word is matched!
                this.unMatchedWords[i] = null;
                this.unMatchedWords = this.unMatchedWords.filter(a=>a);
                Object.keys(this.clickedCellsMap).forEach(matrixKey=>{
                    const [clickedRowNum, clickedColumnNum] = matrixKey.split(',');
                    this.matrixMap[`${clickedRowNum},${clickedColumnNum}`] = 'matched';
                });
                if(this.unMatchedWords.length === 0){
                    //game has finished:
                    clearInterval(this.secondInterval);
                }
                return true;
            }
        }
        return false;
    }

    _isTight({row1, column1, row2, column2}){
        return Math.abs(Number(row1) - Number(row2)) <= 1 && Math.abs(Number(column1) - Number(column2)) <= 1
    }

    isAllowedToClick({rowNum, columnNum}){
        // console.log(`isAllowedToClick:(${rowNum},${columnNum}) this.clickedCellsMap`,this.clickedCellsMap)
        if(this.matrixMap[`${rowNum},${columnNum}`] === 'matched') return {allowed: false, matched: true};
        if(Object.keys(this.clickedCellsMap).length === 0) return {allowed: true};
        if(Object.keys(this.clickedCellsMap).length === 1){
            const [clickedRowNum, clickedColumnNum] = Object.keys(this.clickedCellsMap)[0].split(',');
            if(this._isTight({row1: clickedRowNum, column1: clickedColumnNum, row2: rowNum, column2: columnNum})){
                return {allowed: true};
            }
        }
        if(Object.keys(this.clickedCellsMap).length > 1 ){
            const hasCellTight = Object.keys(this.clickedCellsMap).some(cellMapKey=>{
                const [clickedRowNum, clickedColumnNum] = cellMapKey.split(',');
                return this._isTight({row1: clickedRowNum, column1: clickedColumnNum, row2: rowNum, column2: columnNum})
            })
            if(!hasCellTight){
                return {allowed: false};
            }
            //check if on the same line:

            const [x_1, y_1] = Object.keys(this.clickedCellsMap)[0].split(',').map(a=>Number(a));
            const [x_2, y_2] = Object.keys(this.clickedCellsMap)[1].split(',').map(a=>Number(a));

            if(x_1 === x_2){
                const isOnTheSameLine = Object.keys(this.clickedCellsMap).every(cellMapKey=>{
                    const [clickedRowNum, clickedColumnNum] = cellMapKey.split(',').map(a=>Number(a));
                    return rowNum === clickedRowNum;
                })
                if(isOnTheSameLine) return {allowed: true};
            }
            if(y_1 === y_2){
                const isOnTheSameLine = Object.keys(this.clickedCellsMap).every(cellMapKey=>{
                    const [clickedRowNum, clickedColumnNum] = cellMapKey.split(',').map(a=>Number(a));
                    return columnNum === clickedColumnNum;
                })
                if(isOnTheSameLine) return {allowed: true};
            }

            if(Math.abs(x_1 - x_2) === 1 && (Math.abs(y_1 - y_2) === 1)){
                const isOnTheSameLine = Object.keys(this.clickedCellsMap).some(cellMapKey=>{
                    const [clickedRowNum, clickedColumnNum] = cellMapKey.split(',').map(a=>Number(a));
                    return Math.abs(rowNum - clickedRowNum) === 1 && (Math.abs(columnNum - clickedColumnNum) === 1)
                })
                if(isOnTheSameLine){
                    const isOnTheSameLine2 = Object.keys(this.clickedCellsMap).every(cellMapKey=>{
                        const [clickedRowNum, clickedColumnNum] = cellMapKey.split(',').map(a=>Number(a));
                        return rowNum !== clickedRowNum && columnNum !== clickedColumnNum;
                    })
                    if(isOnTheSameLine2) return {allowed: true};
                    if(this.clickedCellsMap[`${rowNum},${columnNum}`]) return {allowed: true};
                }

            }
        }

        return {allowed: false};
    }
}

export default Matrix;