const EVENT_TYPES = {
    BIRTHDAY: {
        id: 'BIRTHDAY',
        text_view_name: 'birthday'
    },
    ANNIVERSARY: {
        id: 'ANNIVERSARY',
        text_view_name: 'anniversary'
    },
    DEATH_DAY: {
        id: 'DEATH_DAY',
        text_view_name: 'death_day'
    },
    OTHER: {
        id: 'OTHER',
        text_view_name: 'other'
    }
}

export default { EVENT_TYPES }