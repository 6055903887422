import React, {useEffect, useState} from 'react';
import './UnionOfSynagoguesLinkButton.scss';
import {Link} from "react-router-dom";
import utils from "../../../../../helpers/utils";
import TextViewer from "../../../../../helpers/textViewer";


const UnionOfSynagoguesLinkButton = (props)=>{
    const {loading} = props;

    if(loading) return null;

    return  <Link to={`/${utils.getSynagogueName()}/unisyn`}>
        <div className={'union-of-synagogues-link-container'}>
            <TextViewer text={'union_of_synagogues'}/>
        </div>
    </Link>
}

export default UnionOfSynagoguesLinkButton;