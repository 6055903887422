import React, {useEffect, useState} from 'react';
import './SelectDate.scss';
import config from "../../../../../config";
import moment from "moment";
import SingletoneStoreClass from "../../../../../Store/Store";
import TextViewer from "../../../../../helpers/textViewer";
import {ReactComponent as yellow_dot_logo} from "../../../icons/yellow-dot.svg";
import Icon from "@ant-design/icons";

const store = SingletoneStoreClass.getInstance();

const DAYS_MAP = {
    0: "sunday_short",
    1: "monday_short",
    2: "tuesday_short",
    3: "wednesday_short",
    4: "thursday_short",
    5: "friday_short",
    6: "saturday_short"
}


const SelectDate = (props) => {
    const { dates, location, selectedDateJSON, setSelectedDateJSON, currentDate } = props;

    const [description, setDescription] = useState('');
    const [eventMAp, setEventMAp] = useState({});


    const onSelectItemClicked = (dateJSON) =>{
        setSelectedDateJSON(dateJSON);
        if(eventMAp[dateJSON] && eventMAp[dateJSON].length > 0){
            setDescription(eventMAp[dateJSON].join(' \n '));
        }else{
            setDescription('');
        }
    }

    const scrollToCurrentDate = ()=>{
        try{
            document.getElementById('current-date!').scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'end'});
        }catch (e){
            console.warn('got error to scroll to date!',e);
        }
    }

    useEffect(() => {
        const map = {};
        dates.forEach(date=>{
            const events = store.getEventsPerDate(date, location);
            map[date.toJSON()] = events;
        })
        setEventMAp(map);
        if(map[currentDate.toJSON()]) setDescription(map[currentDate.toJSON()].join(' \n '));
        scrollToCurrentDate();
        setTimeout(()=>{
            if( document.getElementById('nearest-event-time')) document.getElementById('nearest-event-time').scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'end'});
        },1000)

        // for some reason this need to be done offen
        setTimeout(()=>{
            scrollToCurrentDate()
        },100);

        setTimeout(()=>{
            scrollToCurrentDate()
        },500);

        setTimeout(()=>{
            scrollToCurrentDate()
        },1500);

    }, []);

    return <div>
        <div className={'top-calender-container'}>
            {dates.map(dateToShow=>{
                const { monthName, dayOfMonth, year } = store.getHebrewDayAndMouthAndYear({date: dateToShow, location});
                const events = eventMAp[dateToShow.toJSON()];
                return <div className={'top-calender-item-container'}>
                    <div className={`top-calender-item${dateToShow.toJSON() === selectedDateJSON ? ' calender-item-selected' : ''}${dateToShow.toJSON() === currentDate.toJSON() ? ' calender-item-today' : ''}`}
                    onClick={()=>onSelectItemClicked(dateToShow.toJSON())} key={dateToShow.toJSON()} id={dateToShow.toJSON() === currentDate.toJSON() ? 'current-date!' : dateToShow.toJSON()}>
                        <div className={'calender-item-day-name'}>{<TextViewer text={DAYS_MAP[dateToShow.getDay()]}/>}</div>
                        <div className={'calender-item-day-month'}>{config.hebrewNumToDay[dayOfMonth].replace('״','')}</div>
                        <div className={'calender-item-month-name'}>{config.hebrewMonthEnfToHebrew[monthName]}</div>
                        <div className={'calender-item-date-foreign'}>{moment(dateToShow).format('D/M')}</div>
                    </div>
                    {events?.length > 0 ? <div className={'event-item-indicator'}><Icon component={yellow_dot_logo} className={'yellow-dot-icon'}/></div> : <div className={'event-item-indicator'}></div>}
                </div>
            })}
        </div>
        {description ? <div className={'event-description-data'}>{description}</div> : <div className={'event-description-empty'}>{description}</div>}

    </div>
}

export default SelectDate;