import React, {useState, useEffect} from 'react';
import './plasma.scss';
import '../PrayersDetails/PrayerDetails/PrayerDetails.scss';
import Loading from "../../Main/Loading/Loading";
import TextViewer from "../../../../helpers/textViewer";
import {useHistory} from "react-router-dom";
import {message} from "antd";
import PlasmaModesSetting from '../../Main/Plasma/PlasmaModesSetting';
import PlasmaBackgrounds from "../../Main/Plasma/PlasmaBackgrounds";
message.config({
    top: '90%',
    duration: 2,
    maxCount: 3,
    rtl: true
});

const addDefaults = (currentPlasmaModesSetting = {})=>{
    Object.keys(PlasmaModesSetting).forEach(slideId=>{
        if(!currentPlasmaModesSetting[slideId]) currentPlasmaModesSetting[slideId] = PlasmaModesSetting[slideId];
    })
    return currentPlasmaModesSetting;
}

const Plasma = (props)=>{

    const { loading, plasmaSetting = {}, updateData } = props;

    const [itemToSave, setItemToSave] = useState({...plasmaSetting, plasmaModesSetting: addDefaults(plasmaSetting.plasmaModesSetting)});

    // useEffect(() => {
    //     console.log("Plasma: ",{plasmaSetting, itemToSave});
    // }, [itemToSave, plasmaSetting]);

    const history = useHistory();

    const onTextInputChange = (event, keyName)=>{
        setItemToSave({...itemToSave, [keyName] : event.target.value})
    }

    const onCheckboxChange = (event, keyName)=>{
        setItemToSave({...itemToSave, [keyName] : event.target.checked})
    }

    if(loading) return <Loading/>

    const isEnabledToSaved = ()=>{
        const hasChanged =  JSON.stringify(plasmaSetting) !== JSON.stringify(itemToSave);
        return hasChanged
    }

    const onSaveClicked = ()=>{
        updateData({fieldName: 'plasmaSetting', data: itemToSave});
        message.open({
            type: 'success',
            content: TextViewer({text: 'saved', returnString: true}),
        })
    }

    const renderItem = (slideId)=>{
        const {text_view_name, duration: defaultDuration, enable: defaultEnable} = PlasmaModesSetting[slideId];

        let duration, enable;
        if(itemToSave.plasmaModesSetting){
            duration = itemToSave.plasmaModesSetting[slideId]?.duration;
            enable = itemToSave.plasmaModesSetting[slideId]?.enable;
        }else{
            duration = defaultDuration;
            enable = defaultEnable;
        }

        const _onchange = (key, newValue)=>{
            const lastItemToSave = JSON.parse(JSON.stringify(itemToSave));

            if(!lastItemToSave.plasmaModesSetting){
                lastItemToSave.plasmaModesSetting = JSON.parse(JSON.stringify(PlasmaModesSetting));
            }
            lastItemToSave.plasmaModesSetting[slideId][key] = newValue;
            setItemToSave(lastItemToSave)
        }

        const onDurationChange = (event)=>{
            _onchange('duration', Number(event.target.value));
        }

        const onDisableChanged = (event) => {
            _onchange('enable', event.target.checked);
        }

        return <div className={'advanced-plasma-setting-item-container'}>
            <div className={'slide_name'}><TextViewer text={text_view_name} addChar={':'}/></div>
            <div>
                <span> <TextViewer text={'slide_duration'} addChar={':'}/></span>
                <input type={'number'} min={1} max={60 * 5} value={duration} onChange={onDurationChange}/>
                <span> <TextViewer text={'seconds'}/></span>

            </div>
            <div>
                <span> <TextViewer text={'display_slide'} addChar={':'}/></span>
                <input type={'checkbox'} checked={enable} onChange={onDisableChanged}/>
            </div>

        </div>
    }

    return <div className={'plasma-edit-container'}>
        <TextViewer text={'plasma_setting'}/>
        <div className={'plasma-edit-text'}>
            <TextViewer text={'plasma_donation_details'}/>
            <textarea rows={'2'} onChange={(event)=>onTextInputChange(event, 'plasmaDonation')} value={itemToSave.plasmaDonation}/>
        </div>
        <div className={'plasma-edit-text'}>
            <TextViewer text={'split_screens_multi_minayans'}/>
            <input type={'checkbox'} onChange={(event)=>onCheckboxChange(event, 'splitScreensByMinyan')} checked={itemToSave.splitScreensByMinyan}/>
        </div>
        <div>
            <TextViewer text={'plasma_advanced_setting'}/>
            {Object.values(PlasmaModesSetting).map(plasmaModesSetting=>renderItem(plasmaModesSetting.slideId))}
        </div>
        <div className={'plasma-choose-background-image'}>
            <TextViewer text={'choose_background_image'}/>
            <div className={'background_images'}>
                {Object.keys(PlasmaBackgrounds).map(PlasmaBackgroundKey=><div className={'background_image'} onClick={()=>{
                    onTextInputChange({target: {value: PlasmaBackgroundKey}}, 'backgroundName')
                }}>
                    <img src={PlasmaBackgrounds[PlasmaBackgroundKey]}/>
                    <input type={'checkbox'} checked={itemToSave?.backgroundName ===  PlasmaBackgroundKey ? true : (!itemToSave.backgroundName && PlasmaBackgroundKey === 'default' ? true : false)} onChange={()=>{}}/>
                </div>)}
            </div>
        </div>
        <div className={'bottom-buttons'}>
            <button className={'bottom-buttons-save'} disabled={!isEnabledToSaved()} onClick={onSaveClicked}>{<TextViewer text={'save'}/>}</button>
            <button className={'bottom-buttons-back'} onClick={()=>{
                history.push({
                    hash: 'main'
                });
            }}><TextViewer text={'return'}/></button>
        </div>
    </div>

}

export default Plasma;