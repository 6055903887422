import React, {useEffect, useState} from "react";
import './Scores.scss';

import { Modal } from 'antd';
import SingletoneStoreClass from "../../../../Store/Store";

import utils from '../../../../helpers/utils';
import TextViewer from "../../../../helpers/textViewer";
import Loading from "../../Main/Loading/Loading";
import loading_gif from "../../icons/animations/loading.gif";
import {analyticsAddLogEvent} from "../../../../firebase/firebase";
const store = SingletoneStoreClass.getInstance();


const ScoresModal = (props) => {


    const {isModalVisible, setIsModalVisible, currentParasha, finishGameScoreSeconds} = props;

    const [scores, setScores] = useState([]);
    const [loading, setLoading] = useState(false);

    const renderTime = (seconds)=>{
        let string = TextViewer({text: "time", returnString: true, addChar: ': '});
        if(seconds < 60){
            string += seconds + TextViewer({text: "seconds", returnString: true, addChar: ' ', addBefore: true});
        }else{
            string += `${Math.floor(seconds/60)}:${Math.round(seconds%60) < 10 ? `0${Math.round(seconds%60)}`: Math.round(seconds%60)}` + TextViewer({text: "minutes", returnString: true, addChar: ' ', addBefore: true});
        }
        return string;
    }


    useEffect(() => {
        analyticsAddLogEvent({eventName: 'game_word_search_score_view', params: { name: utils.getSynagogueName()}});

        const fetch = async ()=>{
           setLoading(true);
           const response = await store.getGameScores({parasha: currentParasha, type: 'word-search'});
           console.log("----response:",response)
           setScores(response);
           setLoading(false);
       }
       if(isModalVisible) fetch();
    }, [isModalVisible]);

    useEffect(() => {
        setTimeout(()=>{
            try{
                document.getElementsByClassName("score-item-data yellow")[0].scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'end'});
            }catch (e){

            }
        },1000)

    }, [scores]);

    const onModalClicked = ()=> {
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const renderScoreItem = ({ gameDurationSeconds, name, synagogueName, synagogueId, index})=>{
        return <div className={'score-item'}>

            <div className={'score-number'}>
                {index + 1}
            </div>
            <div className={`score-item-data ${(finishGameScoreSeconds && finishGameScoreSeconds === gameDurationSeconds) ? 'yellow' : '' }`}>
                <div className={'key_value'}>
                    <div className={'key'}>
                        <TextViewer text={"name"} addChar={':'}/>
                    </div>
                    <div className={'value'}>
                        {name}
                    </div>
                </div>
                <div className={'break'}/>
                <div className={'key_value'}>
                    <div className={'key'}>
                        <TextViewer text={"time"} addChar={':'}/>
                    </div>
                    <div className={'value'}>
                        {renderTime(gameDurationSeconds)}
                    </div>
                </div>
                <div className={'break'}/>
                <div className={'key_value'}>
                    <div className={'key'}>
                        <TextViewer text={"community"} addChar={':'}/>
                    </div>
                    <div className={'value'}>
                        {synagogueName}
                    </div>
                </div>
            </div>

        </div>

    }

    return (
        <div>
            <Modal className={'scores-modal'}
                   title={<div className={'scores-modal-title'}><TextViewer text={"scores"} addedString={currentParasha} addChar={' - '}/></div>} open={isModalVisible} onCancel={handleCancel} footer={null} maskClosable={true}>
                <div className={'scores-modal-content'} onClick={onModalClicked}>
                    {loading ? <img src={loading_gif} alt="loading..." /> : <div>
                        {scores.sort((a,b)=>a.gameDurationSeconds - b.gameDurationSeconds).map((score,index)=>renderScoreItem({...score, index}))}
                    </div>}
                </div>

            </Modal>
        </div>
    );

}


export default ScoresModal;