import React, {useEffect, useState} from 'react';
import './Info.scss';
import SingletoneStoreClass from "../../../../Store/Store";
import TextViewer from "../../../../helpers/textViewer";
import {ReactComponent as location_logo} from "../../icons/location.svg";
import {ReactComponent as phone_logo} from "../../icons/phone.svg";
import {ReactComponent as email_logo} from "../../icons/mail.svg";
import {ReactComponent as website_logo} from "../../icons/website.svg";
import {ReactComponent as youtube_logo} from "../../icons/youtube.svg";
import {ReactComponent as facebook_logo} from "../../icons/facebook.svg";
import {ReactComponent as whatsapp_logo} from "../../icons/whatsapp.svg";
import {ReactComponent as form_logo} from "../../icons/form.svg";
import Icon from "@ant-design/icons";
import {Link} from "react-router-dom";
import utils from "../../../../helpers/utils";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper";
import FloatButtonDonation from "../Donation/FloatButtonDonation/FloatButtonDonation";
import {analyticsAddLogEvent} from "../../../../firebase/firebase";


const store = SingletoneStoreClass.getInstance();


const Info = (props)=>{

    const [info, setInfo] = useState('');
    const [base64Logo, setBase64Logo] = useState('');
    const [base64Photo, setBase64Photo] = useState('');
    const [synagogueAddressString, setSynagogueAddressString] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [synagogueEmailAddress, setSynagogueEmailAddress] = useState('');
    const [synagogueYoutubeUrl, setSynagogueYoutubeUrl] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [externalFormDetails, setExternalFormDetails] = useState('');
    const [externalFormUrl, setExternalFormUrl] = useState('');
    const [facebookUrl, setFacebookUrl] = useState('');
    const [synagogueName, setSynagogueName] = useState('');
    const [location, setLocation] = useState(null);
    const [loading, setLoading] = useState(true);
    const [donationObj, setDonationObj] = useState(null);


    useEffect(() => {
        const fetchData = async ()=>{
            let { info, extraDetails, name, location, donation } = await store.getSynagoguePublicValues({keys: ['info', 'extraDetails', 'name', 'location', 'donation']});
            setInfo(info);
            setLoading(false);
            if(extraDetails?.base64Logo) setBase64Logo(extraDetails.base64Logo);
            if(extraDetails?.base64Photo) setBase64Photo(extraDetails.base64Photo);
            if(extraDetails?.externalFormDetails) setExternalFormDetails(extraDetails.externalFormDetails);
            if(extraDetails?.externalFormUrl) setExternalFormUrl(extraDetails.externalFormUrl);
            if(extraDetails?.facebookUrl) setFacebookUrl(extraDetails.facebookUrl);
            if(extraDetails?.synagogueAddressString) setSynagogueAddressString(extraDetails.synagogueAddressString);
            if(extraDetails?.phoneNumber) setPhoneNumber(extraDetails.phoneNumber);
            if(extraDetails?.synagogueEmailAddress) setSynagogueEmailAddress(extraDetails.synagogueEmailAddress);
            if(extraDetails?.synagogueYoutubeUrl) setSynagogueYoutubeUrl(extraDetails.synagogueYoutubeUrl);
            if(extraDetails?.websiteUrl) setWebsiteUrl(extraDetails.websiteUrl);
            setDonationObj(donation);

            setSynagogueName(name);
            setLocation(location);
            // console.log("extraDetails=",extraDetails)
        };
        fetchData();
        analyticsAddLogEvent({eventName: 'info_event', params: { name: utils.getSynagogueName()}});

    }, []);

    if(loading){
        return  <TextViewer text={"loading"} addedString={'...'}/>
    }

    const renderPic = ()=>{
        const slides = [];
        if(base64Photo){
            slides.push(<SwiperSlide>
                <img src={base64Photo} alt="avatar" style={{width: '100%'}}/>
            </SwiperSlide>)
        }

        if(base64Logo){
            slides.push(<SwiperSlide>
                <img src={base64Logo} alt="avatar" style={{width: '100%'}}/>
            </SwiperSlide>)
        }

        return <Swiper
            speed={3000}
            centeredSlides={true}
            autoplay={{
                delay: 5000,
                disableOnInteraction: true,
            }}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Navigation]}
        >
            {slides.map(s=>s)}
        </Swiper>
    }

    const renderMainLinks = ()=>{
        const items = [];
        if(synagogueAddressString){
            items.push(<div className={'main-link-container'}>
                <Icon component={location_logo} className={'main-link-icon'} onClick={()=>{
                    if(location) utils.navigateToSynagogue({...location})
                }}/>
                <div className={'main-link-text'}>
                    <span  onClick={()=>{
                        if(location) utils.navigateToSynagogue({...location})
                    }}>{synagogueAddressString}</span>
                </div>
            </div>)
        }
        if(phoneNumber){
            let number;
            if(phoneNumber.startsWith('0')){
                number = phoneNumber.toString();
            }
            else{
                number = `0${phoneNumber}`
            }

            let phoneToView;
            if(number.length === 10){
                phoneToView = `${number.slice(0,3)}-${number.slice(3,number.length)}`;
            }else{
                phoneToView = `${number.slice(0,2)}-${number.slice(2,number.length)}`;
            }

            items.push(<div className={'main-link-container'}>
                <a href={`tel:${number}`}><Icon component={phone_logo} className={'main-link-icon'}/></a>
                <div className={'main-link-text'}>
                    <span><a href={`tel:${number}`}>{phoneToView}</a></span>
                </div>
            </div>)
        }
        if(synagogueEmailAddress){
            items.push(
                <div className={'main-link-container'}>
                    <a href = {`mailto:${synagogueEmailAddress}?subject=${synagogueName}`}><Icon component={email_logo} className={'main-link-icon'}/></a>
                   <div className={'main-link-text'}>
                       <span> <a href = {`mailto:${synagogueEmailAddress}?subject=${synagogueName}`}>{synagogueEmailAddress}</a></span>
                   </div>
                </div>
           )
        }
        if(externalFormUrl){
            items.push(<div className={'main-link-container'}>
                        <Link to={`/${utils.getSynagogueName()}/external-url`}>
                            <Icon component={form_logo} className={'main-link-icon'}/>
                        </Link>

                            <div className={'main-link-text'}>
                                <Link to={`/${utils.getSynagogueName()}/external-url`}>{externalFormDetails ?
                                <span>{externalFormDetails}</span> : <TextViewer text={"external_form_url_description"}/>}
                                </Link>
                            </div>
                    </div>)
        }
        return items;
    }

    const renderMiniLinks = ()=>{
        const items = [];
        if(phoneNumber){
            items.push(<Icon component={whatsapp_logo} className={'mini-icon'} onClick={()=>utils.sendWhatsAppMessage({msg: '', phoneNumber})}/>)
        }
        if(facebookUrl){
            items.push(<a href={facebookUrl} target="_blank"><Icon component={facebook_logo} className={'mini-icon'}/></a>)
        }
        if(synagogueYoutubeUrl){
            items.push(<a href={synagogueYoutubeUrl} target="_blank"><Icon component={youtube_logo} className={'mini-icon'}/></a>)
        }
        if(websiteUrl){
            items.push(<a href={websiteUrl} target="_blank"><Icon component={website_logo} className={'mini-icon'}/></a>)
        }

        return <div className={'mini-icons-container'}>{items.map(i=>i)}</div>
    }


    return <div className={'info-container'}>
        <div className={'info-top-container'}>
            <div className={'info-top-links'}>
                {renderMainLinks()}
                {renderMiniLinks()}
            </div>
            <div className={'info-top-pic'}>
                {renderPic()}
            </div>
        </div>
        <div className={'info-body'} dangerouslySetInnerHTML={{ __html: utils.smartStringRenderLinks(info) }}/>
        <FloatButtonDonation loading={loading} donationObj={donationObj}/>
    </div>
}

export default Info;