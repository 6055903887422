import React, {useEffect, useState} from 'react';

import './DailyStudies.scss';
import TextViewer from "../../../../helpers/textViewer";
import { Swiper, SwiperSlide } from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper";
import Loading from "../Loading/Loading";
import DailyStudy from "./DailyStudy";

const MAX_RANGE_TO_SHOW = 5

const getDateRanges = ()=>{
    const currentDate = new Date();
    const datesToShow = [];

    for(let i = MAX_RANGE_TO_SHOW; i > 0; i--){
        datesToShow.push(new Date(new Date().setDate(currentDate.getDate() - i)));
    }
    datesToShow.push(currentDate);
    for(let i = 1; i < MAX_RANGE_TO_SHOW; i++){
        datesToShow.push(new Date(new Date().setDate(currentDate.getDate() + i)));
    }

    //for DEBUG ONLY! // buildHebrewDateMap();

    return datesToShow;
}
const DailyStudies = (props)=>{

    const [loading, setLoading] = useState(true);




    // useEffect(() => {
    //     const run = async ()=>{
    //         setLoading(true);
    //         let usersStats = await store.getSefariaDailyEvents();
    //         setUserStats(usersStats);
    //         setLoading(false);
    //     }
    //     run();
    // }, [])


    const renderPage = ()=>{
        if(loading) return <Loading/>

    }


    return <div className={'daily-studies-container'}>

        <div className={'daily-studies-title'}>
            <TextViewer text={"daily_studies"}/>
        </div>

        <div className={'daily-studies-swipe-container'}>
            <Swiper
                initialSlide={MAX_RANGE_TO_SHOW}
                pagination={{
                    dynamicBullets: true,

                }}
                navigation={true}
                modules={[Pagination]}
                className="daily-studies-container"
            >
                {getDateRanges().map(date=><SwiperSlide><DailyStudy date={date}/></SwiperSlide>)}
            </Swiper>
        </div>


    </div>
}

export default DailyStudies;