import React, {useState, useEffect} from 'react';
import './invoices.scss';
import TextViewer from "../../../../helpers/textViewer";
import pdfLogo from "../../icons/pdf-red.svg";
import shareLogo from "../../icons/share-logo.svg";
import moment from "moment/moment";
import InvoiceGenerator from "../../PDFGenerator/InvoiceGenerator";


const TextViewMap = {
    id: 'invoice_number'
}
const RenderInvoice = (props)=>{

    const { invoice, base64Logo } = props;

    const { id, amount, associationName, associationNumber, createdAt, description, donorFullName, paymentType, synagogueAddress, synagogueEmail, synagoguePhone } = invoice;

    const renderKeyValue = ({key, value})=>{
        if(!value) return null;
        return <div className={'key_value'}>
            <div className={'key'}> <TextViewer text={TextViewMap[key] || key} addChar={':'}/></div>
            <div className={'value'}>{value}</div>
        </div>
    }

    return <div className={'list-item'}>
        <div className={'action-buttons'}>
            <button className={'pdf-item'} onClick={async ()=>{
                const invoiceGenerator = new InvoiceGenerator({
                    base64Logo,
                    invoice
                });
                await invoiceGenerator.init();
                invoiceGenerator.makePDF();
            }}>
                <img src={pdfLogo} alt="icon"/>

            </button>
            <button className={'pdf-item'} onClick={async ()=>{
                const invoiceGenerator = new InvoiceGenerator({
                    base64Logo,
                    invoice
                });
                await invoiceGenerator.init();
                await invoiceGenerator.sharePDF();
            }}> <img src={shareLogo} alt="icon"/></button>
        </div>

        <div className={'preview-item'}>
            {renderKeyValue({key: 'amount', value: `${amount} ₪`})}
            {renderKeyValue({key: 'id', value: id})}
            {renderKeyValue({key: 'donor_full_name', value: donorFullName})}
            {renderKeyValue({key: 'created_at', value: moment(new Date(createdAt)).format("DD.MM.YYYY"), date: true})}
            {renderKeyValue({key: 'description', value: description})}
        </div>

    </div>
}

export default RenderInvoice;