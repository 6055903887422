import  {Component} from 'react';
import SingletoneStoreClass from "../../Store/Store";
import utils from "../../helpers/utils";

const store = SingletoneStoreClass.getInstance();

class BaseComponent extends Component {
    static hasLogicalName = false;

    constructor(props) {
        super(props);
        console.log("[[[[[[[[[[[[[[[[[[[------------------------BaseComponent------------------------]]]]]]]]]]]]]]]]]]]");
        const pathname = window.location.pathname;
        const urlFirstParam = pathname.split("/")[1];
        if(urlFirstParam && urlFirstParam !== 'kids'){
            this.setSynagogueName(urlFirstParam);
        }

        if(pathname === '/kids'){
            let logicalName = utils.getLogicalNameFromLocalStorage();
            if(logicalName){
                console.info(`got url without logicalName using logicalName from the localStorage!`);
                window.history.pushState("", "", `/${logicalName}/kids`);
                BaseComponent.hasLogicalName = true;
                this.setSynagogueName(logicalName);
            }else{
                window.history.pushState("", "", `/`);

            }
        }

        if(pathname === "/"){
            let logicalName = utils.getLogicalNameFromLocalStorage();
            if(logicalName){
                console.info(`got url without logicalName using logicalName from the localStorage!`);
                window.history.pushState("", "", `/${logicalName}${window.location.hash ? `${window.location.hash}` : ''}`);
                BaseComponent.hasLogicalName = true;
                this.setSynagogueName(logicalName);
            }else{
                console.info(`got url without logicalName + no logicalName exit on the localStorage!`);
                BaseComponent.hasLogicalName = false;
            }
        }
    }

    setSynagogueName(logicalName){
        //todo: make sure it's a valid synagouge name!
        utils.setLogicalNameFromLocalStorage(logicalName);
        store.setSynagogueName(logicalName);
        BaseComponent.hasLogicalName = true;
    }
}

export default BaseComponent;

