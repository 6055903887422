import React, {useEffect, useState} from 'react';
import './DisplayEventsByDate.scss';
import SingletoneStoreClass from "../../../../../Store/Store";
import tfilotParser from "../../../../../helpers/TfilotOfTheDay/tfilotParser";
import TextViewer from "../../../../../helpers/textViewer";
import utils from '../../../../../helpers/utils';
import calendarConfig from '../DateUtils/config';
import EventItem from "./EventItem/EventItem";

const store = SingletoneStoreClass.getInstance();

const MAX_EVENT_NAME_LENGTH = 25;

const DisplayEventsByDate = (props)=>{

    const { filters, date, id, plasmaMode=false, minyanName } = props;

    const {monthName, dayOfMonth} = store.getHebrewDayAndMouthAndYear({date});

    const [tfilotTimes, setTfilotTimes] = useState({});
    const [holidayTimes, setHolidayTimes] = useState({});
    const [lessonsTimes, setLessonsTimes] = useState({});
    const [times, setTimes] = useState(null);
    const [location, setLocation] = useState(null);
    const [useYeshivaTimes, setUseYeshivaTimes] = useState(null);
    const [holidayTimesMetadata, setHolidayTimesMetadata] = useState(null);
    const [loading, setLoading] = useState(true);
    const [shabatEnterMinBeforeSunset, setShabatEnterMinBeforeSunset] = useState(null);
    const [shabatExitMinAfterStarsCameOut, setShabatExitMinAfterStarsCameOut] = useState(null);

    const currentDate = new Date();

    useEffect(() => {
        const fetchData = async ()=>{
            let { tfilotTimes, holidayTimes, extraDetails, location, lessonsTimes, times } = await store.getSynagoguePublicValues({keys: ['tfilotTimes', 'holidayTimes', 'extraDetails', 'location', 'lessonsTimes', 'times']});
            setTfilotTimes(tfilotTimes);
            setHolidayTimes(holidayTimes);
            setLessonsTimes(lessonsTimes);
            setLocation(location);
            setTimes(times);
            setUseYeshivaTimes(extraDetails.useYeshivaTimes);
            setHolidayTimesMetadata(extraDetails.holidayTimesMetadata);
            setShabatEnterMinBeforeSunset(extraDetails.shabatEnterMinBeforeSunset);
            setShabatExitMinAfterStarsCameOut(extraDetails.shabatExitMinAfterStarsCameOut);
            setLoading(false);
        };
        fetchData();
    }, []);

    if(loading){
        return <div className={'display-events-by-Date'}>
            <TextViewer text={"loading"} addedString={'...'}/>
        </div>
    }

    let events = [];

    if(times){
        if(filters.includes(calendarConfig.FILTERS.prayers.name)){
            const tfilotOfTheDay = tfilotParser.calcTimesV2({times, date, location, useYeshivaTimes, types:['tfila'], minyanName, shabatExitMinAfterStarsCameOut, shabatEnterMinBeforeSunset})
            events = events.concat(tfilotOfTheDay.map(t=>({...t, icon: calendarConfig.FILTERS.prayers.icon})));
        }
        if(filters.includes(calendarConfig.FILTERS.studies.name)){
            const lessonsOfTheDay = tfilotParser.calcTimesV2({times, date, location, useYeshivaTimes, types:['lesson'], minyanName, shabatExitMinAfterStarsCameOut, shabatEnterMinBeforeSunset})
            events = events.concat(lessonsOfTheDay.map(t=>({...t, icon: calendarConfig.FILTERS.studies.icon})));
        }
    }else{
        //old (need to be deleted after moving to edit v2):
        if(filters.includes(calendarConfig.FILTERS.prayers.name)){
            const tfilotOfTheDay = tfilotParser.calcTfilotTimesV2({
                tfilotTimes,
                holidayTimes,
                holidayTimesMetadata,
                date,
                location,
                useYeshivaTimes,
                shabatExitMinAfterStarsCameOut,
                shabatEnterMinBeforeSunset
            });
            events = events.concat(tfilotOfTheDay.map(t=>({...t, icon: calendarConfig.FILTERS.prayers.icon})));
        }


        if(filters.includes(calendarConfig.FILTERS.studies.name)){
            const lessonsOfTheDay = tfilotParser.calcLessonsV2({
                lessonsTimes,
                date,
                location,
                shabatExitMinAfterStarsCameOut,
                shabatEnterMinBeforeSunset
            });
            events = events.concat(lessonsOfTheDay.map(t=>({...t, icon: calendarConfig.FILTERS.studies.icon})));
        }
    }





    if(filters.includes(calendarConfig.FILTERS.dayTimes.name)){
        const timesOfDay = tfilotParser.calcTimesOfDayV2({
            date,
            location,
            monthName,
            dayOfMonth
        });
        events = events.concat(timesOfDay.map(t=>({...t, icon: calendarConfig.FILTERS.dayTimes.icon})));
    }

    const isToday = date.toDateString() === currentDate.toDateString();
    const isPastDate = currentDate > date;

    const eventPass =(date)=>{
        if(isToday){
            const calcDate = new Date();
            let hours = date.getHours();
            let minutes = date.getMinutes()
            calcDate.setHours(hours);
            calcDate.setMinutes(minutes);
            return currentDate > calcDate;
        }
        if(isPastDate){
            return true;
        }
        return false;
    }
    let isNearestEvent = true;


    const renderWithMarquee = ()=>{
        const items = utils.sortTimes(events).map((event, index)=> {
            const isEventPass = eventPass(event.date);
            let nearest = false;
            if(isToday && !isEventPass && isNearestEvent){
                isNearestEvent = false;
                nearest = true;
            }
            return <EventItem
                key={`display-events-by-Date-${index}`}
                plasmaMode={plasmaMode}
                index={index}
                totalLength={events.length}
                date={event.date}
                name={plasmaMode &&  event.name.length > MAX_EVENT_NAME_LENGTH ? `${event.name.substring(0, MAX_EVENT_NAME_LENGTH)}...` : event.name}
                description={event.description}
                link={event.link || event.zoomLink}
                minyan={event.minyan}
                by={event.by}
                where={event.where}
                icon={event.icon}
                isEventPass={isEventPass}
                isNearestEvent={nearest}
            />
        });

        if(plasmaMode && events.length > 4){
            return <marquee className="GeneratedMarquee" direction="up" scrollamount="4" behavior="alternate" scrolldelay={200}>
                <div className={`display-events-by-Date`} id={id}>
                    {items}
                </div>
            </marquee>
        }

        return <div className={`display-events-by-Date`} id={id}>
            {items}
        </div>
    }

    return renderWithMarquee();
}

export default DisplayEventsByDate;