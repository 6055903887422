import React, {useEffect, useState} from 'react';
import './DvarTora.scss';
import TextViewer from "../../../../helpers/textViewer";


const DvarTora = (props)=>{
    const {loading, dvarTora, tvarToraYoutubeLink} = props;
    const [collapse, setCollapse] = useState(false);

    if(loading) return null;

    const getYoutubeLinkId = (url)=>{
        if(!url) return false;
        try{
            if(url.includes('?v=')){
                return url.split('?v=')[1];
            }
            if(url.includes('/')){
                return url.split('/').pop();
            }
            return false;
        }catch (e){
            console.warn('getYoutubeLinkId ERROR: ',e)
            return false;
        }
    }
    const youtubeId = getYoutubeLinkId(tvarToraYoutubeLink);

    if(!dvarTora && !youtubeId) return null;



    return <div className={'dvar-tora-container'}>
        <div className={'dvar-tora-title'}><TextViewer className={'calendar-loading'} text={"dvar_torah"}/></div>
        {tvarToraYoutubeLink ? <div className={'dvar-tora-video'}>
            <iframe
                src={`https://www.youtube.com/embed/${youtubeId}`}
            >
            </iframe>
        </div> : null}
        {dvarTora ? <div onClick={()=>setCollapse(!collapse)} className={`dvar-tora-text ${collapse ? 'show-full' : ''}`}>{dvarTora}</div> : null}
    </div>
};
export default DvarTora;