import React, {useState, useEffect, useRef} from 'react';
import './subscriptionModel.scss';
import {Modal} from "antd";
import base64Images from "../../PDFGenerator/base64/images";
import TextViewer from "../../../../helpers/textViewer";
import config from "../../../../config";
import phone_logo from "../../icons/phone-colored.svg";
import whatsapp_logo from "../../icons/whatsapp-colored.svg";


const MODES = {
    trialPeriodOver: {
        title: 'trial_period_over',
        whatsappMsg:'היי - צד גבאי שלי חסום'
    },
    invoiceLimited: {
        title: 'invoice_blocked',
        whatsappMsg:'היי - אני מעוניין בפתיחת האפשרות ליצירת חשבוניות דיגיטלית דרך האפליקצייה'
    }
}

const SubscriptionModel = (props)=>{

    const {synagogueName, mode} = props;

    return <Modal className={'payment-modal'}
                  visible={true} footer={null} maskClosable={false} closable={false} keyboard={false}>
        <div className={'payment-content'}>

            <img src={base64Images.logo} alt="icon" className={'mysynagogue-logo'}/>

            <TextViewer text={MODES[mode].title} addChar={'.'} className={'payment-modal-title'}/>

            <div className={'communications-actions'}>
                <div className={'payment-button'}>
                    <a href={`tel:${config.feedbackPhone}`} ><img src={phone_logo} alt="icon"/></a>
                </div>
                <TextViewer text={'or'}/>
                <div className={'payment-button'} onClick={()=>{
                    let msg = '';
                    msg+= 'היי אור,';
                    msg+= '%0A';
                    msg+= MODES[mode].whatsappMsg;
                    msg+= '%0A';
                    msg+= `*${synagogueName}*`;
                    msg+= '%0A';
                    msg+= 'עמוד חסום: ';
                    msg+= '%0A';
                    msg+= window.location.href;
                    msg+= '%0A';
                    msg+= 'תודה רבה';
                    window.open( `https://api.whatsapp.com/send?phone=${config.feedbackPhone}&text=${msg}`, '_blank');
                }}>
                    <img src={whatsapp_logo} alt="icon"/>
                </div>
            </div>

            <img src={base64Images.mySynagogueTxt} alt="icon"/>

        </div>

    </Modal>
}


export default SubscriptionModel;