import React from 'react';
import utils from './utils'
import translateText from './translateText';

const TextViewer = (props)=>{
    const { text, returnString, addedString, addChar, addBefore=false, className, onClick, id } = props;

    const result = translateText(props);
    if(returnString) return result;
    return <span dir={'auto'} className={className} onClick={onClick} id={id}>{result}</span>
}

export default TextViewer;