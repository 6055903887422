import './externalUrl.scss';
import React, {useEffect, useState} from 'react';
import SingletoneStoreClass from "../../../../Store/Store";
import utils from '../../../../helpers/utils'
import Loading from "../Loading/Loading";

const store = SingletoneStoreClass.getInstance();

const ExternalUrl = (props)=>{
    const [loading, setLoading] = useState(true);
    const [iframeLoading, setIframeLoading] = useState(true);
    const [externalFormUrl, setExternalFormUrl] = useState(null);
    const [externalFormDetails, setExternalFormDetails] = useState(null);

    useEffect(() => {
        const fetchData = async ()=>{
            let { extraDetails } = await store.getSynagoguePublicValues({keys: ['extraDetails']});
            setExternalFormUrl(extraDetails.externalFormUrl);
            setExternalFormDetails(extraDetails.externalFormDetails);
            setLoading(false);
        };
        fetchData();
    }, []);

    return <div>
        <div className={'external-url-container'}>
            <div>{externalFormDetails ? externalFormDetails : ''}</div>
            {iframeLoading ?<Loading/> : null}
            <div className={'external-url--iframe-container'}>
                <iframe
                    onLoad={()=>setIframeLoading(false)}
                    src={utils.getExternalUrl(externalFormUrl)}
                    frameBorder="0" marginHeight="0" marginWidth="0">Loading…
                </iframe>
            </div>

        </div>
    </div>
}

export default ExternalUrl;
