import React, {useState, useEffect} from 'react';

import '../PrayersDetails/PrayersDetails.scss';
import './Donations.scss';
import utils from "../../../../helpers/utils";
import TextViewer from "../../../../helpers/textViewer";
import Loading from "../../Main/Loading/Loading";
import DonationsPanel from './DonationPanel/DonationsPanel';
import add_yellow_circle_logo from "../../icons/add_yellow_circle.svg";
import {useHistory} from "react-router-dom";
import PDFGenerator from "../../PDFGenerator/PDFGenerator";
import delete_icon from "../../icons/delete.svg";
import pdf_icon from "../../icons/pdf.svg";


const Donations = (props) => {

    const { loading, users = [], updateData, synagogueName, base64Logo } = props;
    const donations = [];
    users.forEach((user, userIndex)=>{
        if(user.donations){
            user.donations.forEach((donation, index)=>{
                donations.push({...donation, user, donationIndex: index, userIndex });
            })
        }
    })
    const [checkedItemsMap, setCheckedItemsMap] = useState({});
    const [indexToRender, setIndexToRender] = useState(-1);
    const [donationIndex, setDonationIndex] = useState(-1);
    const [search, setSearch] = useState('');
    const [cloneMode, setCloneMode] = useState(false);

    const history = useHistory();

    useEffect(() => {
        const searchParams = window.location.search;

        if(searchParams.includes('?search=')){
            setSearch(decodeURI(searchParams.split('?search=')[1]))
        }

    }, []);


    // useEffect(() => {
    //     console.log("---donations DEBUG =", {checkedItemsMap, donations})
    // }, [checkedItemsMap, donations]);


    const onAddItemClicked = ()=>{
        setIndexToRender(-1);
        setDonationIndex(-1);
        setCloneMode(false);
        history.push({
            hash: 'donations',
            search: 'display_one'
        });
    }

    const renderItem = (donation, userIndex, donationIndex)=>{
        const renderKeyValue = ({key, value})=>{
            return <div className={'key_value'}>
                <div className={'key'}> <TextViewer text={key} addChar={':'}/></div>
                <div className={'value'}>{value}</div>
            </div>
        }

        const onCheckClicked = (event)=>{
            const obj = JSON.parse(JSON.stringify(checkedItemsMap));
            if(event.target.checked){
                obj[donationIndex] = true;
            }else{
                delete obj[donationIndex]
            }
            setCheckedItemsMap(obj);
        }


        const onItemClicked = ()=>{
            setIndexToRender(userIndex);
            setDonationIndex(donation.donationIndex);
            setCloneMode(false);
            history.push({
                hash: 'donations',
                search: 'display_one'
            })
        }
        const onCloneClicked = ()=>{
            setIndexToRender(userIndex);
            setDonationIndex(donation.donationIndex);
            setCloneMode(true);
            history.push({
                hash: 'donations',
                search: 'display_one'
            });
        }

        return <div className={isDisplayed(donation) ? '' : 'dont-show'}>
            <div className={'list-item'}>
                <div className={'checkbox'}>
                    <input type="checkbox" onClick={(event)=>onCheckClicked(event)} checked={checkedItemsMap[donationIndex]}/>
                </div>
                <div className={'preview-item'} onClick={onItemClicked}>
                    {renderKeyValue({key: 'name', value: `${donation.user.name} ${donation.user.family}`})}
                    {renderKeyValue({key: 'when', value: utils.calcTimeDiff({stringDate: donation.donationTime, TextViewer})})}
                    {renderKeyValue({key: 'sum', value: donation.donationTotalPayment})}
                </div>
                <div className={'list-item-divider'}></div>
                <button className={'clone-item-button'} onClick={onCloneClicked}><TextViewer text={'clone'}/></button>
            </div>
        </div>
    }

    if(loading) return <Loading/>

    if(window.location.search.includes('display_one')){
        return <DonationsPanel users={users} index={indexToRender} donationIndex={donationIndex} cloneMode={cloneMode} close={()=>{
            setDonationIndex(-1);
            setIndexToRender(-1);
            history.push({
                hash: 'donations',
            })
        }} updateData={updateData}/>
    }

    const isDisplayed = (item)=>{
        if(!search) return true;
        if(item?.user?.name?.includes(search) || item?.user?.family?.includes(search) || `${item?.user?.name} ${item?.user?.family}`.includes(search)) return true;
        return false;
    }

    const sortDonations = ()=>{
        const sortedArray = donations.sort((a,b)=>new Date(b.donationTime) - new Date(a.donationTime));
        return sortedArray;
    }

    const onSelectAllClicked = (event)=>{
        if(event.target.checked){
            const newCheckedItemsMap = {};
            donations.forEach((donation, index)=>{
                newCheckedItemsMap[index] = true;
            })
            setCheckedItemsMap(newCheckedItemsMap);
        }else{
            setCheckedItemsMap({});
        }
    }

    const onDeleteClicked = ()=>{
        let deleteAmount = 0;
        Object.keys(checkedItemsMap).forEach(key=>{
            if(checkedItemsMap[key]) deleteAmount++;
        })
        const text = TextViewer({text: 'are_u_sure_delete_items', addChar: '?', returnString: true});

        if (window.confirm(text.replace('X', deleteAmount)) == true) {
            let updatedItems = JSON.parse(JSON.stringify(users));

            Object.keys(checkedItemsMap).forEach(key=>{
                const {name, family, phoneNumber} = donations[key].user;
                const userToUpdate = updatedItems.find(user=>user.name === name && user.family === family && user.phoneNumber === phoneNumber);
                if(!userToUpdate){
                    console.warn("can't find user to delete:", donations[key].user);
                    return;
                }
                userToUpdate.donations[donations[key].donationIndex] = null;
            })
            updatedItems.forEach(user=>{
                if(Array.isArray(user.donations)) user.donations = user.donations.filter(u=>u);
            })

            updateData({fieldName: 'manager', data: updatedItems});
            setCheckedItemsMap({});
        }

    }


    return <div className={'list-details-container'}>
        <div className={'list-details-container-title'}>
            <TextViewer text={'donations'}/>
            <input value={search} placeholder={' 🔎 חיפוש'} onChange={(event)=>setSearch(event.target.value)}/>
        </div>
        <div className={'list-details-container-actions'}>
            <div className={'select-all-container'}>
                <input type="checkbox" name="scales" onClick={onSelectAllClicked}/>
                <label className={'select-all-label'}><TextViewer text={'choose_all'}/></label>
            </div>
            <button className={'delete-item-button'} onClick={onDeleteClicked} disabled={Object.keys(checkedItemsMap).length === 0}><TextViewer text={'delete'}/></button>
        </div>
        <div className={'add-item-button'} onClick={onAddItemClicked}>
            <img src={add_yellow_circle_logo} alt="icon"/>
        </div>

        <div className={'list-container'}>
            {sortDonations().map((donation, index)=>{
                return renderItem(donation, donation.userIndex, index)
            })}
        </div>
    </div>
}

export default Donations;