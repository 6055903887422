import React from 'react';
import { Progress } from 'antd';

import "./Offline.scss";

const offline = props => (
    <div className={"offline-container"}>
        <div className={'offline-text'}>בעיית רשת, חלק מהנתנונים עלולים להיות לא מדויקים</div>
        <Progress
            strokeColor={{
                '0%': '#521751',
                '100%': 'rgb(249,165,249)',
            }}
            percent={100}
            showInfo={false}
            status={"active"}
        />
    </div>
);

export default offline;