import React, {useEffect, useState} from 'react';
import TextViewer from "../../../helpers/textViewer";
import {Select} from "antd";
import cities from './citiies.json';
import './SignUp.scss'
import SingletoneStoreClass from "../../../Store/Store";
import Loading from "../Main/Loading/Loading";
import Support from '../Support/Support';
import appIcon from "../icons/color_icon.svg";
import animationCheck from "../icons/animations/animation_blue_check.gif";

const store = SingletoneStoreClass.getInstance();

const SignUp = (props)=>{
    const [name, setName] = useState('');
    const [phonenumber, setPhonenumber] = useState('');
    const [cityName, setCityName] = useState('');
    const [location, setLocation] = useState({});
    const [logicalName, setLogicalName] = useState('');
    const [pass, setPass] = useState('');
    const [loading, setLoading] = useState(false);
    const [validationError, setValidationError] = useState({error: false, description: ''});
    const [newSynagogueCreated, setNewSynagogueCreated] = useState(false);
    const [citiesToRender, setCitiesToRender] = useState(cities.slice(0, 50));

    useEffect(() => {
        // console.log("SIGN UP DEBUGGER: ",{name, phonenumber, cityName, location, logicalName, pass});
        validateInputs();
    }, [name, phonenumber, cityName, location, logicalName, pass]);

    useEffect(() => {
        // console.log("newSynagogueCreated: ",newSynagogueCreated);
        if(newSynagogueCreated){
            setTimeout(()=>{
                window.location.href = `${window.location.origin}/${logicalName}`;
            }, 1000 * 3);
        }
    }, [newSynagogueCreated]);

    const onTextInputchange = ({event, setter})=>{
        setter(event.target.value);
    }

    const onChooseCityChange = (cityJson)=>{
        const city = JSON.parse(cityJson);
        setLocation(city.location);
        setCityName(city.name);
    }
    const onPhonenumberChange = (event)=>{
        setPhonenumber(event.target.value)
    }

    const onSearchChanged = (search)=>{
        console.log(setCitiesToRender(cities.filter(c=>c.name.startsWith(search))))
    }

    const validateInputs = ()=>{
        if(!name || !name.trim()){
            setValidationError({error: true, description: 'אנא בחר שם בית כנסת'});
            return;
        }
        if(!logicalName || !logicalName.trim()){
            setValidationError({error: true, description: 'אנא בחר שם בית כנסת באנגלית'});
            return;
        }
        if(logicalName && logicalName.includes(' ')){
            setValidationError({error: true, description: 'שם בית כנסת באנגלית לא יכול להכיל רווחים'});
            return;
        }
        if(logicalName && !logicalName.match(/^[A-Za-z0-9-_]+$/)){
            setValidationError({error: true, description: 'שם בית כנסת חייב להכיל תווים באנגלית בלבד'});
            return;
        }
        if(!location || !cityName){
            setValidationError({error: true, description: 'אנא בחר עיר / יישוב'});
            return;
        }
        if(!phonenumber || !phonenumber.trim()){
            setValidationError({error: true, description: 'אנא הוסף טלפון'});
            return;
        }
        if(phonenumber.length !== 10 || !phonenumber.match(/^[0-9]+$/)){
            setValidationError({error: true, description: 'טלפון לא תקין'});
            return;
        }
        if(!pass || !pass.trim()){
            setValidationError({error: true, description: 'אנא בחר סיסמא'});
            return;
        }

        if(!pass.match(/^(?=.*[0-9])(?=.*[a-zA-Z])(?!.* ).{8,16}$/)){
            setValidationError({error: true, description: 'אנא בחר סיסמא באורך של לפחות 8 תווים, לפחות אות באנגלית וספרה'});
            return;
        }

        setValidationError({error: false, description: ''});
    }

    const onCreateNewSynagogueClicked = async ()=>{

        // function delay(time) {
        //     return new Promise(resolve => setTimeout(resolve, time));
        // }

        setLoading(true);
        try{
            const response = await store.createNewApplication({name, phonenumber, cityName, location, logicalName, pass});
            localStorage.setItem('session_login', btoa(encodeURIComponent(`${phonenumber}[::]${pass}`)));
            localStorage.setItem('FIRST_ON_BOARDING', true);

            // await delay(2000)
            setNewSynagogueCreated(true);
        }catch (e){
            console.error(e)
            console.error(e.data)
            console.error(e?.response?.data?.message)

            if(e?.response?.data?.message.includes('logicalname ALLREADY EXIST')){
                setValidationError({
                    error: true,
                    description: `אופס... השם: ${logicalName} כבר תפוס, אנא בחר שם אחר ונסה שנית`,
                    dontDisableButton: true});
            }else{
                setValidationError({
                    error: true,
                    description: `איראה שגיאה, אנא נסה שנית`,
                    dontDisableButton: true});
            }
        }finally {
            setLoading(false);
        }
    }

    if(loading) return <Loading/>

    if(newSynagogueCreated){
        return <div className={'signup-container'}>
                <div className={'new-synagogue-created'}>
                <TextViewer text={"synagogue_have_created_successfully"} addChar={'!'}/>
                    <div className={'icon_container'}>
                        <img src={animationCheck}/>
                    </div>
                <TextViewer text={"you_are_transferred_to"} className={'transferred_to'} addChar={': '} addedString={`${window.location.origin}/${logicalName}`}/>
            </div>
        </div>
    }


    return <div className={'signup-container'}>
        <div className={'icon_container'}>
            <img src={appIcon}/>
        </div>


        <div className={'welcome-title'}>
            <TextViewer text={"create_new_synagogue"}/>
        </div>
        <div className={'signup-fields-container'}>
            <div className={'signup-field-container'}>
                <div>
                    <TextViewer text={"synagogue_name"} addChar={ ' * '} addBefore/>
                </div>
                <input type={'text'} onChange={(event)=>onTextInputchange({event, setter: setName})} value={name}/>
            </div>
            <div className={'signup-field-container'}>
                <div>
                    <TextViewer text={"synagogue_logical_name"} addChar={ ' * '} addBefore/>
                </div>
                <div className={'logical_name_container'}>
                    <input type={'text'} onChange={(event)=>onTextInputchange({event, setter: setLogicalName})} value={logicalName}/>
                </div>
            </div>
            <div className={'signup-field-container'}>
                <div className={'logical_name_example_container'}>
                    <span>{`https://mysynagogue.net${logicalName ? `/${logicalName}` : ''}`}</span>
                </div>
            </div>
            <div className={'signup-field-container'}>
                <Select dropdownMatchSelectWidth={false}  optionFilterProp={"children"}
                        className={"select-city"}
                        showSearch
                        placeholder={TextViewer({text:'choose_city', returnString: true})}
                        onSearch={onSearchChanged}
                        style={{ width: '100%' }}
                        onChange={onChooseCityChange}>
                    {citiesToRender.map(city =>
                        <Select.Option key={`synagogue-${city.name}`}
                                value={JSON.stringify(city)}>{city.name}</Select.Option>)}
                </Select>
            </div>
            <div className={'signup-field-container'}>
                <div>
                    <TextViewer text={"phonenumber"} addChar={ ' * '} addBefore/>
                </div>
                <input type={'number'} onChange={onPhonenumberChange} value={phonenumber}/>
            </div>
            <div className={'signup-field-container'}>
                <div>
                    <TextViewer text={"password"} addChar={ ' * '} addBefore/>
                </div>
                <input type={'text'} onChange={(event)=>onTextInputchange({event, setter: setPass})} value={pass}/>
            </div>
            {newSynagogueCreated ? <div className={'new-synagogue-created'}>
                    <TextViewer text={"synagogue_have_created_successfully"} addChar={'!'}/>
                    <TextViewer text={"you_are_transferred_to"} className={'transferred_to'} addChar={': '} addedString={`${window.location.origin}/${logicalName}`}/>
            </div> :
                <div className={'create_app'}>
                    {validationError.error ? <div className={'validation_error'}>
                        <div>{validationError.description}</div>
                    </div> : null}
                    <button disabled={validationError.error && !validationError.dontDisableButton} onClick={onCreateNewSynagogueClicked}><TextViewer text={"create_app"} addChar={'!'}/></button>
                </div>}
        </div>
        <Support/>
    </div>
}

export default SignUp;