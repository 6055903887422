import React, {useEffect, useState} from 'react';

import './Welcome.scss';
import TextViewer from "../../../helpers/textViewer";
import SingletoneStoreClass from "../../../Store/Store";
import {Select} from "antd";

import simple_use_logo from "../icons/simple_use.svg";
import user_friendly_logo from "../icons/user_friendly.svg";
import web_app_logo from "../icons/web_app.svg";
import automatic_schedule_logo from "../icons/automatic_schedule.svg";
import union_of_syngogue_logo from "../icons/union-of-syngogue-logo.svg";
import chabad_logo from "../icons/chabad.svg";
import leshem_logo from "../icons/leshem.png";
import tirat_tsvi_logo from "../icons/tirat-tsvi.jpeg";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper";
import {useHistory} from "react-router-dom";


import utils from "../../../helpers/utils";
import config from "../../../config";
import Loading from "../Main/Loading/Loading";
import {analyticsAddLogEvent} from "../../../firebase/firebase";
import SignUp from "../SignUp/SignUp";



const {Option} = Select;


const store = SingletoneStoreClass.getInstance();


const RECOMMENDS = [
    {
        descriptionHeb: 'יש שם כלי מדהים שנותן לגבאי לנהל את העליות. מכניסים את השמות של כל המתפללים עם הטלפונים שלהם, ואחרי שהגבאי משבץ את העליות - העולה מקבל הודעה באופן אוטומטי שיקבל עליה בשבת.',
        descriptionEng: 'There is an amazing tool there, that allows the Gabbai to manage the \'Aliut\'. You enter the names of all payers with their phones, and after the Gabbai set the \'Aliut\' the one who choose to make the \'Alia\' - automatically receives a notification before Shabbat.',
        whoHeb: 'אבי פולק, פאי בני הישיבות, רעננה',
        whoEng: 'Avi Polak, Pai Bnei Hashivas, Ra\'anana',
        date: '9/6/2022 | י״ב סיוון תשפ״ב'
    },
    {
        descriptionHeb: 'האפליקציה תביא לחיזוק הקשר בין חברי הקהילה ותצליח לחבר מתפללים נוספים לבית הכנסת. העובדה שהכל הופך להיות נגיש ופשוט יותר- תאפשר לכל אחד ואחת למצוא את המקום המתאים לו ולהרגיש חלק בלתי נפרד מבית הכנסת.',
        descriptionEng: 'The application will strengthen the relationship between the members of the community and will succeed in connecting more worshipers to the synagogue.',
        whoHeb: 'הרב אלי בן-דהן , יו״ר המועצה הדתית ומנכ״ל בתי הדין הרבניים, ירושלים',
        whoEng: 'Rabbi Eli Ben-Dahan, Chairman of the Religious Council and CEO of the Rabbinical Courts, Jerusalem',
        date: '24/10/2022 | כ״ט חשוון תשפ״ג'
    },
    {
        descriptionHeb: 'מאז שיש לנו את האפליקצייה המתפללים יודעים מה קורה, כבר לא לחוץ לחשב מתי מנחה ובלחיצת כפתור מפיץ לכל הקהילה את האירועים ובקשות לתשלום.',
        descriptionEng: 'Since we have the application, the worshipers know what is going on, they are no longer under pressure to calculate when the moderator is, and with the click of a button distributes the events and requests for payment to the entire community.',
        whoHeb: 'זכריה שבזי, שמחת יחיאל, ראש העין',
        whoEng: 'Zachariah Shabazi, Simchat Yehiel, Rosh Ha\'Ain',
        date: '24/10/2022 | כ״ט חשוון תשפ״ג'
    }
]

const Welcome = (props) => {

    const [loading, setLoading] = useState(true);
    const [chooseSynagogue, setChooseSynagogue] = useState(false);
    const [synagogues, setSynagogues] = useState([]);
    const [plasmaMode, setPlasmaMode] = useState(false);

    const lang = utils.getLanguage();

    const history = useHistory();

    useEffect(() => {
        window.innerWidth >= 768 ? setPlasmaMode(true) : setPlasmaMode(false);
        const fetchData = async ()=>{
            setLoading(true);
            let synagoguesMetaData = await store.getSynagoguesMetaData(false);
            setSynagogues(synagoguesMetaData);
            setLoading(false);
        };
        fetchData();
        analyticsAddLogEvent({eventName: 'welcome_event'});

    }, []);

    const onChange = (logicalName)=>{
        console.log(logicalName);
        setChooseSynagogue(true);
        window.location.href = `${window.location.origin}/${logicalName}`;
    }

    if(chooseSynagogue) return <Loading/>

    if(history.location.hash === '#signup') return <SignUp/>

    return <div className={'welcome-container'}>
        <div className={'welcome-title'}>
            <TextViewer text={"welcome"} addedString={'!'}/>
        </div>
        <div className={'welcome-sub-title'}>
            <TextViewer text={"app_description"}/>
        </div>
        <div className={'welcome-text'}>
            <TextViewer text={"already_registered"} addedString={'?'}/>
        </div>
        <Select dropdownMatchSelectWidth={false} style={{width: 200}} optionFilterProp={"children"}
                className={"select-synagogue"}
                showSearch
                placeholder={<TextViewer text={'choose_synagogue_drop_down'}/>} onChange={onChange}
                loading={loading}>
            {synagogues.map(metaData =>
                <Option key={`synagogue-${metaData.logicalName}`}
                        value={metaData.logicalName}>{metaData.name.length > 40 ? `${metaData.name.slice(0,5)}...` : metaData.name}</Option>)}
        </Select>
        <div className={'welcome-text'}>
            <TextViewer text={"crate_community"} addedString={':'}/>
        </div>
        <div className={'leave-details-button'} onClick={()=>{
            history.push({
                hash: 'signup',
            });
        }}><TextViewer text={"click_here"}/></div>
        <div className={'welcome-text'}>
            <TextViewer text={"getting_details"} addedString={':'}/>
        </div>
        <div className={'leave-details-button'} onClick={()=>{
            utils.sendWhatsAppMessage({msg: '*ברצוני לקבל פרטים על אפליקצייה MySynaoggue* : %0A שם:%0A בית כנסת: %0Aעיר/ישוב: %0A%0A', phoneNumber: config.feedbackPhone});
            analyticsAddLogEvent({eventName: 'welcome_leave_details_event'});
        }}><TextViewer text={"leave_details"}/></div>
        <div className={'welcome-text'}>
            <TextViewer text={"why"} addedString={'?'} addChar={' MySynagogue'}/>
        </div>
        <div className={'welcome-why-mysynagogue'}>
            <div>
                <img src={simple_use_logo} alt="icon"/>
                <TextViewer text={"simple_use"}/>
            </div>
            <div>
                <img src={user_friendly_logo} alt="icon"/>
                <TextViewer text={"user_friendly"}/>
            </div>
            <div>
                <img src={web_app_logo} alt="icon"/>
                <TextViewer text={"web_app"}/>
            </div>
            <div>
                <img src={automatic_schedule_logo} alt="icon"/>
                <TextViewer text={"automatic_schedule"}/>
            </div>
        </div>
        <div className={'recommendation-container'}>
            <div className={'recommendation-title'}>
                <TextViewer text={"gabbais_recommend"}/>
            </div>
            <div className={'recommendation-scroller'}>
                <Swiper
                    speed={3000}
                    centeredSlides={true}
                    loop={true}
                    autoplay={{
                        disableOnInteraction: false, //enable for debugging
                    }}
                    pagination={{
                        clickable: true
                    }}
                    navigation={true}

                    modules={[Autoplay, Pagination, Navigation]}
                >
                    {RECOMMENDS.map(r=> <SwiperSlide data-swiper-autoplay={1000 * 5}>
                       <div className={'recommendation-text-container'}>
                           <div className={'recommendation-text-description'}>{lang === 'heb' ? r.descriptionHeb : r.descriptionEng}</div>
                           <div className={'recommendation-text-who'}>{lang === 'heb' ? r.whoHeb : r.whoEng}</div>
                           <div className={'recommendation-text-date'}>{r.date}</div>
                       </div>
                    </SwiperSlide>)}
                </Swiper>
            </div>
        </div>


        <div className={'our-partners'}>
            <div className={'our-partners-title'}>
                <TextViewer text={"our_partners"}/>
            </div>
            <div className={'our-partners-body'}>
                <img src={union_of_syngogue_logo} alt="icon"/>
                <img src={chabad_logo} alt="icon"/>
                <img src={leshem_logo} alt="icon"/>
                <img src={tirat_tsvi_logo} alt="icon"/>
            </div>
        </div>
    </div>

}

export default Welcome;