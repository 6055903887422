import axios from 'axios'
import config from "../config"
import moment from "moment";
import Hebcal from "hebcal"; //https://github.com/hebcal/hebcal-js
import { getYeshivaTimes } from './YeshivaTimes/yeshivaDB';
import ApiManager from './apiManager';
import occasions from './occasions.json'
import HebrewZmanim from './hebrewZmanim';
const CancelToken = axios.CancelToken;
const GlobalCache = {};

const DAYS = config.days;

const cancelTokens = {
    setSynagogueId: null,
    synagoguesMetaData: null,
    userDate: null,
    getSynagogueFullData: null,
    makeAuthenticate: null,
    changeSynagogueField: null
};

const getDateBy_HHMM = (hhmm) => {
    let d = new Date();
    let hour = hhmm.split(":")[0];
    let min = hhmm.split(":")[1];
    d.setHours(hour);
    d.setMinutes(min);
return d;
}

class SingletoneStoreClass {
    static synagogueId = null;
    static synagogueName = null;
    constructor(){
        this.state = {
            name: null,
            cityName: null,
            location: null,
            tfilotTimes :undefined,
            updatesAndNews: undefined,
            weeklySpecialEvents: null,
            weeklySpecialEventsV2: null,
            unisynData: null,
            dvarTora: null,
            lessonsTimes: null,
            donation: null,
            info: null,
            isAuth: false,
            username: null,
            password: null,
            synagoguesMetaData: null,
            extraDetails: null,
            holidayTimes: null,
            shabatEnter: null,
            shabatExist: null,
            timesForRelativeCalc: {},
        };
        this.apimanager = new ApiManager()
    }

    removeCache(){
        this.state.name = null;
        this.state.cityName = null;
        this.state.location = null;
        this.state.tfilotTimes = null;
        this.state.updatesAndNews = null;
        this.state.weeklySpecialEvents = null;
        this.state.weeklySpecialEventsV2 = null;
        this.state.unisynData = null;
        this.state.dvarTora = null;
        this.state.lessonsTimes = null;
        this.state.donation = null;
        this.state.info = null;
        this.state.synagoguesMetaData = null;
        this.state.extraDetails = null;
        this.state.holidayTimes = null;
        this.state.shabatEnter = null;
        this.state.shabatExist = null;
        this.state.timesForRelativeCalc = {};

        config.cityLocations.forEach(cityLocation => cityLocation.showFirst = false);
        this.apimanager = new ApiManager();
    }

    setAuthFalse(){
        this.state.isAuth = false;
        this.state.username = null;
        this.state.password = null;
    }

    cancelRunningRequest(){
        Object.keys(cancelTokens).map(key=>{
            if(cancelTokens[key]){
                cancelTokens[key]("AXIOS: cancel requests");
            }
            return null;
        });
    }


    async getSynagoguesMetaData(useCache){
        try{
            if(useCache && this.state.synagoguesMetaData){
                console.log("return synagoguesMetaData from memory!");
                return this.state.synagoguesMetaData;
            }

            let response = await axios.get(`${config.sourceUrl}/api/v1/synagogues`, {
                params: {fields: "logicalName,name"}, cancelToken: new CancelToken(function executor(c) {
                    cancelTokens.synagoguesMetaData = c;
                })
            });

            this.state.synagoguesMetaData = response.data;
            return response.data;
        }catch (e) {
            console.error(`error while getting synagoguesMetaData: ${e}`);
            throw e;
        }
    }

    async updateUserData({userId, synagogueId, synagogueLogicName, platform, appVersion, fcmToken}){
        console.log(`store updating user data: userId=${userId}, synagogueId=${synagogueId}, synagogueLogicName=${synagogueLogicName}`);
        try{
            localStorage.setItem("lastUserUpdate", "UPDATING");
            let response = await axios.put(`${config.sourceUrl}/api/v1/users/update`, {
                userId,
                synagogueId,
                synagogueLogicName,
                platform,
                appVersion,
                fcmToken
            }, {
                cancelToken: new CancelToken(function executor(c) {
                    cancelTokens.userDate = c;
                })
            });
            //todo: check why fcmToken not being updated on the DB!!!!
            localStorage.setItem("lastUserUpdate", new Date().getTime().toString());
            return response.data;


        }catch (e) {
            localStorage.removeItem("lastUserUpdate");
            console.error(`error while getting updateUserData: ${e}`);
        }
    }

    static makeRequest = async (type, url, requestName, opts, {username, password} = {}) => {
        const setSynagogueId = async ()=>{
            console.log("---------------- start setSynagogueId-----------------");
            if(!SingletoneStoreClass.synagogueName){
                let msg = `can't make request without synagogue name!`;
                console.error(msg);
                throw new Error(msg);
            }
            let response = await axios.get(`${config.sourceUrl}/api/v1/synagogues/logicalNAme`, {
                params: {logicalNAme: SingletoneStoreClass.synagogueName},
                cancelToken: new CancelToken(function executor(c) {
                    cancelTokens.setSynagogueId = c;
                })
            });
            if(!response.data){
                let msg = `Synagogue Name: ${SingletoneStoreClass.synagogueName} Not Valid`;
                console.error(msg);
                if(window.navigator.onLine){
                    window.localStorage.removeItem("logicalName");
                    window.location.href = window.location.origin;
                }
                // throw new Error(msg);
            }
            SingletoneStoreClass.synagogueId = response.data.id;
            console.log("---------------- finish setSynagogueId-----------------");
        };

        if (!SingletoneStoreClass.synagogueId) {
            await setSynagogueId();
        }

        url = url.replace("{{synagogueId}}",SingletoneStoreClass.synagogueId);

        const axiosParams = {
            cancelToken: new CancelToken(function executor(c) {
                cancelTokens[requestName] = c;
            })
        };
        if(username && password){
            axiosParams.headers = {
                Authorization: `Basic ${btoa(unescape(encodeURIComponent(`${username}:${password}`)))}`
            }
        }
        if (type.toLowerCase() === "get") return axios.get(url, Object.assign({}, opts, axiosParams));
        else return axios[type.toLowerCase()](url, opts, axiosParams);
    };

    setSynagogueName(name){
        SingletoneStoreClass.synagogueName = name;
    }

    getSynagogueName(){
        return SingletoneStoreClass.synagogueName;
    }

    getSynagogueId(){
        return SingletoneStoreClass.synagogueId;
    }

    reset(){
        SingletoneStoreClass.synagogueId = null;
        SingletoneStoreClass.synagogueName = null;
    }


    isAuth(){
        // if(SingletoneStoreClass.synagogueName === 'demo') return true; // for demo env skip on authentication
        return this.state.isAuth;
    }

    getUserName(){
        return this.state.username;
    }

    async getSynagogueFullData(){
        let result = await SingletoneStoreClass.makeRequest("get",`${config.sourceUrl}/api/v1/synagogues/{{synagogueId}}`,
            "getSynagogueFullData", {}, {username: this.state.username, password: this.state.password});
        return result.data;
    }

    async getSynagogueUsersStats(){
        let result = await SingletoneStoreClass.makeRequest("get",`${config.sourceUrl}/api/v1/users/stats/{{synagogueId}}`,
            "getSynagogueUsersStats", {}, {username: this.state.username, password: this.state.password});
        return result.data;
    }

    async makePushNotifications({title, body}){
        let result = await SingletoneStoreClass.makeRequest("post",`${config.sourceUrl}/api/v1/synagogues/push_notification/{{synagogueId}}`,
            "makePushNotifications", { title, body }, {username: this.state.username, password: this.state.password});
        return result.data;
    }


    async getPrayer({userId}){
        let result = await SingletoneStoreClass.makeRequest("get",`${config.sourceUrl}/api/v1/synagogues/prayer/{{synagogueId}}`,
            "getPrayer", { params: {userId}});
        return result.data;
    }

    async updatePrayer(body){
        let result = await SingletoneStoreClass.makeRequest("put",`${config.sourceUrl}/api/v1/synagogues/set_prayer/{{synagogueId}}`,
            "updatePrayer", body);
        return result.data;
    }

    async getGameScores({parasha, type}){
        let result = await SingletoneStoreClass.makeRequest("get",`${config.sourceUrl}/api/v1/game-scores/records`,
            "getGameScores", { params: {parasha, type}});
        return result.data;
    }

    async postGameScore(body){
        let result = await SingletoneStoreClass.makeRequest("post",`${config.sourceUrl}/api/v1/game-scores/create`,
            "postGameScore", body);
        return result.data;
    }

    async getHebrewDates(){
        let result = await SingletoneStoreClass.makeRequest("get",`${config.sourceUrl}/api/v1/synagogues/hebrew_events/{{synagogueId}}`,
            "getHebrewDates");
        return result.data;
    }

    async makeAuthenticate(username, password){
        let response = await SingletoneStoreClass.makeRequest("post",`${config.sourceUrl}/api/v1/auth/{{synagogueId}}`, "makeAuthenticate", {username, password});
        this.state.isAuth = response.data.auth;
        this.state.username = username;
        this.state.password = password;

        return response.data.auth;
    }

    changeSynagogueField(filedName, data){
        this.removeCache();
        return SingletoneStoreClass.makeRequest("put",`${config.sourceUrl}/api/v1/synagogues/{{synagogueId}}`,
            "changeSynagogueField", {[filedName]: data}, {username: this.state.username, password: this.state.password});
    }

    async createNewInvoice({invoice}){
        this.removeCache();
        const response = await SingletoneStoreClass.makeRequest("post",`${config.sourceUrl}/api/v1/invoices/{{synagogueId}}`,
            "createNewInvoice", invoice, {username: this.state.username, password: this.state.password});
        return response.data;
    }

    async getInvoices(){
        this.removeCache();
        const response = await SingletoneStoreClass.makeRequest("get",`${config.sourceUrl}/api/v1/invoices/{{synagogueId}}`,
            "getInvoices", {}, {username: this.state.username, password: this.state.password});
        return response.data;
    }

    static instance = null;

    static createInstance() {
        var object = new SingletoneStoreClass();
        return object;
    }

    static getInstance () {
        if (!SingletoneStoreClass.instance) {
            SingletoneStoreClass.instance = SingletoneStoreClass.createInstance();
        }
        return SingletoneStoreClass.instance;
    }

    static getParshiot() {
        return Hebcal.parshiot.map(p => p[2]).concat('ויקהל-פקודי','תרומה-תצוה','נצבים-וילך','שבת חוה״מ פסח','שבת חוה״מ סוכות')
    }

    static getParshiotMap() {
        return Hebcal.parshiot.reduce((acc, cur, idx) =>{
            acc[cur[2]] = idx;
            return acc;
        }, {})
    }

    getHebrewDate({location, date}){
        try{
            let hebrewDate = new Hebcal.HDate(date || new Date());
            if(location) hebrewDate.setLocation(location.latitude, location.longitude);
            return hebrewDate.toString(config.language === "hebrew" ? "h" : "");
        }catch (e) {
            console.error(`getHebrewDate error: ${e}`)
        }
    }

    toHebrewDate({date, location}){
        try{
            let hebrewDate = new Hebcal.HDate(date);
            if(location) hebrewDate.setLocation(location.latitude, location.longitude);
            return hebrewDate.toString(config.language === "hebrew" ? "h" : "");
        }catch (e) {
            console.error(`getHebrewDate error: ${e}`)
        }
    }

    convertHebrewDate({hebrewMonthName, hebrewMonthDay, location, nextYear=false, nextYearIfPassed=true}){


        const _hebrewMonthDayToNumber = ()=>{
            if(hebrewMonthDay.length === 2 && !hebrewMonthDay.includes('״')){
                hebrewMonthDay = `${hebrewMonthDay[0]}״${hebrewMonthDay[1]}`;
            }
            if(config.hebrewDayToNum[hebrewMonthDay]) return config.hebrewDayToNum[hebrewMonthDay];
            console.info("can't convert hebrew day to number! need to investigate.",hebrewMonthName, hebrewMonthDay);
            return hebrewMonthDay;
        }

        try{
            let hebrewDate
            if(nextYear){
                hebrewDate = new Hebcal.HDate(new Date(new Date().setFullYear(new Date().getFullYear() + 1)));
            }else{
                hebrewDate = new Hebcal.HDate(new Date());
            }
            if(location) hebrewDate.setLocation(location.latitude, location.longitude);
            hebrewDate.setMonth(hebrewMonthName);
            hebrewDate.setDate(_hebrewMonthDayToNumber());
            let date = hebrewDate.greg();
            // if(nextYearIfPassed){
            //     if((new Date() -date) > 24 * 60 * 60 *1000){ // more than a day
            //         //the date already passed:
            //         hebrewDate = new Hebcal.HDate(new Date(new Date().setFullYear(new Date().getFullYear() + 1)));
            //         if(location) hebrewDate.setLocation(location.latitude, location.longitude);
            //         hebrewDate.setMonth(hebrewMonthName);
            //         hebrewDate.setDate(_hebrewMonthDayToNumber());
            //         date = hebrewDate.greg();
            //
            //     }
            // }
            if(nextYearIfPassed){
                if((new Date() -date) > 24 * 60 * 60 *1000){ // more than a day
                    //the date already passed:
                    // console.log("hebrewDate.getFullYear():",hebrewDate.getFullYear())
                    hebrewDate.setFullYear(hebrewDate.getFullYear() + 1)
                    date = hebrewDate.greg();

                }
            }
            return date;
        }catch (e) {
            console.error(`convertHebrewDate [hebrewMonthName: ${hebrewMonthName} hebrewMonthDay: ${hebrewMonthDay}] error: ${e} `)
        }
    }

    getHebrewDateWithoutYear(location){
        try{
            let hebrewDate = new Hebcal.HDate(new Date());
            if(location) hebrewDate.setLocation(location.latitude, location.longitude);
            const monthName = hebrewDate.getMonthName(config.language === "hebrew" ? "h" : "");
            const dayNumHebrew = config.hebrewNumToDay[hebrewDate.getDate()];

           return  dayNumHebrew + " " + monthName;
        }catch (e) {
            console.error(`getHebrewDate error: ${e}`)
        }
    }

    getHebrewDayAndMouthAndYear({date, location}){
        try{
            let hebrewDate = new Hebcal.HDate(date);
            if(location) hebrewDate.setLocation(location.latitude, location.longitude);
            const monthName = hebrewDate.getMonthName();
            const dayOfMonth = hebrewDate.getDate();
            const year = hebrewDate.toString('h').split(" ").pop().replace('ה','');

            return  {monthName, dayOfMonth, year}
        }catch (e) {
            console.error(`getHebrewDate error: ${e}`)
        }
    }

    isRoshChodesh(date, cityLocation){
        let hebrewDate = new Hebcal.HDate(date);
        if(cityLocation) hebrewDate.setLocation(cityLocation.latitude, cityLocation.longitude);
        return (hebrewDate.hallel() === 1 && (hebrewDate.day === 1 || hebrewDate.day === 30 || hebrewDate.day === 29)) ? true : false;
    }

    getDayTimes(date, cityLocation){
        let hebrewDate = new Hebcal.HDate(date);
        if(cityLocation) hebrewDate.setLocation(cityLocation.latitude, cityLocation.longitude);

        let month = new Hebcal.Month(hebrewDate.getMonth(), hebrewDate.getFullYear());
        if(cityLocation) month.setLocation(cityLocation.latitude, cityLocation.longitude);

        let times =  {
            hebrewDate: hebrewDate.toString(config.language === "hebrew" ? "h" : ""),
            dayInWeek: DAYS[hebrewDate.getDay()],
            daysInMonth: hebrewDate.daysInMonth(),
            fullYear: hebrewDate.getFullYear(),
            molad: month.molad(),
            leapYear: month.isLeapYear(),
            parsha: hebrewDate.getParsha(config.language === "hebrew" ? "h" : ""),
            sunrise: hebrewDate.sunrise(),
            sunset: hebrewDate.sunset(),
            hourMins: hebrewDate.hourMins(),
            candleLighting: hebrewDate.candleLighting(),
            havdalah: hebrewDate.havdalah(),
            holidays: hebrewDate.holidays(),
            omer: hebrewDate.omer(),
            dafyomi: hebrewDate.dafyomi(config.language === "hebrew" ? "h" : ""),
            tachanun: hebrewDate.tachanun(),
            hallel: hebrewDate.hallel(),
            roshChodesh: hebrewDate.hallel() === 1 ? true : false
        };

        times =  Object.assign(times, hebrewDate.getZemanim());

        if(this.getSynagogueName() === 'leshem' || this.getSynagogueName() === 'leshem-west'){
            times.tzeit = moment(times.tzeit).add(-19, "m").toDate();
        }

        delete times.shkiah; //already exist sunset
        return times;
    }

    getCurrentDay(location){
        let hebrewDate = new Hebcal.HDate(new Date());
        if(location) hebrewDate.setLocation(location.latitude, location.longitude);
        return hebrewDate.getDay();
    }

    getWeeklySpecialEvents(date, cityLocation){
        if(this.state.weeklySpecialEvents){
            console.log("return weeklySpecialEvents from memory!");
            return this.state.weeklySpecialEvents;
        }
        let events =[];
        let hebrewDate = new Hebcal.HDate(date);
        let dayNum = hebrewDate.getDay();
        for (let i = 0; i < (14 - dayNum); i++) {
            let d = moment(date).add(i, "d").toDate();
            let hebrewDate = new Hebcal.HDate(d);
            if (cityLocation) hebrewDate.setLocation(cityLocation.latitude, cityLocation.longitude);
            if (hebrewDate.holidays().length > 0) {
                hebrewDate.holidays().forEach(holiday => {
                    let event = {
                        desc: holiday.getDesc(config.language === "hebrew" ? "h" : "").replace("(", ")").replace(")", "("),
                        dayNum: (dayNum + i) % 7,
                        hebrewDate: config.hebrewNumToDay[hebrewDate.getDate()] + " " + hebrewDate.getMonthName(config.language === "hebrew" ? "h" : "")
                    };
                    events.push(event)
                })
            }
        }
        this.state.weeklySpecialEvents = events;
        return this.state.weeklySpecialEvents;

    }

    getHebcalDate(date){
        return new Hebcal.HDate(date);
    }

    getEventsPerDate(date, cityLocation, displayImages = false){

        const datesAreOnSameDay = (first, second) => first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();

        let events =[];
        let hebrewDate = new Hebcal.HDate(date);
        //for debug:
        // let hebrewDate = new Hebcal.HDate(new Date("2024-06-5"));
        if (cityLocation) hebrewDate.setLocation(cityLocation.latitude, cityLocation.longitude);

        const monthName = hebrewDate.getMonthName();
        const dayOfMonth = hebrewDate.getDate();
        if(occasions?.[monthName]?.[dayOfMonth]?.length > 0){
            events = events.concat(occasions[monthName][dayOfMonth])

            if(occasions[monthName][dayOfMonth].some(occasion=>occasion.includes('עֽוֹמֶר'))){
                if(datesAreOnSameDay(date, new Date()) && new Date().getHours() > 12){
                // if(datesAreOnSameDay(date, new Date())){
                    let nextDayDate = new Date();
                    nextDayDate.setDate(nextDayDate.getDate() + 1);
                    const nextHebrewDate = new Hebcal.HDate(nextDayDate);
                    const nextMonthName = nextHebrewDate.getMonthName();
                    const nextDayOfMonth = nextHebrewDate.getDate();
                    if(occasions?.[nextMonthName]?.[nextDayOfMonth]?.length > 0){
                        if(occasions[nextMonthName][nextDayOfMonth].some(occasion=>occasion.includes('עֽוֹמֶר'))){
                            let s = 'הערב סופרים: ';
                            s += occasions[nextMonthName][nextDayOfMonth][0];
                            events = events.concat([s]);
                        }
                    }

                }
            }
        }

        if (hebrewDate.holidays().length > 0) {
            hebrewDate.holidays().forEach(holiday => {
                const holidayName = holiday.getDesc(config.language === "hebrew" ? "h" : "").replace("(", ")").replace(")", "(")
                if(!events.some(e=>e.includes(holidayName))) events.push(holidayName);

            })
        }

        return events;
    }

    getWeeklySpecialEventsV2(date, cityLocation, amountOfEvents = 5){
        if(this.state.weeklySpecialEventsV2){
            console.log("return weeklySpecialEventsV2 from memory!");
            return this.state.weeklySpecialEventsV2;
        }
        let events =[];
        let counter = 0;
        while (events.length < amountOfEvents){
            counter++;
            let d = moment(date).add(counter, "d").toDate();
            let hebrewDate = new Hebcal.HDate(d);
            if (cityLocation) hebrewDate.setLocation(cityLocation.latitude, cityLocation.longitude);
            if (hebrewDate.holidays().length > 0) {
                hebrewDate.holidays().forEach(holiday => {
                    let event = {
                        desc: holiday.getDesc(config.language === "hebrew" ? "h" : "").replace("(", ")").replace(")", "("),
                        hebrewDate: config.hebrewNumToDay[hebrewDate.getDate()] + " " + hebrewDate.getMonthName(config.language === "hebrew" ? "h" : "")
                    };
                    events.push(event)
                })
            }
        }


        this.state.weeklySpecialEventsV2 = events;
        return this.state.weeklySpecialEventsV2;

    }

    getShabatEnter(location, round, opt){
        const cacheKey = `getShabatEnter-${location?.latitude}-${location?.longitude}-${round}-${JSON.stringify(opt)}`;

        if(GlobalCache[cacheKey]){
            return GlobalCache[cacheKey];
        }

        const returnWithCache = (result)=>{
            GlobalCache[cacheKey] = result;
            return result;
        }
        const yeshivaMode = opt && opt.useYeshivaTimes;
        const shabatEnterMinBeforeSunset = opt?.shabatEnterMinBeforeSunset || config.defaultShabatEnterMinBeforeSunset;

        let date = opt?.date || new Date();
        if(yeshivaMode && config.yeshivaTimes[yeshivaMode] && config.yeshivaTimes[yeshivaMode].eng){
            const area = config.yeshivaTimes[yeshivaMode].eng;
            console.info(`get shabat enter by Yeshiva times (${area})`);
            const yesivaTimeObj = getYeshivaTimes(date);
            if(yesivaTimeObj[area]){
                const timeString = yesivaTimeObj[area].startTime;
                const result = getDateBy_HHMM(timeString);
                if(round){
                    return returnWithCache(this._roundToNearestBy5Min(moment(round)).toDate());
                }
                return returnWithCache(result);
            }
        }
        if(!opt.useCurrentDate){
            let dayNum = date.getDay();
            if(dayNum>=0 && dayNum <= 4){
                //calc the next friday:
                date = moment(date).add(5-dayNum,"d").toDate();
                console.log(`calc the next friday (adding ${5-dayNum} days) - date: ${date.toLocaleDateString()}`);
            }
        }

        const hebrewZmanim = new HebrewZmanim({date, location});
        let sunset = hebrewZmanim.getSunset();

        let shabatEnter = moment(sunset).add((-1 * shabatEnterMinBeforeSunset) , 'm');


        if(round){
            shabatEnter = this._roundToNearestBy5Min(shabatEnter);
        }

        return returnWithCache(shabatEnter.toDate());
    }

    getShabatExit(location, round, opt){
        const cacheKey = `getShabatExit-${location?.latitude}-${location?.longitude}-${round}-${JSON.stringify(opt)}`;

        if(GlobalCache[cacheKey]){
            return GlobalCache[cacheKey];
        }

        const returnWithCache = (result)=>{
            GlobalCache[cacheKey] = result;
            return result;
        }
        const yeshivaMode = opt && opt.useYeshivaTimes;
        const shabatExitMinAfterStarsCameOut = opt?.shabatExitMinAfterStarsCameOut || config.defaultShabatExitMinAfterStarsCameOut;
        let date = opt?.date || new Date();
        if(yeshivaMode && config.yeshivaTimes[yeshivaMode] && config.yeshivaTimes[yeshivaMode].eng){
            date.setDate(date.getDate() - 1); // use the yesterday day for calculating shabat exit by yeshiva
            const area = config.yeshivaTimes[yeshivaMode].eng;
            const yesivaTimeObj = getYeshivaTimes(date);
            if(yesivaTimeObj[area]){
                const timeString = yesivaTimeObj[area].endTime;
                const result = getDateBy_HHMM(timeString);
                console.info(`get shabat exit by Yeshiva times (${area})`, result ,'for date: ',date);
                if(round){
                    return returnWithCache(this._roundToNearestBy5Min(moment(round)).toDate());
                }
                return returnWithCache(result);
            }
        }
        if(!opt.useCurrentDate){
            let dayNum = date.getDay();
            if(dayNum>=0 && dayNum <= 5){
                date = moment(date).add(6-dayNum,"d").toDate();
                console.log(`calc the next saturday (adding ${6-dayNum} days) - date: ${date.toLocaleDateString()}`);
            }
        }

        const hebrewZmanim = new HebrewZmanim({date, location});
        let starsCameOut = hebrewZmanim.getStarsCameOut();

        let shabatExist =  moment(starsCameOut).add((shabatExitMinAfterStarsCameOut), 'm');

        if(round){
            shabatExist = this._roundToNearestBy5Min(shabatExist);
        }
        return returnWithCache(shabatExist.toDate());
    }

    getTimesForRelativeCalc(location, opt){
        const _getDayOfCurrentWeek = (dayNum)=>{
            //details for this logic, here: https://momentjscom.readthedocs.io/en/latest/moment/02-get-set/06-day/
            return moment().day(dayNum).toDate();
        }

        const { dayNum, dateToCalc } = opt;


        const cacheKey = `${dayNum}-${dateToCalc ? dateToCalc.toJSON() : ''}`;

        if(this.state.timesForRelativeCalc[cacheKey]) return this.state.timesForRelativeCalc[cacheKey]

        const date = dateToCalc || _getDayOfCurrentWeek(dayNum);

        const hebrewZmanim = new HebrewZmanim({date, location});

        const times = {
            sunset: hebrewZmanim.getSunset(),
            sunrise: hebrewZmanim.getSunrise(),
            tzeit: hebrewZmanim.getStarsCameOut(),
            plag_hamincha: hebrewZmanim.getPlagHamincha(),
            alot_hashacher: hebrewZmanim.getAlosHashachar(),
            sof_zman_shma: hebrewZmanim.getSofZmanShma()
        }


        if(!this.state.timesForRelativeCalc[cacheKey]){
            this.state.timesForRelativeCalc[cacheKey] = times;
        }
        return times;
    }

    _roundToNearestBy5Min(momentTime){
        let minutes = momentTime.minute();
        let modulo = minutes%5;
        if (modulo === 0) return momentTime;
        if(modulo>=1 && modulo<=3){
            return momentTime.add(-1 * modulo, "m");
        }else if(modulo === 4){
            return momentTime.add(5 - modulo, "m");
        }
        console.error(`error calculation _roundToNearestBy5Min!!!`);
        return momentTime;
    }

    getParasha(location, date){
        let hebrewDate = new Hebcal.HDate(date || new Date());
        if(location) hebrewDate.setLocation(location.latitude, location.longitude);
        let parasha = hebrewDate.getParsha(config.language === "hebrew" ? "h" : "");
        if(parasha){
            let p = parasha.join('-');
            //fix grammar mistakes:
            if(p.includes("סמחת")) p = p.replace("סמחת","שמחת");
            if(p.includes("סופשנה")) return "חוה״מ סוכות"
            if(p.includes("חול המועד פסח")) return "חוה״מ פסח"
            return p;
        }
        console.error(`can't get parasha: ${parasha}`);
        return null;
    }

    async getSynagoguePublicValues({keys, ignoreCache = false}){
        //todo: call to setSynagogueId only once
        const setSynagogueId = async ()=>{
            console.log("---------------- start setSynagogueId-----------------");
            if(!SingletoneStoreClass.synagogueName){
                let msg = `can't make request without synagogue name!`;
                console.error(msg);
                throw new Error(msg);
            }
            let response = await axios.get(`${config.sourceUrl}/api/v1/synagogues/logicalNAme`, {
                params: {logicalNAme: SingletoneStoreClass.synagogueName},
                cancelToken: new CancelToken(function executor(c) {
                    cancelTokens.setSynagogueId = c;
                })
            });
            if(!response.data){
                let msg = `Synagogue Name: ${SingletoneStoreClass.synagogueName} Not Valid`;
                console.error(msg);
                if(window.navigator.onLine){
                    window.localStorage.removeItem("logicalName");
                    window.location.href = window.location.origin;
                }
                // throw new Error(msg);
            }
            SingletoneStoreClass.synagogueId = response.data.id;
            console.log("---------------- finish setSynagogueId-----------------");
        };

        if (!SingletoneStoreClass.synagogueId) {
            await setSynagogueId();
        }
        return await this.apimanager.getValues({keys, synagogueId: SingletoneStoreClass.synagogueId, ignoreCache} );
    }

    async getUnisynData(){
        if(this.state.unisynData) return this.state.unisynData;
        try{
            const result = await axios.get(config.uniSynGoogleSheetsUrl);
            const parser = new DOMParser();
            const doc = parser.parseFromString(result.data, "text/html")
            const table = doc.querySelector("table");

            const dataObj = {};

            for(let i=1; i< table.rows[1].cells.length;i++){
                const columnsName = table.rows[1].cells[i].textContent;
                const columnsValue = table.rows[2].cells[i].textContent;
                dataObj[columnsName] = columnsValue;
            }
            this.state.unisynData = dataObj;
            return dataObj;
        }catch (e){
            console.error('getUnisynData - got error: ',e);
            return {};
        }

    }

    async createNewApplication({name, phonenumber, cityName, location, logicalName, pass}){
        const response = await axios.post(`${config.sourceUrl}/api/v1/synagogues`, {name, phonenumber, cityName, location, logicalName, pass});
        return response.data;
    }

    async getSefariaDailyEvents({day, month, year, timezone = 'Asia/Jerusalem', custom = 'ashkenazi'}){
        const response = await axios.get(`https://www.sefaria.org/api/calendars`, {params: {day, month, year, timezone, custom}});
        return response.data;
    }

    async getSefariaTexts({code}){
        const response = await axios.get(`https://www.sefaria.org/api/texts/${code}`);
        return response.data;
    }
}

export default SingletoneStoreClass;