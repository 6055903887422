import React, {useEffect, useState} from 'react';
import './Filter.scss';
import TextViewer from "../../../../../helpers/textViewer";
import calendarConfig from '../DateUtils/config';
import Icon from "@ant-design/icons";



const Filter = (props)=>{

    const { filters, setFilters, currentDate, setSelectedDateJSON } = props;


    const onFilterClick = (filterName)=>{
        if(filters.includes(filterName)){
            //already clicked, remove:
            let f = filters.filter(f=>f!==filterName)
            setFilters(f);
        }else{
            //not clicked, add:
            let f = filters.concat([filterName]);
            setFilters(f);
        }
    }

    return <div className={'filters-container'}>
        <div>
            <TextViewer text={"filter"} addChar={':'}/>
        </div>
        {Object.values(calendarConfig.FILTERS).map(filterObj=>{
            const selected = filters.includes(filterObj.name);
            return <div className={`filter-container-times ${selected ? 'filter-selected' : ''}`}
                        onClick={()=>onFilterClick(filterObj.name)}
                        key={`filter-${filterObj.name}`}>
                <Icon component={filterObj.icon} className={'filter-icon'}/>
                <TextViewer text={filterObj.name}/>
            </div>
        })}
        <div className={'move-to-today'} onClick={()=>{
                setSelectedDateJSON(currentDate.toJSON());
                document.getElementById('current-date!').scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'end'});
                setTimeout(()=>{
                    if( document.getElementById('nearest-event-time')) document.getElementById('nearest-event-time').scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'end'});
                },1000)
        }}><TextViewer text={"today"}/>
        </div>
        

    </div>
}

export default Filter;