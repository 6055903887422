import React from 'react';
import BaseComponent from "../../baseComponent/BaseComponent"
import SingletoneStoreClass from "../../../Store/Store";
import "./Edit.scss";
import Auth from "../../Auth/Auth";

import Management from "../Management/Management";
import TextViewer from "../../../helpers/textViewer";
import utils from "../../../helpers/utils";
import {analyticsAddLogEvent} from "../../../firebase/firebase";
import Support from "../Support/Support";

const store = SingletoneStoreClass.getInstance();

class Edit extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentData: {
                name: null,
                updates: null,
                dvarTora: null,
                tfilotTimes: null,
                holidayTimes: null,
                extraDetails: null,
                lessonsTimes: null,
                donation: {url: null, title: null, extra: null},
                info: null,
                users: [],
                logicalName: null, //not editable!
                cityName: null, //not editable!
                location: null //not editable!
            },
            newData: {
                name: null,
                updates: null,
                dvarTora: null,
                tfilotTimes: null,
                holidayTimes: null,
                extraDetails: null,
                lessonsTimes: null,
                donation: {url: null, title: null, paybox: null},
                info: null,
                users: []
            },
            loading: true,
            loadingButtons: {}
        };
        this.rerenderParentCallback = this.rerenderParentCallback.bind(this); //this for force bind the this if it called from outer component
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async fetchData(){
        this.setState({loading: true});
        await this.syncData();
        this.setState({loading: false});
    }

    componentWillUnmount() {
        store.cancelRunningRequest();
        store.removeCache();
    }

    async syncData() {
        let data = await store.getSynagogueFullData();
        this.setState({
            currentData: {
                name: data.name,
                updates: data.updates,
                dvarTora: data.dvarTora,
                logicalName: data.logicalName,
                cityName: data.cityName,
                location: data.location,
                donation: {url: data.donation.url, extra: data.donation.extra, paybox: data.donation.paybox},
                tfilotTimes: data.tfilotTimes,
                holidayTimes: data.holidayTimes,
                extraDetails: data.extraDetails,
                lessonsTimes: data.lessonsTimes,
                info: data.info,
                manager: data.manager,
            },
            newData: {
                name: data.name,
                updates: data.updates,
                dvarTora: data.dvarTora,
                donation: {url: data.donation.url, extra: data.donation.extra, paybox: data.donation.paybox},
                tfilotTimes: data.tfilotTimes,
                holidayTimes: data.holidayTimes,
                extraDetails: data.extraDetails,
                lessonsTimes: data.lessonsTimes,
                info: data.info,
                manager: data.manager
            }
        });
        return data;
    }

    updateSynagogueField(fieldName, data) {
        return store.changeSynagogueField(fieldName, data);
    }

    rerenderParentCallback() {
        this.forceUpdate();
        this.fetchData();
    }

    renderAuth() {
        analyticsAddLogEvent({eventName: 'edit_before_auth_event', params: { name: utils.getSynagogueName()}});
        return (
            <div>
                <h2 onClick={()=>{
                    // for debug only :)
                    navigator.clipboard.writeText(localStorage.getItem("fcmToken"))
                }}><TextViewer text={'gabbai'}/></h2>
                <TextViewer text={'gabai_info_message'}/>
                <Auth rerenderParentCallback={this.rerenderParentCallback}/>
            </div>
        )
    }

    render() {

        return (
            <div className={"edit-mode"}>
                {store.isAuth() ? <Management/>  : this.renderAuth()}
                <Support/>
            </div>
        )
    };
}

export default Edit;

