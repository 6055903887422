import React, {useEffect, useRef, useState} from 'react';
import TextViewer from "../../../../helpers/textViewer";
import SingletoneStoreClass from "../../../../Store/Store";
import Loading from "../Loading/Loading";
import './DailyStudy.scss';
import moment from "moment/moment";
import plus_icon_circle from "../../icons/plus-circle.svg";
import minus_icon_circle from "../../icons/minus-circle.svg";
import utils from "../../../../helpers/utils";
import whatsapp_icon from "../../icons/whatsapp-icon.svg";

const store = SingletoneStoreClass.getInstance();
const CONFIG = {
    EVENTS_TO_DISPLAY: ['Halakhah Yomit', 'Daily Mishnah', 'Daily Rambam', '929', 'Daf Yomi', 'Arukh HaShulchan Yomi', 'Tanya Yomi']
}


const daysToAlpha = {
    0: "sunday",
    1: "monday",
    2: "tuesday",
    3: "wednesday",
    4: "thursday",
    5: "friday",
    6: "saturday",
};

const RenderSingleStudyEvent = (props)=>{

    const { name, chapter, code, title } = props;

    // console.log("RenderSingleStudyEvent=",{ name, chapter, code, title })

    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [textLines,setTextLines ] = useState([]);
    const [textSize,setTextSize ] = useState(12);
    const linesRef = useRef(null);


    const onTitleClicked = async ()=>{
        setOpen(!open);
        if(textLines.length === 0){
            setLoading(true);
            let data = await store.getSefariaTexts({code});
            // console.log("onTitleClicked data: ",data)
            setTextLines(data.he);
            setLoading(false);
        }
    }

    const onShareClicked = ()=>{
        let msg = '';
        msg+= '*';
        msg+= '📢';
        msg+= name;
        msg+= '📣';
        msg+= '*';
        msg+= '%0A';
        msg+= '%0A';
        msg+= title;
        msg+= '%0A';
        msg+= '%0A';
        msg += linesRef.current.innerText || linesRef.current.textContent;



        msg+= '%0A';
        msg+= '%0A';




        msg+= 'לפרטים נוספים, עדכונים, זמני תפילות ושיעורים בקרו באפליקציה שלנו: https://mysynagogue.net';
        msg+= `/${utils.getSynagogueName()}`;


        window.open( `https://api.whatsapp.com/send?text=${msg}`, '_blank');
    }

    return <div className={`single-study-event-container${open ? ' open' : ''}`}>
        <div className={'title'} onClick={onTitleClicked}>
            <div className={'title-text'}><b>{name}</b> - {chapter}</div>
            <div className={'title-icon'}><img src={open ? minus_icon_circle : plus_icon_circle} alt="icon"/></div>
        </div>
        {open ? <div className={'content'} style={{fontSize: `${textSize}px`}} >
            <div className={'increase-decrease-text'}>
                <button onClick={onShareClicked}> <img className={'whats-app-button'} src={whatsapp_icon} alt="icon"/><TextViewer text='share' addedString={' '}/></button>
                <button style={{fontSize: `14px`}} onClick={()=>textSize <30 ? setTextSize(textSize + 2) : null}><TextViewer text='increase_text' addedString={'+'}/></button>
                <button style={{fontSize: `14px`}} onClick={()=> textSize > 8 ? setTextSize(textSize - 2) : null}><TextViewer text='decrease_text' addedString={'-'}/></button>
            </div>

            {loading ?  <div><TextViewer text='loading' addedString={'...'}/></div> : <div ref={linesRef} onClick={()=>setOpen(false)}>{textLines.map(line=><div className={'tfila-wrapper'} dangerouslySetInnerHTML={{ __html: line }} />)}</div>}
        </div> : null}

    </div>
}



const DailyStudy = (props)=>{
    const { date } = props;

    const [loading, setLoading] = useState(true);
    const [dailyEvents, setDailyEvents] = useState([])

    useEffect(() => {

        // console.log("----dailyEvents",dailyEvents)
    }, [dailyEvents])


    useEffect(() => {

        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();


        // console.log('{day, month, year}: ',{day, month, year})
        const run = async ()=>{
            setLoading(true);
            let data = await store.getSefariaDailyEvents({day, month, year});
            // console.log(data);
            setDailyEvents(data.calendar_items.filter(i=>CONFIG.EVENTS_TO_DISPLAY.includes(i?.title?.en)));
            setLoading(false);
        }
        run();
    }, []);

    if(loading) return <Loading/>


    return <div className={'daily-events-container'}>
        <div className={'daily-events-title'}>
            <TextViewer text={daysToAlpha[date.getDay()]} addedString={moment(date).format("DD.MM.YYYY")} addChar={` - ${store.getHebrewDate({date})} - `} />
        </div>
        {CONFIG.EVENTS_TO_DISPLAY.map(eventName=>{
            const dailyEventDescriptionData = dailyEvents.find(d=>d.title.en === eventName);
            if(!dailyEventDescriptionData) return null;

            return <RenderSingleStudyEvent
                title={TextViewer({returnString: true, text: daysToAlpha[date.getDay()], addedString: moment(date).format("DD.MM.YYYY"), addChar: ` - ${store.getHebrewDate({date})} - `})}
                name={dailyEventDescriptionData.title.he}
                chapter={dailyEventDescriptionData.displayValue.he}
                code={dailyEventDescriptionData.url}/>
        })}

    </div>


}

export default DailyStudy;