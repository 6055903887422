import React, {useEffect, useState} from 'react';
import SingletoneStoreClass from "../../../../../../Store/Store";
import config from "../../../../../../config";
import './hebrewEventsMemorial.scss';

const store = SingletoneStoreClass.getInstance();


const MemoryBoard = (props)=>{

    const { location, types = ['יארצייט','יורצייט'], prayersHebrewDatesEvents} = props;

    const getModeName =  ()=> {
        if(types.includes('יארצייט')){
            return 'memorial-mode';
        }
        return 'happy-mode'
    }

    const getGif = ()=>{

        if(getModeName() === 'memorial-mode') return 'https://images.squarespace-cdn.com/content/v1/60d49456e7b22f7adf0387f2/1632327661711-8M2ZA9LZN6F8LWGRFONB/animated-candle.gif';


        const gifUrls = [
            'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEg6FzbInHJ8esvwKr9RceU_TrThQqqPrXrI8J-Xo-1pFmWUK75ApIw965oBvnvlhL1DPmjnhzWjG1yqN_8UA-a-iGCEz4cFxEvpIrgn2dshW6F4JAFpLLueojQblkm7T35o7BW6TSsnBuM/s1600/normal_5c875ce053a0e.gif',
            'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhferHopLkSlQcAeHA4SUIslLYcFh2aOcZJU-jmGhv2p1Y1QAqcBi6wtOq1lozONpJSzaj5NYW4V2F-GeK6OCS2tNQDa1cwnPX8y7coS5ubH-mho2YBdAliuNAmwnkLG6juHv9qDmUig1c/s1600/normal_5c875cecb20fd.gif',
            'https://3.bp.blogspot.com/-kIQBb5PoLPE/XE2FbY8mizI/AAAAAAAAdh8/CUXPuDjM-FU9cjfBsPR_61Ryd3_ZqdFBgCLcBGAs/s1600/4473-20120820101107982.gif',
            'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjinP0ZNTUe2G1yubxkoWnc7e_XM1ozqJpkCVaOn9aBy-wlUWiTL2vR13_YHhe7eS2I2l8RK0sHCMI5EN7h92fG3CPvpSLir1dJQPTNiaP1pRFOiWt5ivtnTqCp1M5awIDVPnjm2aWlVpI/s1600/%25D7%2599%25D7%2595%25D7%259E%25D7%2595%25D7%259C%25D7%2593%25D7%25AA+%25D7%25A9%25D7%259E%25D7%25976.gif',
            'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEi5O1P-u8y5eHVukR2r9GGdWhja46J7cLAOBrV-AadLUoYyHJxICE-0Jpr5CxVVJvDCYFh4Nd93kw3n_pAbvU_3Zkdx_CD0XUIYc3tCwfCSh_tY_zQ7N9gm7nkSqfBKzbJvKObQ109FKQI/s1600/normal_5c875d0c8fe84.gif',
            'https://narkis2016.files.wordpress.com/2016/11/8fe_b3a_3d2_33f_fcb_happy_birthday28.gif',
        ]
        return gifUrls[Math.floor(Math.random() * gifUrls.length)];
    }




    return <div className={`hebrew-board-events-container ${getModeName()}`}>

        <div className={'hebrew-board-events-items'}>
            {prayersHebrewDatesEvents.map(hebrewDateEvent=><div>
                <div className={'hebrew-board-event-container'}>
                    <div className={'hebrew-board-event-memory-candle'}>
                        <img src={getGif()} alt="loading..." />
                    </div>
                    <div className={'hebrew-board-event-name'}>
                        {hebrewDateEvent.name} {hebrewDateEvent.family || null}
                    </div>
                    <div className={'hebrew-board-event-hebrewDay'}>
                        {hebrewDateEvent.hebrewDay}
                    </div>
                    <div className={'hebrew-board-event-hebrewMonth'}>
                        {hebrewDateEvent.hebrewMonth}
                    </div>
                </div>
            </div>)}
        </div>

    </div>
}

export default MemoryBoard;