import SingletoneStoreClass from "../../../../Store/Store";


const store = SingletoneStoreClass.getInstance();

const getEndOfTfila = ({location})=>{
    const currentDate = new Date();


    const dayTimes = store.getDayTimes(currentDate, location);

    let {sof_zman_tfilla, sunset, alot_hashachar, omer} = dayTimes;

    const prays = [
        {date: alot_hashachar, diffMs: null, totalDiff: null, hebrewName: 'ערבית'},
        {date: sof_zman_tfilla, diffMs: null, totalDiff: null, hebrewName: 'שחרית'},
        {date: sunset, diffMs: null, totalDiff: null, hebrewName: 'מנחה'}];

    const DAY_MS = 24 * 60 * 60 * 1000;

    let nextEndTfila;
    for (let tfila of prays) {
        if (currentDate < tfila.date) {
            nextEndTfila = tfila;
            nextEndTfila.diffMs = tfila.date - currentDate;
            switch (tfila.hebrewName) {
                case 'ערבית':
                    nextEndTfila.totalDiff = DAY_MS - (prays[2].date - prays[0].date);
                    break;
                case 'שחרית':
                    nextEndTfila.totalDiff = prays[1].date - prays[0].date;
                    break;
                case 'מנחה':
                    nextEndTfila.totalDiff = prays[2].date - prays[1].date;
                    break;
            }
            break;
        }
    }
    if (!nextEndTfila) { // it means that we passed the latest pray on the day (after sunset) - the next time is alot_hashachar
        nextEndTfila = prays[0];
        nextEndTfila.diffMs = DAY_MS - (currentDate - prays[0].date);
        nextEndTfila.totalDiff = DAY_MS - (prays[2].date - prays[0].date);
    }

    return nextEndTfila;
}

export default getEndOfTfila;

