const INVOICE_CONFIG = {
    synagogueName: {
        keyName: 'synagogueName',
        text_view_name: 'synagogue_name',
        type: 'text',
        editable: false,
        propName: 'synagogueName',
    },
    donorFullName: {
        keyName: 'donorFullName',
        text_view_name: 'donor_full_name',
        type: 'select_user',
        required: true,
        editable: true,
        dontSave: true
    },
    amount: {
        keyName: 'amount',
        text_view_name: 'amount',
        type: 'number',
        required: true,
        editable: true,
        validator: (data)=> data < 20000,
        dontSave: true,
        warnMsg: 'max_amount_20k'
    },
    paymentType: {
        keyName: 'paymentType',
        text_view_name: 'payment_type',
        required: true,
        type: 'select',
        defaultMsg: 'בחר סוג תשלום',
        options: ["מזומן","העברה בנקאית","ביט","שיק בנקאי","כרטיס אשראי","פייבוקס"],
        editable: true,
        dontSave: true
    },
    donorEmail: {
        keyName: 'donorEmail',
        text_view_name: 'donor_email',
        type: 'email',
        required: false,
        editable: true,
        dontSave: true
    },
    description: {
        keyName: 'description',
        text_view_name: 'description',
        type: 'text_area',
        required: false,
        editable: true,
        dontSave: true
    },
    createAt: {
        keyName: 'createAt',
        text_view_name: 'create_at',
        type: 'text',
        editable: false,
        propName: 'createAt',
    },
    synagogueAddress: {
        keyName: 'synagogueAddress',
        text_view_name: 'synagogue_address',
        type: 'input',
        required: true,
        editable: true,
    },
    synagoguePhone: {
        keyName: 'synagoguePhone',
        text_view_name: 'synagogue_phone',
        type: 'tel',
        required: true,
        editable: true,
        validator: (data)=> !isNaN(data) && data.length <= 10
    },
    synagogueEmail: {
        keyName: 'synagogueEmail',
        text_view_name: 'synagogue_email',
        type: 'email',
        required: true,
        editable: true,
    },
    type: {
        keyName: 'type',
        text_view_name: 'association_type',
        required: true,
        type: 'select',
        defaultMsg: 'בחר סוג (ח״פ, ע״מ או עמותה)',
        options: ["עמותה", "חברה פרטית (ח״פ)", "עוסק מורשה (ע״מ)"],
        editable: true,
    },
    associationName: {
        keyName: 'associationName',
        text_view_name: 'association_name',
        textReviewReplace: {
            first: 'העמותה',
            by: 'type'
        },
        type: 'input',
        required: true,
        editable: true,
    },
    associationNumber: {
        keyName: 'associationNumber',
        text_view_name: 'association_number',
        textReviewReplace: {
            first: 'עמותה רשומה',
            by: 'type'
        },
        type: 'number',
        required: true,
        editable: true,
    },
    chapter46: {
        keyName: 'chapter46',
        text_view_name: 'has_chapter_46',
        type: 'checkbox',
        required: false,
        editable: true,
    },
    signature: {
        keyName: 'signature',
        text_view_name: 'digital_signature',
        type: 'signature',
        required: true,
        editable: true,
    }
}

export default INVOICE_CONFIG;