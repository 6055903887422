import React, {useState, useEffect} from 'react';
import './ReportsV2.scss';
import Loading from "../../Main/Loading/Loading";
import TextViewer from "../../../../helpers/textViewer";
import {useHistory} from "react-router-dom";
import CreateReport from "../../PDFGenerator/CreateReport";
import {Select} from "antd";
import config from "../../../../config";
import SingletoneStoreClass from "../../../../Store/Store";
import PDFGenerator from "../../PDFGenerator/PDFGenerator";
import PRAYER_CONFIGS from '../PrayersDetails/config/prayerConfig';
import utils from "../../../../helpers/utils";
import prayerConfig from "../PrayersDetails/config/prayerConfig";
import moment from "moment/moment";
import tfilotParser from "../../../../helpers/TfilotOfTheDay/tfilotParser";


const CONFIG = {
    types: {
        prayersReports: {
            TextViewer: 'prayers_reports'
        },
        specialDatesReports: {
            TextViewer: 'special_dates_reports'
        },
        aliotReport: {
            TextViewer: 'aliot_report'
        },
        timesOfTheDayReports: {
            TextViewer: 'times_of_the_day_reports'
        },
        pdfMode: {
            TextViewer: 'summery-PDF'
        },
        tfilotAndLessons: {
            TextViewer: 'tfilot_and_lessons'
        }
    }

}


const store = SingletoneStoreClass.getInstance();
const { Option } = Select;


const ReportsV2 = (props)=>{
    const { loading , users = [], updateData, synagogueName, base64Logo, location, extraDetails, times } = props;
    const [rendering, setRendering] = useState(false);
    const [path, setPath] = useState('');


    // console.log("users: ",users)

    const history = useHistory();


    useEffect(() => {


        const unlisten = history.listen( location =>  {
            if(location.hash.startsWith('#')) setPath(location.hash.split('#')[1])
            else setPath(location.pathname.split('/').pop())
        });

        if(window.location.href.includes('#') && window.location.href.split('#')[2]){
            setPath(window.location.href.split('#')[2]);
        }

        return unlisten;
    }, []);


    // useEffect(() => {
    //   console.log("----path=",path);
    // }, [path]);


    const fakeDelay = async ()=>{
        setRendering(true);
        await new Promise(resolve => setTimeout(resolve, 1000))
        setRendering(false);
    }


    const PdfMode = (props)=>{

        let currentParasha = store.getParasha(location, new Date());

        const currentDate = new Date();

        const [customizeGeneralReport, setCustomizeGeneralReport] = useState(null);
        const [reportCustomName, setReportCustomName] = useState('');
        const [startDate, setStartDate] = useState(new Date());
        const [endDate, setEndDate] = useState(new Date(currentDate.setDate(currentDate.getDate() + 30)));


        return <div className={'pdf-mode-container'}>
            <TextViewer text={'times_community_report'} addedString={currentParasha} addChar={' - פרשת'} addChar={':'}/>

            <button className={'list-details-actions-export-pdf'} onClick={fakeDelay}>
                <CreateReport textTitle={TextViewer({text: 'create_dedicate_report'})}/>
            </button>
            <hr className={'divider-report'} />
            <TextViewer text={'times_report_per_special_date'} addChar={': '}/>

            <Select className={'statics-select'} dropdownMatchSelectWidth={false} showSearch allowClear
                    placeholder={TextViewer({text: 'בחר מועד', returnString: true})}
                    onChange={(e)=>{
                        if(e) setCustomizeGeneralReport(JSON.parse(e))
                        else setCustomizeGeneralReport(null)
                    }}
            >
                {config.importantDates.map(optionObj=><Option key={JSON.stringify(optionObj)} value={JSON.stringify(optionObj)}>{`${optionObj.name}${typeof optionObj.hebrewDayAtMonth === 'number' ? ` - ${config.hebrewNumToDay[optionObj.hebrewDayAtMonth]} ${config.englishMonthToHebrew[optionObj.hebrewMonth]}` : ''}`}</Option>)}
            </Select>
            {customizeGeneralReport ? <button className={'list-details-actions-export-pdf'} disabled={!customizeGeneralReport} onClick={fakeDelay}>
                <CreateReport isfast={customizeGeneralReport?.fast} reportName={customizeGeneralReport ? customizeGeneralReport.name : null} textTitle={TextViewer({text: 'create_dedicate_report'})} fromDate={customizeGeneralReport ? store.convertHebrewDate({hebrewMonthName: customizeGeneralReport?.hebrewMonth, hebrewMonthDay: customizeGeneralReport?.hebrewDayAtMonth, location}) : null}/>
            </button> : null}
            <hr className={'divider-report'} />

            <TextViewer text={'times_report_per_custom_date'} addChar={': '}/>
            <input type={'text'} onChange={(event)=>setReportCustomName(event.target.value)} placeholder={'בחר שם לדו״ח'}
                   value={reportCustomName}/>
            <div>
                <div className={'from-date-container'}>
                    <TextViewer text={'from_date'} addChar={':'}/>
                    <input type={'date'} onChange={(event)=>setStartDate(new Date(event.target.value))} placeholder={'start'}
                           value={startDate.toISOString().substr(0, 10)}/>
                </div>
                <div className={'from-date-container'}>
                    <TextViewer text={'to_date'} addChar={':'}/>
                    <input type={'date'} onChange={(event)=>setEndDate(new Date(event.target.value))}
                           value={endDate.toISOString().substr(0, 10)}/>
                </div>
            </div>

            <button className={'list-details-actions-export-pdf report-body-container'} onClick={fakeDelay}>
                <CreateReport reportName={reportCustomName} textTitle={TextViewer({text: 'create_dedicate_report'})} fromDate={startDate} untilDate={endDate}/>

            </button>

        </div>
    }

    const PrayersReports = (props)=>{

        const [exportType, setExportType] = useState('pdf');
        const [lastPaymentYearFilter, setLastPaymentYearFilter] = useState(PRAYER_CONFIGS.lastPaymentYear.options[0]);
        const [lastDidntPaymentYearFilter, setLastDidntPaymentYearFilter] = useState(PRAYER_CONFIGS.lastPaymentYear.options[0]);


        // useEffect(() => {
        //   console.log("----lastPaymentYearFilter=",lastPaymentYearFilter);
        // }, [lastPaymentYearFilter]);

        return <div>
            <div className={'choose-export-type'}>
                <TextViewer text={'choose_export_type'} addChar={':'}/>
                <div className={'empty-divider'}></div>
                <select onChange={(event)=>setExportType(event.target.value)} value={exportType}>
                    <option value={'pdf'}>pdf</option>
                    <option value={'csv'}>csv</option>
                </select>
            </div>
            <hr className={'divider-report'} />
            <div>
                <TextViewer text={'all_prayers'} addChar={':'}/>
                <button className={'list-details-actions-export-pdf'} onClick={async ()=>{
                    const pdfGenerator = new PDFGenerator({
                        base64Logo,
                        synagogueName,
                        title: TextViewer({text: 'prayers_details_short', returnString: true}),
                        type: 'prayers',
                        items: users,
                        exportType
                    });
                    await pdfGenerator.init();
                    pdfGenerator.makePDF();
                }}><TextViewer text={'create_dedicate_report'}/></button>
            </div>


            <hr className={'divider-report'} />
            <TextViewer text={'all_members_prayers'} addChar={':'}/>
            <button className={'list-details-actions-export-pdf'} onClick={async ()=>{
                const pdfGenerator = new PDFGenerator({
                    base64Logo,
                    synagogueName,
                    title: TextViewer({text: 'prayers_details_short', returnString: true}),
                    type: 'prayers',
                    items: users.filter(user=>user.isMember),
                    exportType
                });
                await pdfGenerator.init();
                pdfGenerator.makePDF();
            }}><TextViewer text={'create_dedicate_report'}/></button>

            <hr className={'divider-report'} />

            <TextViewer text={'prayers_renew_membership_to_year'} addChar={': '}/>
            <select onChange={(event)=>setLastPaymentYearFilter(event.target.value)} value={lastPaymentYearFilter}>
                {PRAYER_CONFIGS.lastPaymentYear.options.map(yearName=>(
                    <option
                        value={yearName}>{yearName}</option>
                ))}
            </select>
            <button className={'list-details-actions-export-pdf'} onClick={async ()=>{
                const pdfGenerator = new PDFGenerator({
                    base64Logo,
                    synagogueName,
                    title: TextViewer({text: 'prayers_details_short', returnString: true}),
                    type: 'prayers',
                    items: users.filter(user=>user.isMember && (user.lastPaymentYear ? user.lastPaymentYear === lastPaymentYearFilter : lastPaymentYearFilter === PRAYER_CONFIGS.lastPaymentYear.options[0])),
                    exportType
                });
                await pdfGenerator.init();
                pdfGenerator.makePDF();
            }}><TextViewer text={'create_dedicate_report'}/></button>


            <hr className={'divider-report'} />
            <TextViewer text={'prayers_didnt_renew_membership_to_year'} addChar={': '}/>
            <select onChange={(event)=>setLastDidntPaymentYearFilter(event.target.value)} value={lastDidntPaymentYearFilter}>
                {PRAYER_CONFIGS.lastPaymentYear.options.map(yearName=>(
                    <option
                        value={yearName}>{yearName}</option>
                ))}
            </select>
            <button className={'list-details-actions-export-pdf'} onClick={async ()=>{
                const pdfGenerator = new PDFGenerator({
                    base64Logo,
                    synagogueName,
                    title: TextViewer({text: 'prayers_details_short', returnString: true}),
                    type: 'prayers',
                    items: users.filter(user=>user.isMember && (user.lastPaymentYear ? user.lastPaymentYear !== lastPaymentYearFilter : lastPaymentYearFilter !== PRAYER_CONFIGS.lastPaymentYear.options[0])),
                    exportType
                });
                await pdfGenerator.init();
                pdfGenerator.makePDF();
            }}><TextViewer text={'create_dedicate_report'}/></button>

            <hr className={'divider-report'} />



        </div>
    }

    const SpecialDatesReports = (props)=>{

        const currentDate = new Date();


        const [exportType, setExportType] = useState('pdf');
        const [importantDatesFilter, setImportantDatesFilter] = useState('all');
        const [filterByTime, setFilterByTime] = useState(false);
        const [startDate, setStartDate] = useState(new Date());
        const [endDate, setEndDate] = useState(new Date(currentDate.setDate(currentDate.getDate() + 30)));

        // useEffect(() => {
        //     console.log("----startDate, endDate=",startDate, endDate);
        // }, [startDate, endDate]);

        return <div className={'report-body-container'}>
            <div className={'choose-export-type'}>
                <TextViewer text={'choose_export_type'} addChar={':'}/>
                <div className={'empty-divider'}></div>
                <select onChange={(event)=>setExportType(event.target.value)} value={exportType}>
                    <option value={'pdf'}>pdf</option>
                    <option value={'csv'}>csv</option>
                </select>
            </div>
            <hr className={'divider-report'} />


            <div>
                <div className={'empty-divider'}></div>
                <select value={importantDatesFilter} onChange={(event)=>{setImportantDatesFilter(event.target.value)}}>
                    {[<option value={'all'}>{TextViewer({text: 'all', returnString: true})}</option>].concat(prayerConfig.importantDates.objectStructure.reason.type.map(option=><option value={option}>{option}</option>))}
                </select>
            </div>

            <div onClick={()=>setFilterByTime(!filterByTime)}>
                <span> <TextViewer text={'filter_by_time'} addChar={':'}/></span>
                <input type={'checkbox'} checked={filterByTime}/>
            </div>

            {filterByTime ? <div>
                <div>
                    <TextViewer text={'from_date'} addChar={':'}/>
                    <input type={'date'} onChange={(event)=>setStartDate(new Date(event.target.value))} placeholder={'start'}
                           value={startDate.toISOString().substr(0, 10)}/>
                </div>
                <div>
                    <TextViewer text={'to_date'} addChar={':'}/>
                    <input type={'date'} onChange={(event)=>setEndDate(new Date(event.target.value))}
                           value={endDate.toISOString().substr(0, 10)}/>
                </div>
            </div>: null }

            <button className={'list-details-actions-export-pdf'} onClick={async ()=>{
                const pdfGenerator = new PDFGenerator({
                    base64Logo,
                    synagogueName,
                    title: TextViewer({text: 'important_dates', returnString: true, addedString: importantDatesFilter === 'all' ? '' : ` -${importantDatesFilter}`}),
                    type: 'importantDates',
                    items: utils.getImportantDatesByUsers(users, importantDatesFilter === 'all' ? false : importantDatesFilter, location).filter(u=> filterByTime ? (endDate >  u.date && startDate < u.date) : true),
                    exportType
                });
                await pdfGenerator.init();
                pdfGenerator.makePDF();
            }}><TextViewer text={'create_dedicate_report'}/></button>
        </div>
    }

    const AliotReport = (props)=>{

        const currentDate = new Date();

        let currentParasha = store.getParasha(location, new Date());



        const [exportType, setExportType] = useState('pdf');
        const [importantDatesFilter, setImportantDatesFilter] = useState('all');
        const [filterByTime, setFilterByTime] = useState(false);
        const [startDate, setStartDate] = useState(new Date());
        const [endDate, setEndDate] = useState(new Date(currentDate.setDate(currentDate.getDate() + 30)));

        return <div className={'report-body-container'}>
            <div className={'choose-export-type'}>
                <TextViewer text={'choose_export_type'} addChar={':'}/>
                <div className={'empty-divider'}></div>
                <select onChange={(event)=>setExportType(event.target.value)} value={exportType}>
                    <option value={'pdf'}>pdf</option>
                    <option value={'csv'}>csv</option>
                </select>
            </div>
            <hr className={'divider-report'} />
            <TextViewer text={'generate_aliyot_weekly_report' } addedString={currentParasha} addChar={' פרשת'} />
            <button className={'list-details-actions-export-pdf'} onClick={async ()=>{
                const nextSunday = moment().startOf('week').add(7, 'day');
                const weeklyReportUsers = utils.calcWeeklyImportantUsers({users, currentParasha, startDate: nextSunday.toDate(), endDate: nextSunday.add(6, 'day').toDate(), TextViewer});

                const pdfGenerator = new PDFGenerator({
                    base64Logo,
                    synagogueName,
                    title: TextViewer({text: 'generate_aliyot_weekly_report', addedString: currentParasha, addChar: ' פרשת', returnString: true}),
                    type: 'weeklyReportUsers',
                    items: weeklyReportUsers,
                    exportType
                });
                await pdfGenerator.init();
                pdfGenerator.makePDF();
            }}>
                <TextViewer text={'create_dedicate_report'}/>
            </button>
            <hr className={'divider-report'} />

            <div onClick={()=>setFilterByTime(!filterByTime)}>
                <span> <TextViewer text={'filter_by_time'} addChar={':'}/></span>
                <input type={'checkbox'} checked={filterByTime}/>
            </div>

            {filterByTime ? <div>
                <div>
                    <TextViewer text={'from_date'} addChar={':'}/>
                    <input type={'date'} onChange={(event)=>setStartDate(new Date(event.target.value))} placeholder={'start'}
                           value={startDate.toISOString().substr(0, 10)}/>
                </div>
                <div>
                    <TextViewer text={'to_date'} addChar={':'}/>
                    <input type={'date'} onChange={(event)=>setEndDate(new Date(event.target.value))}
                           value={endDate.toISOString().substr(0, 10)}/>
                </div>
            </div>: null }
            <div>
                <TextViewer text={'aliot_genreal_report'} addChar={': '}/>
                <button className={'list-details-actions-export-pdf'} onClick={async ()=>{
                    const aliyot = [];
                    users.forEach((user, userIndex)=>{
                        if(user.aliyot){
                            user.aliyot.forEach((aliya, index)=>{
                                aliyot.push({...aliya, user, aliyahIndex: index, userIndex });
                            })
                        }
                    })

                    const pdfGenerator = new PDFGenerator({
                        base64Logo,
                        synagogueName,
                        title: TextViewer({text: 'aliyah_panel', returnString: true}),
                        type: 'aliyot',
                        items: aliyot.sort((a,b)=>new Date(b.aliyahTime) - new Date(a.aliyahTime)).filter(u=> filterByTime ? (endDate >  u.date && startDate < u.date) : true),
                        exportType
                    });
                    await pdfGenerator.init();
                    pdfGenerator.makePDF();
                }}><TextViewer text={'create_dedicate_report'}/></button>
            </div>
            <hr className={'divider-report'} />

        </div>
    }

    const TimesOfTheDayReports = (props)=>{

        const currentDate = new Date();

        const [exportType, setExportType] = useState('pdf');
        const [startDate, setStartDate] = useState(new Date());
        const [endDate, setEndDate] = useState(new Date(currentDate.setDate(currentDate.getDate() + 30)));

        return <div className={'report-body-container'}>
            <div className={'choose-export-type'}>
                <TextViewer text={'choose_export_type'} addChar={':'}/>
                <div className={'empty-divider'}></div>
                <select onChange={(event)=>setExportType(event.target.value)} value={exportType}>
                    <option value={'pdf'}>pdf</option>
                    <option value={'csv'}>csv</option>
                </select>
            </div>
            <hr className={'divider-report'} />

            <div>
                <TextViewer text={'from_date'} addChar={':'}/>
                <input type={'date'} onChange={(event)=>setStartDate(new Date(event.target.value))} placeholder={'start'}
                       value={startDate.toISOString().substr(0, 10)}/>
            </div>
            <div>
                <TextViewer text={'to_date'} addChar={':'}/>
                <input type={'date'} onChange={(event)=>setEndDate(new Date(event.target.value))}
                       value={endDate.toISOString().substr(0, 10)}/>
            </div>

            <button className={'list-details-actions-export-pdf'} onClick={async ()=>{

                const calcDates = ()=>{
                    const arrayOfDates = [];
                    return new Promise((resolve=>{
                        setTimeout(() => {
                            const dateToIterate = new Date(startDate.getTime())
                            while (dateToIterate < endDate){
                                arrayOfDates.push(new Date(dateToIterate.getTime()));
                                dateToIterate.setDate(dateToIterate.getDate() + 1);
                            }
                            const items = [];
                            arrayOfDates.forEach(date=>{
                                const timesOfDay = tfilotParser.calcTimesOfDayV2({
                                    date,
                                    location
                                });
                                items.push({...timesOfDay.reduce((acc, currentValue)=>{
                                        acc[currentValue.name] = currentValue.date;
                                        return acc;
                                    }, {}), date});
                            });
                            resolve(items);
                        }, 0);
                    }))
                }

                if(startDate > endDate){
                    console.warn("can't calculate startDate > endDate");
                    return;
                }
                setRendering(true);
                await new Promise(resolve => setTimeout(resolve, 100))
                const items = await calcDates();
                setRendering(false);

                const pdfGenerator = new PDFGenerator({
                    base64Logo,
                    synagogueName,
                    title: TextViewer({text: 'times_of_the_day', addChar: ': ',returnString: true, addedString: synagogueName}),
                    type: 'timesOfTheDay',
                    items,
                    exportType
                });
                await pdfGenerator.init();
                pdfGenerator.makePDF();
            }}><TextViewer text={'create_dedicate_report'}/></button>

        </div>
    }


    const TfilotAndLessonsReports = (props)=>{

        const { times, extraDetails } = props;

        const currentDate = new Date();

        const [exportType, setExportType] = useState('pdf');
        const [startDate, setStartDate] = useState(new Date());
        const [endDate, setEndDate] = useState(new Date(currentDate.setDate(currentDate.getDate() + 30)));

        return <div className={'report-body-container'}>
            <div className={'choose-export-type'}>
                <TextViewer text={'choose_export_type'} addChar={':'}/>
                <div className={'empty-divider'}></div>
                <select onChange={(event)=>setExportType(event.target.value)} value={exportType}>
                    <option value={'pdf'}>pdf</option>
                    <option value={'csv'}>csv</option>
                </select>
            </div>
            <hr className={'divider-report'} />
            <div className={'from-date-container'}>
                <TextViewer text={'from_date'} addChar={':'}/>
                <input type={'date'} onChange={(event)=>setStartDate(new Date(event.target.value))} placeholder={'start'}
                       value={startDate.toISOString().substr(0, 10)}/>
            </div>
            <div className={'from-date-container'}>
                <TextViewer text={'to_date'} addChar={':'}/>
                <input type={'date'} onChange={(event)=>setEndDate(new Date(event.target.value))}
                       value={endDate.toISOString().substr(0, 10)}/>
            </div>

            <button className={'list-details-actions-export-pdf'} onClick={async ()=>{
                const calcDates = ()=>{

                    const { useYeshivaTimes, shabatExitMinAfterStarsCameOut, shabatEnterMinBeforeSunset} = extraDetails;

                    const arrayOfDates = [];
                    return new Promise((resolve=>{
                        setTimeout(() => {
                            const dateToIterate = new Date(startDate.getTime())
                            while (dateToIterate < endDate){
                                arrayOfDates.push(new Date(dateToIterate.getTime()));
                                dateToIterate.setDate(dateToIterate.getDate() + 1);
                            }
                            const items = [];
                            arrayOfDates.forEach(date=>{
                                const timesOfDay = tfilotParser.calcTimesV2({times, date, location, useYeshivaTimes, shabatExitMinAfterStarsCameOut, shabatEnterMinBeforeSunset})
                                console.log(timesOfDay)
                                items.push({
                                    date,
                                    description: timesOfDay.map(t=>`${t.name} - ${moment(t.date).format("HH:mm")}`).join(' , ')
                                })
                            });
                            resolve(items);
                        }, 0);
                    }))
                }

                if(startDate > endDate){
                    console.warn("can't calculate startDate > endDate");
                    return;
                }
                setRendering(true);
                await new Promise(resolve => setTimeout(resolve, 100))
                const items = await calcDates();
                setRendering(false);

                const pdfGenerator = new PDFGenerator({
                    base64Logo,
                    synagogueName,
                    title: TextViewer({text: 'times_of_the_day', addChar: ': ',returnString: true, addedString: synagogueName}),
                    type: 'tfilotAndLessons',
                    items,
                    exportType
                });
                await pdfGenerator.init();
                pdfGenerator.makePDF();
            }}><TextViewer text={'create_dedicate_report'}/></button>
        </div>
    }


    if(loading || rendering) return <Loading/>


    const renderPage =()=>{
        if(path == 'prayersReports') return <PrayersReports/>
        if(path == 'pdfMode') return <PdfMode/>
        if(path == 'specialDatesReports') return <SpecialDatesReports/>
        if(path == 'aliotReport') return <AliotReport/>
        if(path == 'timesOfTheDayReports') return <TimesOfTheDayReports/>
        if(path == 'tfilotAndLessons') return <TfilotAndLessonsReports times={times} extraDetails={extraDetails}/>


        return <React.Fragment>
            {/*<TextViewer text={'choose_report_type'} addChar={':'}/>*/}

            <hr className={'divider-report'} />


            {Object.keys(CONFIG.types).map(type=>{
                const {TextViewer: tv} = CONFIG.types[type];
                return <div>
                    <button className={'main-button-report'} onClick={()=>{
                        history.push({
                            hash: `#reportsv2#${type}`
                        });
                        setPath(type)
                    }}>
                        <TextViewer text={tv}/>
                    </button>
                    <hr className={'divider-report'} />

                </div>
            })}
        </React.Fragment>
    }




    return <div>
        <div className={'top-menu-bar-reports'}>
            <a onClick={()=>{
                history.push({
                    hash: `#reportsv2`
                });
            }}>{TextViewer({text: 'reports', returnString: true})}</a>
            {path !=='reportsv2' ? <span> > {TextViewer({text: CONFIG.types[path]?.TextViewer, returnString: true})}</span> : null}
        </div>

        <div className={'reports-v2-container'}>{renderPage()}</div>
    </div>
}

export default ReportsV2;