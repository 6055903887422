import React, {useEffect, useState} from 'react';
import './Donation.scss';
import TextViewer from "../../../../helpers/textViewer";
import SingletoneStoreClass from "../../../../Store/Store";
import Loading from '../Loading/Loading';
import {analyticsAddLogEvent} from "../../../../firebase/firebase";
import utils from "../../../../helpers/utils";
import axios from "axios";

const store = SingletoneStoreClass.getInstance();

const DONATION_MODES = {
    url: {
        header: {
            text: 'donation_service'
        },
        body: {
            render: (link, urlSupportIframe, setUrlSupportIframe)=>{
                if(urlSupportIframe) return <div className={'donation-body-iframe'}>
                    <iframe
                        src={link}
                        onLoad={(event)=>{
                            console.log("iframe loaded!!!", event.target.contentWindow.window.length);
                            if(event.target.contentWindow.window.length === 0) setUrlSupportIframe(false);
                        }}
                        frameBorder="0" marginHeight="0" marginWidth="0">Loading…
                    </iframe>
                </div>
                return <div className={'donation-body-paybox'}>
                    <a href={link} target="_blank">{link}</a>
                </div>
            }
        }
    },
    extra: {
        header: {
            text: 'donation_details'
        },
        body: {
            render: (text)=>{
                return <div className={'donation-body-extra-details'}>
                    {text}
                </div>
            }
        }
    },
    paybox: {
        header: {
            text: 'PayBox'
        },
        body: {
            render: (utl)=>{
                return <div className={'donation-body-paybox'}>
                    <a href={utl} target="_blank">{utl}</a>
                </div>
            }
        }
    }
}


const Donation = (props)=>{
    const [loading, setLoading] = useState(true);
    const [donationObj, setDonationObj] = useState(null);
    const [selectedTab, setSelectedTab] = useState(null);
    const [urlSupportIframe, setUrlSupportIframe] = useState(true);



    useEffect(() => {
        const fetchData = async ()=>{
            let { donation } = await store.getSynagoguePublicValues({keys: ['donation']});
            setDonationObj(donation);
            setLoading(false);
            if(donation.url) setSelectedTab('url');
            else if(donation.extra) setSelectedTab('extra');
            else if(donation.paybox) setSelectedTab('paybox');
        };
        analyticsAddLogEvent({eventName: 'donation_event', params: { name: utils.getSynagogueName()}});

        fetchData();
    }, []);

    if(loading){
        return <Loading/>
    }

    return <div className={'donation-container'}>
        <div className={'donation-title'}>
            <TextViewer text={"donation"}/>
        </div>
        <div className={'donation-tabs'}>
            {donationObj.url ? <div className={`donation-tab ${selectedTab === 'url' ? 'selected' : ''}`} onClick={()=>setSelectedTab('url')}><TextViewer text={DONATION_MODES.url.header.text}/></div> : null}
            {donationObj.extra ? <div className={`donation-tab ${selectedTab === 'extra' ? 'selected' : ''}`} onClick={()=>setSelectedTab('extra')} ><TextViewer text={DONATION_MODES.extra.header.text}/></div> : null}
            {donationObj.paybox ? <div className={`donation-tab ${selectedTab === 'paybox' ? 'selected' : ''}`} onClick={()=>setSelectedTab('paybox')} ><TextViewer text={DONATION_MODES.paybox.header.text}/></div> : null}
        </div>
        <div className={'donation-body'}>
            {DONATION_MODES[selectedTab] ? DONATION_MODES[selectedTab].body.render(donationObj[selectedTab], urlSupportIframe, setUrlSupportIframe) : null}
        </div>
    </div>

}

export default Donation;