import uuid from "uuid";

/**
 *
 * @param itemToRender
 * itemToRender ->
 */

const calcPaymentHistory = ({itemToRender,itemToUpdate, isPayed, subPayment, totalPayment, type='קיזוז', details = ''})=>{
    const keyNAme = type === 'תרומה' ? 'donation' : 'aliyah';
    // console.log('calcPaymentHistory params: ',{itemToRender,itemToUpdate, isPayed, subPayment, totalPayment, type, details, keyNAme});

    if(!itemToRender.isPayed && isPayed){
        //payment have been executed

        let amountOfPayment = 0;

        if(!subPayment){
            amountOfPayment = Number(totalPayment);
        }else if(subPayment){
            if(!itemToRender[`${keyNAme}SubPayment`]){
                //first sub payments
                amountOfPayment = Number(subPayment);
            }else{
                //sub payments already done on the past
                amountOfPayment = Number(totalPayment) - Number(subPayment);
            }
        }

        const historyPayment = {
            amount: amountOfPayment,
            date: new Date().toISOString(),
            type,
            details,
            id: uuid.v1()
        }

        if(itemToUpdate.historyPayments && Array.isArray(itemToUpdate.historyPayments)){
            itemToUpdate.historyPayments.push(historyPayment)
        }else{
            itemToUpdate.historyPayments = [historyPayment];
        }

    }
    else if(subPayment && (subPayment < totalPayment)){
        let amount;
        if(subPayment > Number(itemToRender[`${keyNAme}SubPayment`])){
            //sub payment already exist, this is the n time
            amount = subPayment - Number(itemToRender[`${keyNAme}SubPayment`]);
        }else{
            //this is the first time sub payment have been created
            amount = Number(subPayment)
        }
        const historyPayment = {
            amount,
            date: new Date().toISOString(),
            type,
            details,
            id: uuid.v1()
        }

        if(itemToUpdate.historyPayments && Array.isArray(itemToUpdate.historyPayments)){
            itemToUpdate.historyPayments.push(historyPayment)
        }else{
            itemToUpdate.historyPayments = [historyPayment];
        }
    }
    // console.log('calcPaymentHistory after: ',itemToUpdate);

}

export default calcPaymentHistory;