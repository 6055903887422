import React, {useState, useEffect} from 'react';
import './AliyotBulkPanel.scss';
import TextViewer from "../../../../../helpers/textViewer";
import ALIYAH_CONFIG from "../config/aliyahConfig";
import AliyahPanel from "./AliyahPanel";
import '../../PrayersDetails/PrayerDetails/PrayerDetails.scss';
import {message} from "antd";

const AliyotBulkPanel =(props)=>{

    const {users, parasha, date, close, updateData } = props;

    const [userToItemMap, setUserToItemMap] = useState({});

    useEffect(() => {
        console.log("userToItemMap: ",userToItemMap);
    }, [userToItemMap]);

    const onItemChange = (item)=>{
        const {userIndex, ...itemToSave} = item;
        if(userIndex && userIndex !== -1){
            const currentUserToItemMap = {...userToItemMap};
            currentUserToItemMap[userIndex] = itemToSave;
            setUserToItemMap(currentUserToItemMap);
        }
    }

    const onSaveClicked = ()=>{
        const updatedItems = [...users];
        let updatedAliyotCounter = 0;

        Object.keys(userToItemMap).forEach(userIndex=>{
            if(userIndex === -1) return;

            if(Array.isArray(updatedItems[userIndex].aliyot)){
                updatedItems[userIndex].aliyot.push(userToItemMap[userIndex]);
            }else{
                updatedItems[userIndex].aliyot = [userToItemMap[userIndex]]
            }
            updatedAliyotCounter++;
        })

        updateData({fieldName: 'manager', data: updatedItems});
        close();
        message.open({
            type: 'success',
            content: TextViewer({text: `${updatedAliyotCounter} עליות נוספו בהצלחה`, returnString: true}),
        })
    }

    return <div>
        <div className={'title'}>
            <TextViewer text={'add_aliot_of_parashat'} addedString={parasha}/>
        </div>
        {ALIYAH_CONFIG.aliyahType.options.map((aliyahType, index)=>{
            if(aliyahType === 'אחר') return null;
            return <div>
                <AliyahPanel users={users}
                             index={-1}
                             aliyahIndex={-1}
                             cloneMode={false}
                             noTitle={true}
                             noActions={true}
                             onItemChange={onItemChange}
                             aliyahTypeName={aliyahType}
                             aliyahDate={date}
                             close={close}
                             updateData={null}/>
                { ALIYAH_CONFIG.aliyahType.options.length -2 === index? null :  <hr className={'aliya-divider'}/>}
            </div>
        })}
        <div>
            <button disabled={Object.keys(userToItemMap).length === 0} onClick={onSaveClicked}>{<TextViewer text={'save'}/>}</button>
            <button onClick={()=>close()}><TextViewer text={'return'}/></button>
        </div>

    </div>

}

export default AliyotBulkPanel;