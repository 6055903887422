import React, {useEffect, useState} from 'react';
import SingletoneStoreClass from "../../../../../../Store/Store";
import './generalEventsViewer.scss';

const store = SingletoneStoreClass.getInstance();

const GeneralEventsViewer = (props)=> {
    const { events } = props;

    return <div className={'general-events-viewer-container'}>
        {events.map(event=><div className={'general-events-item'}>{event}</div>)}
    </div>


}
export default GeneralEventsViewer;