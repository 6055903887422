import React, {useState, useEffect} from 'react';
import './Main.scss';
import TextViewer from "../../../../helpers/textViewer";
import SingletoneStoreClass from "../../../../Store/Store";
import utils from "../../../../helpers/utils";
import plus_icon from "../../icons/plus.svg";
import add_people_icon from "../../icons/add_people.svg";
import clock_white_icon from "../../icons/clock_white.svg";
import pdf_icon from "../../icons/pdf-export.svg";
import whatsapp_icon from "../../icons/whatsapp-icon.svg";
import invoice_icon from "../../icons/invoice.svg";

import { Select } from "antd";
import Loading from '../../Main/Loading/Loading'
import {useHistory} from "react-router-dom";
import PDFGenerator from "../../PDFGenerator/PDFGenerator";
import prayerConfig from '../PrayersDetails/config/prayerConfig';
import moment from 'moment';
const {Option} = Select;
const { getTimesStatics, getUsersStatics } = utils;


const store = SingletoneStoreClass.getInstance();

const STATS_PERIOD = {
    always: 'always',
    lastWeek: 'lastWeek',
    lastMonth: 'lastMonth',
}

const IMPORTANT_DATES_PERIOD = {
    nextWeek: 'nextWeek',
    nextMonth: 'nextMonth',
    next2Months: 'next2Months',
    next3Months: 'next3Months',
    next4Months: 'next4Months',
    next6Months: 'next6Months',
    all: 'all'
}


const Main = (props)=>{

    const { times, users, synagogueName, base64Logo, location, updates } = props;

    const userName = store.getUserName()

    const [loading, setLoading] = useState(true);
    const [userStats, setUserStats] = useState([]);
    const [statsPeriod, setStatsPeriod] = useState(STATS_PERIOD.always);
    const [statsPrayersAmount, setStatsPrayersAmount] = useState(null);
    const [statsPrayersNotificationAmount, setStatsPrayersNotificationAmount] = useState(null);
    const [importantDatePeriod, setImportantDatePeriod] = useState(IMPORTANT_DATES_PERIOD.nextMonth);
    const [importantDateType, setImportantDateType] = useState(IMPORTANT_DATES_PERIOD.all);

    let currentParasha = store.getParasha(location);
    let lastParasha = store.getParasha(location, moment().day(moment().day() >= 6 ? 5 :-2).toDate());



    const history = useHistory();

    useEffect(() => {
        const run = async ()=>{
            setLoading(true);
            let usersStats = await store.getSynagogueUsersStats();
            setUserStats(usersStats);
            setLoading(false);
        }
        run();
    }, [])


    useEffect(() => {
        const getDateXDaysAgo = (numOfDays, date = new Date())=> {
            const daysAgo = new Date(date.getTime());
            daysAgo.setDate(date.getDate() - numOfDays);
            return daysAgo;
        }

        let users;

        if(statsPeriod === STATS_PERIOD.always){
            users = userStats;
        }else if(statsPeriod === STATS_PERIOD.lastWeek) {
            const lastWeekDay = getDateXDaysAgo(7);
            users = userStats.filter(u=>new Date(u.lastUpdateAt) > lastWeekDay);
        }else if(statsPeriod === STATS_PERIOD.lastMonth) {
            const lastMonthDate = getDateXDaysAgo(30);
            users = userStats.filter(u=>new Date(u.lastUpdateAt) > lastMonthDate);
        }

        setStatsPrayersAmount(users.length);
        setStatsPrayersNotificationAmount(users.filter(u=>u.fcmToken).length);

    }, [statsPeriod, loading])

    if(loading) return <Loading/>

    const { activeEventsCounter, nonActiveEventsCounter, minyanNames, minyansCounter, tfilaCounter } = getTimesStatics(times);
    let {futureAliyotIncome, actualAliyotIncome, actualDonationIncome, futureDonationIncome, importantDates = []} = getUsersStatics({users}, location);


    const filterImportantDates = (events)=>{

        let data = events || importantDates;

        // console.log("-----data:",data)


        const getDateXDaysNext = (numOfDays, date = new Date())=> {
            const daysNext = new Date(date.getTime());
            daysNext.setDate(date.getDate() + numOfDays);
            return daysNext;
        }

        const yesterday = new Date(Date.now() - 86400000); // that is: 24 * 60 * 60 * 1000


        const currentDate = yesterday;

        if(importantDatePeriod === IMPORTANT_DATES_PERIOD.all){
            return data;
        }else if(importantDatePeriod === IMPORTANT_DATES_PERIOD.nextWeek) {
            const nextWeekDay = getDateXDaysNext(7);
            return data.filter(importantDate=>importantDate.date > currentDate && importantDate.date < nextWeekDay);
        }else if(importantDatePeriod === IMPORTANT_DATES_PERIOD.nextMonth) {
            const nextMonthDate = getDateXDaysNext(30);
            return data.filter(importantDate=>importantDate.date > currentDate && importantDate.date < nextMonthDate);
        }else if(importantDatePeriod === IMPORTANT_DATES_PERIOD.next2Months) {
            const nextMonthDate = getDateXDaysNext(60);
            return data.filter(importantDate=>importantDate.date > currentDate && importantDate.date < nextMonthDate);
        }else if(importantDatePeriod === IMPORTANT_DATES_PERIOD.next3Months) {
            const nextMonthDate = getDateXDaysNext(90);
            return data.filter(importantDate=>importantDate.date > currentDate && importantDate.date < nextMonthDate);
        }else if(importantDatePeriod === IMPORTANT_DATES_PERIOD.next4Months) {
            const nextMonthDate = getDateXDaysNext(120);
            return data.filter(importantDate=>importantDate.date > currentDate && importantDate.date < nextMonthDate);
        }else if(importantDatePeriod === IMPORTANT_DATES_PERIOD.next6Months) {
            const nextMonthDate = getDateXDaysNext(150);
            return data.filter(importantDate=>importantDate.date > currentDate && importantDate.date < nextMonthDate);
        }
    }

    const filterByType = (array)=>{
        if(!importantDateType || importantDateType === IMPORTANT_DATES_PERIOD.all ) return array;
        return array.filter(a=>a.reason === importantDateType);
    }

    const toPercent = ({part, total}) => Math.round((part / total) * 100)

    return <div className={'management-main-container'}>
        <div className={'main-hello'}>
            <TextViewer text={'hello'} addChar={` ${userName}`} addedString={'!'}/>
        </div>
        <div className={'main-action-buttons'}>
            <div className={'action-button'} onClick={()=>{
                history.push({
                    hash: 'times',
                    search: 'display_one'
                });
            }}>
                <img src={plus_icon} alt="icon"/>
                <TextViewer text={'add_tfila_lesson'}/>
            </div>
            <div className={'action-button'} onClick={()=>{
                history.push({
                    hash: 'prayers_details',
                    search: 'display_one'
                })}
            }>
                <img src={add_people_icon} alt="icon"/>
                <TextViewer text={'add_prayer'}/>
            </div>
            <div className={'action-button'} onClick={()=>{
                history.push({
                    hash: 'messages_and_notifications',
                    search: 'display_one'
                })}}>
                <img src={clock_white_icon} alt="icon"/>
                <TextViewer text={'publish_note'}/>
            </div>
            <div className={'action-button'} onClick={()=>{
                history.push({
                    hash: 'aliyah_panel',
                    search: 'display_one'
                })}
            }>
                <img src={plus_icon} alt="icon"/>
                <TextViewer text={'add_aliyah'}/>
            </div>
            <div className={'action-button'} onClick={()=>{
                history.push({
                    hash: 'aliyah_panel',
                    search: `?display_one=yes&multi_sliyah=yes&parasha=${lastParasha}&date=${moment().day(moment().day() >= 6 ? 6 :-1).toDate().toISOString().substr(0, 10)}`
                })}
            }>
                <img src={plus_icon} alt="icon"/>
                <TextViewer text={'add_aliot_of_parashat'} addedString={lastParasha}/>
            </div>
            <div className={'action-button'} onClick={async ()=>{
                const lastSunday = moment().startOf('week').toDate();
                const nextSaturday = moment().startOf('week').add(13, 'day').toDate();
                const weeklyReportUsers = utils.calcWeeklyImportantUsers({users, currentParasha, startDate: lastSunday, endDate: nextSaturday, TextViewer});

                const pdfGenerator = new PDFGenerator({
                    base64Logo,
                    synagogueName,
                    title: TextViewer({text: 'generate_aliyot_weekly_report', addedString: currentParasha, addChar: ' פרשת', returnString: true}),
                    type: 'weeklyReportUsers',
                    items: weeklyReportUsers
                });
                await pdfGenerator.init();
                pdfGenerator.makePDF();
            }}>
                <img src={plus_icon} alt="icon"/>
                <TextViewer text={'generate_aliyot_weekly_report' } addedString={currentParasha} addChar={' פרשת'} />
            </div>
            <div className={'action-button'} onClick={()=>{

                let msg = '';
                msg+= '*';
                msg+= '📢';
                msg+= 'עדכון צוות הגבאים';
                msg+= '📣';
                msg+= '*';
                msg+= '%0A';
                msg+= '%0A';


                const relevantUpdates= utils.filterUpdates({updates});

                console.log("relevantUpdates:",relevantUpdates);


                if(relevantUpdates.length > 0) {
                    const eventUpdate = relevantUpdates.pop()
                    if (eventUpdate.title) {
                        msg += `*${eventUpdate.title}*`;
                    }
                    if (eventUpdate.text) {
                        if(eventUpdate.title) {
                            msg += ': ';
                        }
                        msg += eventUpdate.text;
                    }
                }

                msg+= '%0A';
                msg+= '%0A';




                msg+= 'לפרטים נוספים, עדכונים, זמני תפילות ושיעורים בקרו באפליקציה שלנו: https://mysynagogue.net';
                msg+= `/${utils.getSynagogueName()}`;

                window.open( `https://api.whatsapp.com/send?text=${msg}`, '_blank');

                }
            }>
                <img className={'whats-app-button'} src={whatsapp_icon} alt="icon"/>
                <TextViewer text={'update_prayers'}/>
            </div>
            <div className={'action-button zoom-in-out-animation'} onClick={()=>{
                history.push({
                    hash: 'invoices',
                })}
            }>
                <img className={'invoice-logo'} src={invoice_icon} alt="icon"/>
                <TextViewer text={'invoices'}/>
            </div>
        </div>

        <div className={'statics'}>
            <div className={'statics-title'}>
                <TextViewer text={'statics'} addChar={':'}/>
            </div>
            <div className={'statics-select-container'}>
                <Select className={'statics-select'} dropdownMatchSelectWidth={false}
                        value={statsPeriod} onChange={(period)=>{setStatsPeriod(period)}}>
                    {Object.values(STATS_PERIOD).map(statsPeriod =>
                        <Option key={`stats-${statsPeriod}`}
                                value={statsPeriod}>{<TextViewer text={statsPeriod} returnString/>}</Option>)}
                </Select>
            </div>

            <div className={'statics-body'}>
                <div className={'static'}>
                    <div className={'static-amount'}>{statsPrayersAmount}</div>
                    <div className={'static-name'}><TextViewer text={'amount_of_prayers_enters'}/></div>
                </div>
                <div className={'static'}>
                    <div className={'static-amount'}>{statsPrayersNotificationAmount}</div>
                    <div className={'static-name'}><TextViewer text={'amount_of_prayers_active_push_notifications'}/></div>
                </div>
            </div>
        </div>

        <div className={'statics'}>
            <div className={'statics-title'}>
                <TextViewer text={'events'} addChar={':'}/>
            </div>

            <div className={'statics-body'}>
                <div className={'static'}>
                    <div className={'static-amount'}>{activeEventsCounter}</div>
                    <div className={'static-name'}><TextViewer text={'amount_of_active_events'}/></div>
                </div>
                <div className={'static'}>
                    <div className={'static-amount'}>{nonActiveEventsCounter}</div>
                    <div className={'static-name'}><TextViewer text={'amount_of_non_active_events'}/></div>
                </div>
                <div className={'static'}>
                    <div className={'static-amount'}>{minyansCounter}</div>
                    <div className={'static-name'}><TextViewer text={'minyans_amount'}/></div>
                </div>
            </div>
            {minyanNames.length > 1 ? <div className={'added-string-info'}><TextViewer text={'minyan_names'} addedString={minyanNames.map(m=>`"${m}"`).toString()}/></div> : null}

        </div>

        <div className={'statics'}>
            <div className={'statics-title'}>
                <TextViewer text={'prayers_amount'} addChar={':'}/>
            </div>

            <div className={'statics-body'}>
                <div className={'static'}>
                    <div className={'static-amount'}>{users.length}</div>
                    <div className={'static-name'}><TextViewer text={'total_prayers_amount'}/></div>
                </div>
                <div className={'static'}>
                    <div className={'static-amount'}>{users.reduce((accumulator, currentValue)=>accumulator + (currentValue?.aliyot?.length || 0),0)}</div>
                    <div className={'static-name'}><TextViewer text={'total_aliot_amount'}/></div>
                </div>
            </div>

        </div>

        <div className={'statics'}>
            <div className={'statics-title'}>
                <TextViewer text={'revenues'} addChar={':'}/>
            </div>

            <div className={'statics-body'}>
                <div className={'static'}>
                    <div className={'static-amount'}>₪{futureAliyotIncome + futureDonationIncome}</div>
                    <div className={'static-name'}><TextViewer text={'future_income'}/></div>
                </div>
                <div className={'static'}>
                    <div className={'static-amount'}>₪{actualAliyotIncome + actualDonationIncome}</div>
                    <div className={'static-name'}><TextViewer text={'actual_income'}/></div>
                </div>
            </div>
            {(futureAliyotIncome + futureDonationIncome + actualAliyotIncome + actualDonationIncome) ?
                <React.Fragment>.<div className={'added-string-info'}><span>{`${toPercent({part: (actualAliyotIncome + futureAliyotIncome), total: (actualAliyotIncome + futureAliyotIncome + futureDonationIncome + actualDonationIncome)})}%
            הכנסות מעליות לתורה`}</span></div>
                <div className={'added-string-info'}><span>{`${toPercent({part: (futureDonationIncome + actualDonationIncome), total: (actualAliyotIncome + futureAliyotIncome + futureDonationIncome + actualDonationIncome)})}%
            הכנסות מתרומות`}</span></div></React.Fragment> : null}

        </div>

        <div className={'statics'}>
            <div className={'statics-title'}>
                <TextViewer text={'important_dates'} addChar={':'}/>
            </div>
            <div className={'statics-title-divider'}>
                <div>
                    <div className={'statics-select-container'}>
                        <TextViewer text={'time'} addChar={':'}/>

                        <Select className={'statics-select'} dropdownMatchSelectWidth={false}
                                value={importantDatePeriod} onChange={(period)=>{setImportantDatePeriod(period)}}>
                            {Object.values(IMPORTANT_DATES_PERIOD).map(importantPeriod =>
                                <Option key={`stats-${importantPeriod}`}
                                        value={importantPeriod}>{<TextViewer text={importantPeriod} returnString/>}</Option>)}
                        </Select>
                    </div>

                    <div className={'statics-select-container'}>
                        <TextViewer text={'type_s'} addChar={':'}/>
                        <Select className={'statics-select'} dropdownMatchSelectWidth={false}
                                value={importantDateType}
                                onChange={(type)=>{
                                    setImportantDateType(type)
                                }}
                        >
                            {[IMPORTANT_DATES_PERIOD.all,...prayerConfig.importantDates.objectStructure.reason.type].map(importantDateType =>
                                <Option key={`stats-${importantDateType}`}
                                        value={importantDateType}>{<TextViewer text={importantDateType} returnString/>}</Option>)}
                        </Select>
                    </div>
                </div>
                <div>

                    <div className={'statics-select-container'}>
                        <img src={pdf_icon} alt="icon" className={'export-pdf'} onClick={async ()=>{
                            const pdfGenerator = new PDFGenerator({
                                base64Logo,
                                synagogueName,
                                title: TextViewer({text: 'important_dates', returnString: true}),
                                type: 'importantDates',
                                items: filterImportantDates(utils.getImportantDatesByUsers(users, importantDateType === 'all' ? false : importantDateType)),
                                exportType: 'pdf'
                            });
                            await pdfGenerator.init();
                            pdfGenerator.makePDF();
                        }}>
                        </img>
                    </div>
                </div>

            </div>



            {filterByType(filterImportantDates()).map(importantDate=><div className={'important-date-summery-container'} onClick={()=>{
                history.push({
                    hash: 'prayers_details',
                    search: `search=${importantDate.user.name} ${importantDate.user.family}`
                });
            }}>
                <div className={'prayer-name'}>{`${importantDate.user.name} ${importantDate.user.family}`}</div>
                <div >{importantDate.reason}: {importantDate.relation ? `(${importantDate.relation})` : ''}  {importantDate.fullName} - {importantDate.hebrewDay} {importantDate.hebrewMonth}</div>
            </div>)}
            {filterByType(filterImportantDates()).length === 0 ? <div className={'added-string-info'}><TextViewer text={'no_data'}  addChar={'.'}/></div> : null}

        </div>

    </div>

}

export default Main;