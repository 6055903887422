import React, {useEffect, useState} from 'react';
import './SpecialMessages.scss';
import TextViewer from "../../../../helpers/textViewer";
import SingletoneStoreClass from "../../../../Store/Store";
import occasions from "../../../../Store/occasions.json";

const store = SingletoneStoreClass.getInstance();

const SFERA_MAP =
{
    "Nisan_16": "חסד שבחסד",
    "Nisan_17": "גבורה שבחסד",
    "Nisan_18": "תפארת שבחסד",
    "Nisan_19": "נצח שבחסד",
    "Nisan_20": "הוד שבחסד",
    "Nisan_21": "יסוד שבחסד",
    "Nisan_22": "מלכות שבחסד",
    "Nisan_23": "חסד שבגבורה",
    "Nisan_24": "גבורה שבגבורה",
    "Nisan_25": "תפארת שבגבורה",
    "Nisan_26": "נצח שבגבורה",
    "Nisan_27": "הוד שבגבורה",
    "Nisan_28": "יסוד שבגבורה",
    "Nisan_29": "מלכות שבגבורה",
    "Nisan_30": "חסד שבתפארת",
    "Iyyar_1": "גבורה שבתפארת",
    "Iyyar_2": "תפארת שבתפארת",
    "Iyyar_3": "נצח שבתפארת",
    "Iyyar_4": "הוד שבתפארת",
    "Iyyar_5": "יסוד שבתפארת",
    "Iyyar_6": "מלכות שבתפארת",
    "Iyyar_7": "חסד שבנצח",
    "Iyyar_8": "גבורה שבנצח",
    "Iyyar_9": "תפארת שבנצח",
    "Iyyar_10": "נצח שבנצח",
    "Iyyar_11": "הוד שבנצח",
    "Iyyar_12": "יסוד שבנצח",
    "Iyyar_13": "מלכות שבנצח",
    "Iyyar_14": "חסד שבהוד",
    "Iyyar_15": "גבורה שבהוד",
    "Iyyar_16": "תפארת שבהוד",
    "Iyyar_17": "נצח שבהוד",
    "Iyyar_18": "הוד שבהוד",
    "Iyyar_19": "יסוד שבהוד",
    "Iyyar_20": "מלכות שבהוד",
    "Iyyar_21": "חסד שביסוד",
    "Iyyar_22": "גבורה שביסוד",
    "Iyyar_23": "תפארת שביסוד",
    "Iyyar_24": "נצח שביסוד",
    "Iyyar_25": "הוד שביסוד",
    "Iyyar_26": "יסוד שביסוד",
    "Iyyar_27": "מלכות שביסוד",
    "Iyyar_28": "חסד שבמלכות",
    "Iyyar_29": "גבורה שבמלכות",
    "Sivan_1": "תפארת שבמלכות",
    "Sivan_2": "נצח שבמלכות",
    "Sivan_3": "הוד שבמלכות",
    "Sivan_4": "יסוד שבמלכות",
    "Sivan_5": "מלכות שבמלכות"
}


const SpecialMessages = (props)=>{
    const {loading, } = props;

    const {monthName, dayOfMonth} = store.getHebrewDayAndMouthAndYear({date: new Date()});


    if(loading) return null;

    const messageData = [];


    if(monthName === 'Tishrei' && dayOfMonth >=14 && dayOfMonth <=22){
        messageData.push(<div className={'special-message-container'}>
            <div className={'special-message-title'}><TextViewer className={'calendar-loading'} text={"ushpizin"}/></div>
            <div className={`special-message-text`}>
                עוּלוֹ אֻשְׁפִּיזִין עִלָּאִין קַדִּישִׁין
                עוּלוֹ אֲבַהֵן עִלָּאִין קַדִּישִׁין
                לְמֵיתַב בְּצִילָא דִּמְהֵימְנוּתָא עִלָּאָה.

                <br/>
                לָעוֹל אַבְרָהָם רְחִימָא
                <br/>
                וְעִמֵּהּ יִצְחָק עֲקִידְתָּא
                <br/>
                וְעִמֵּהּ יַעֲקֹב שְׁלִימְתָּא
                <br/>
                וְעִמֵּהּ מֹשֶׁה רַעְיָא מְהֵימְנָא
                <br/>
                וְעִמֵּהּ אַהֲרֹן כַּהֲנָא קַדִּישָׁא
                <br/>
                וְעִמֵּהּ יוֹסֵף צַדִּיקָא
                <br/>
                וְעִמֵּהּ דָּוִד מַלְכָּא מְשִׁיחָא
                <br/>


                בְּסֻכּוֹת תֵּשְׁבוּ
                תִּיבוּ אֻשְׁפִּיזִין עִלָּאִין תִּיבוּ
                תִּיבוּ אֻשְׁפִּיזֵי מֵהִימְנוּתָא תִּיבוּ.

            </div>
        </div>)
    }

    if(monthName === 'Nisan' && dayOfMonth === 13){
        messageData.push(<div className={'special-message-container'}>
            <div className={'special-message-title'}><TextViewer className={'calendar-loading'} text={"בדיקת חמץ"}/></div>
            <div className={`special-message-text`}>
                <br/>
                הֲרֵינִי מוּכָן וּמְזוּמָן לְקַיֵּם מִצְוַת עֲשֵׂה וְלֹא תַעֲשֵׂה שֶׁל בְּדִיקַת חָמֵץ לְשֵׁם יִחוּד קוּדְשָׁא בְּרִיךְ הוּא וּשְׁכִינְתֵּיהּ עַל יְדֵי הַהוּא טָמִיר וְנֶעֱלָם בְּשֵׁם כָּל יִשְׂרָאֵל. וִיהִי נוֹעֲם אֲדֹנָי אֱלֹהֵינוּ עָלֵינוּ וּמַעֲשֵׂה יָדֵינוּ כּוֹנְנָה עָלֵינוּ וּמַעֲשֵׂה יָדֵינוּ כּוֹנְנֵהוּ.
                <br/>
                בָּרוּךְ אַתָּה אֲדֹנָי אֱלֹהֵינוּ מֶלֶךְ הָעוֹלָם, אֲשֶׁר קִדְּשָׁנוּ בְּמִצְוֹתָיו וְצִוָּנוּ עַל בִּיעוּר חָמֵץ
                <br/>
                מיד אחר הבדיקה יבטל את החמץ ויאמר:
                <br/>
                כָּל חֲמִירָא וַחֲמִיעָא דְאִיכָּא בִרְשׁוּתִי, דְלָא חֲמִתֵּיהּ, וּדְלָא בִעַרְתֵּיהּ, וּדְלָא יְדַעְנָא לֵיהּ, לִבָּטֵל וְלֶהֱוֵי הֶפְקֵר כְּעַפְרָא דְאַרְעָא.<br/>
                <br/>
                ואם אינו מבין בלשון תרגום, יאמר הביטול בלשון שמבין [וזה הנוסח בעברית: "כל חמץ ושאור שיש ברשותי שלא ראיתיו, ושלא ביערתיו ושלא ידעתיו יבטל ויהיה הפקר כעפר הארץ"]
            </div>
        </div>)
    }

    if(monthName === 'Nisan' && dayOfMonth === 14){
        messageData.push(<div className={'special-message-container'}>
            <div className={'special-message-title'}><TextViewer className={'calendar-loading'} text={"ביעור חמץ"}/></div>
            <div className={`special-message-text`}>
                <br/>
                הֲרֵינִי מוּכָן וּמְזוּמָן לְקַיֵּם מִצְוַת עֲשֵׂה וְלֹא תַעֲשֶׂה שֶׁל שְׂרֵפַת חָמֵץ לְשֵׁם יִחוּד קוּדְשָׁא בְּרִיךְ הוּא וּשְׁכִינְתֵּיהּ עַל יְדֵי הַהוּא טָמִיר וְנֶעֱלָם בְּשֵׁם כָּל יִשְׂרָאֵל:
                <br/>
                בערב פסח לאחר שריפת חמץ יבטלנו, ויאמר:
                <br/>
                כָּל חֲמִירָא וַחֲמִיעָא דְּאִכָּא בִרְשׁוּתִי דַּחֲזִתֵּהּ וּדְלָא חֲזִתֵּהּ דַּחֲמִתֵּהּ וּדְלָא חֲמִתֵּהּ דְּבִעַרְתֵּהּ וּדְלָא בִעַרְתֵּהּ

                לִבָּטֵל וְלֶהֱוֵי הֶפְקֵר כְּעַפְרָא דְאַרְעָא
                <br/>
                תפילה לאחר הביעור:
                <br/>
                יְהִי רָצוֹן מִלְפָנֶיךָ יְיָ אֱלֹהֵינוּ וֵאלֹהֵי אֲבוֹתֵינוּ כְּשֵׁם שֶׁאֲנִי מְבַעֵר חָמֵץ מִבֵּיתִי וּמֵרְשׁוּתִי כַּךְ יְיָ אֱלֹהַי וֵאלֹהַי אֲבוֹתַי תְּבַעֵר אֶת כָּל הַחִיצוֹנִים וְאֶת רוּחַ הַטּוּמְאָה תְּבַעֵר מִן הָאָרֶץ וְאֶת יִצְרֵנוּ הָרָע תְּבַעֲרֵהוּ מֵאִתָּנוּ וְתִתֶּן לָנוּ לֵב בָּשָׂר וְכָל הַסִטְרָא אַחֲרָא וְכָל הָרִשְׁעָה כְּעָשָׁן תִּכְלֶה וְתַעֲבִיר מֶמְשֶׁלֶת זָדוֹן מִן הָאָרֶץ וְכָל הַמְעִיקִים לַשְׁכִינָה תְּבַעֲרֵם בְּרוּחַ בָּעֵר וּבְרוּחַ מִשְׁפָּט כְּשֵׁם שֶׁבִּעַרְתָּ אֶת מִצְרַיִם וְאֶת אֱלֹהֵיהֶם בַּיָמִים הַהֵם וּבִזְמַן הַזֶּה אָמֵן: וִיהִי נֹעַם אֲדֹנָי אֱלֹהֵינוּ עָלֵינוּ. וּמַעֲשֵׂה יָדֵינוּ כּוֹנְנָה עָלֵינוּ. וּמַעֲשֵׂה יָדֵינוּ כּוֹנְנֵהוּ
            </div>
        </div>)
    }

    if(monthName === 'Nisan' && dayOfMonth >= 0 && dayOfMonth <= 31){
        messageData.push(<div className={'special-message-container'}>
            <div className={'special-message-title'}><TextViewer className={'calendar-loading'} text={"ברכת האילנות"}/></div>
            <div className={`special-message-text`}>
                <br/>
                מברכים ברכת האילנות לכתחילה בחודש ניסן, אין מברכים את הברכה אלא על פרחי אילנות מאכל המלבלבים. וצריך שיהיו לפחות שני אילנות<br/>
                <br/>
                בָּרוּךְ אַתָּה יְהֹוָה, אֱלֹהֵֽינוּ מֶלֶךְ הָעוֹלָם, שֶׁלֹּא חִסֵּר בְּעוֹלָמוֹ כְּלוּם, וּבָרָא בוֹ בְּרִיוֹת טוֹבוֹת וְאִילָנוֹת טוֹבוֹת, לֵהָנוֹת בָּהֶם בְּנֵי אָדָם<br/>
            </div>
        </div>)
    }

    if((monthName === 'Nisan' && dayOfMonth >= 16 && dayOfMonth <= 31)
        || (monthName === 'Iyyar' && dayOfMonth >= 1 && dayOfMonth <= 31)
        || (monthName === 'Sivan' && dayOfMonth >= 1 && dayOfMonth <= 5)
    ){
        let todaySfira;
        let sfera;
        if(new Date().getHours() >= 19){
            var today = new Date();
            var tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000));
            const nextDayHebrewObj = store.getHebrewDayAndMouthAndYear({date: tomorrow});
            todaySfira = occasions[nextDayHebrewObj.monthName][nextDayHebrewObj.dayOfMonth];
            sfera = SFERA_MAP[`${nextDayHebrewObj.monthName}_${nextDayHebrewObj.dayOfMonth}`];
        }else{
            todaySfira = occasions[monthName][dayOfMonth];
            sfera = SFERA_MAP[`${monthName}_${dayOfMonth}`];
        }

        if(todaySfira.length) messageData.push(<div className={'special-message-container'}>
            <div className={'special-message-title'}><TextViewer className={'calendar-loading'} text={"סדר ספירת העומר"}/></div>
            <div className={`special-message-text`}>
                <br/>
                לְשֵׁם יִחוּד קוּדְשָׁא בְּרִיךְ הוּא וּשְׁכִינְתֵּיהּ, בִּדְחִילוּ וּרְחִימוּ, לְיַחֲד שֵׁם י"ה בו"ה בְּיִחוּדָא שְׁלִים בְּשֵׁם כָּל יִשְׂרָאֵל. הִנְנִי מוּכָן וּמְזֻמָּן לְקַיֵּם מִצְוַת עֲשֵׂה שֶׁל סְפִירַת הָעֹמֶר כְּמוֹ שֶׁכָּתוּב בַּתּוֹרָה: וּסְפַרְתֶּם לָכֶם מִמָּחֳרַת הַשַּׁבָּת מִיּוֹם הֲבִיאֲכֶם אֶת עֹמֶר הַתְּנוּפָה שֶׁבַע שַׁבָּתוֹת תְּמִימֹת תִּהְיֶינָה: עַד מִמָּחֳרַת הַשַּׁבָּת הַשְּׁבִיעִת תִּסְפְּרוּ חֲמִשִּׁים יוֹם וְהִקְרַבְתֶּם מִנְחָה חֲדָשָׁה לַיְיָ: וִִיהִי נֹעַם יְיָ אֱלֹהֵינוּ עָלֵינוּ ומַעֲשֵׂה יָדֵינוּ כּוֹנְנָה עָלֵינוּ וּמַעֲשֵׂה יָדֵינוּ כּוֹנְנֵהוּ:
                <br/>
                <br/>
                בָּרוּךְ אַתָּה יְיָ אֱלֹהֵינוּ מֶלֶךְ הָעוֹלָם, אֲשֶׁר קִדְּשָׁנוּ בְּמִצְוֹתָיו וְצִוָּנוּ עַל סְפִירַת הָעֹמֶר.
                <br/>
                <br/>
                {todaySfira.find(s=>s.includes('לָעֽוֹמֶר'))}
                <br/>
                <br/>
                לַמְנַצֵּחַ בִּנְגִינוֹת מִזְמוֹר שִׁיר: אֱלֹהִים יְחָנֵּנוּ וִיבָרְכֵנוּ, יָאֵר פָּנָיו אִתָּנוּ, סֶלָה: לָדַעַת בָּאָרֶץ דַרְכֶּךָ, בְּכָל גּוֹיִם יְשׁוּעָתֶךָ: יוֹדוּךָ עַמִּים אֱלֹהִים, יוֹדוּךָ עַמִּים כֻּלָּם: יִשְׂמְחוּ וִירַנְּנוּ לְאֻמִּים, כִּי תִשְׁפֹּט עַמִּים מִישֹׁר, וּלְאֻמִּים בָּאָרֶץ תַּנְחֵם סֶלָה: יוֹדוּךָ עַמִּים אֱלֹהִים, יוֹדוּךָ עַמִּים כֻּלָּם: אֶרֶץ נָתְנָה יְבוּלָהּ, יְבָרְכֵנוּ אֱלֹהִים אֱלֹהֵינוּ: יְבָרְכֵנוּ אֱלֹהִים, וְיִירְאוּ אוֹתוֹ כָּל אַפְסֵי אָרֶץ:
                <br/>
                <br/>
                אָנָּא בְּכֹחַ, גְּדֻלַּת יְמִינֶךָ, תַּתִּיר צְרוּרָה.
                קַבֵּל רִנַּת, עַמֶּךָ. שַׂגְּבֵנוּ, טַהֲרֵנוּ נוֹרָא.
                נָא גִבּוֹר, דּוֹרְשֵׁי יִחוּדֶךָ, כְּבָבַת שָׁמְרֵם.
                בָּרְכֵם טַהֲרֵם, רַחֲמֵי צִדְקָתֶךָ, תָּמִיד גָּמְלֵם.
                חָסִין קָדוֹשׁ, בְּרֹב טוּבְךָ, נַהֵל עֲדָתֶךָ.
                יָחִיד גֵּאֶה, לְעַמְּךָ פְּנֵה, זוֹכְרֵי קְדֻשָּׁתֶךָ.
                בָּרוּךְ שֵׁם כְּבוֹד מַלְכוּתוֹ לְעוֹלָם וָעֶד:
                <br/>
                <br/>
                רִבּוֹנוֹ שֶׁל עוֹלָם, אַתָּה צִוִּיתָנוּ עַל יְדֵי מֹשֶׁה עַבְדֶּךָ לִסְפּוֹר סְפִירַת הָעוֹמֶר, כְּדֵי לְטַהֲרֵנוּ מִקְּלִפּוֹתֵינוּ וּמִטֻּמְאוֹתֵינוּ, כְּמוֹ שֶׁכָּתַבְתָּ בְּתוֹרָתֶךָ. וּסְפַרְתֶּם לָכֶם מִמָּחֳרַת הַשַּׁבָּת מִיּוֹם הֲבִיאֲכֶם אֶת־עֹמֶר הַתְּנוּפָה, שֶׁבַע שַׁבָּתוֹת תְּמִימֹת תִּהְיֶינָה. עַד מִמָּחֳרַת הַשַּׁבָּת הַשְּׁבִיעִת תִּסְפְּרוּ חֲמִשִּׁים יוֹם. כְּדֵי שֶׁיּטָּהֲרוּ נַפְשׁוֹת עַמְּךָ יִשְׂרָאֵל מִזֻּהֲמָתָם. וְּבְכֵן יְהִי רָצוֹן מִלְּפָנֶיךָ יְיָ אֱלֹהֵינוּ וֵאלֹהֵי אֲבוֹתֵינוּ, שֶׁבִּזְכוּת סְפִירַת הָעוֹמֶר שֶׁסָּפַרְתִּי הַיּוֹם, יְתֻקַּן מַה שֶׁפָּגַמְתִּי בִּסְפִירָה
                <br/>
                <br/>
                {sfera}
                <br/>
                <br/>
                וְאֶטָּהֵר וְאֶתְקַדֵּשׁ בִּקְדֻשָּׁה שֶׁל מַעְלָה, וַעל יְדֵי זֶה יֻשְׁפַּע שֶׁפַע רַב בְּכָל הָעוֹלָמוֹת. וּלְתַקֵּן אֶת נַפְשׁוֹתֵינוּ, וְרוּחוֹתֵינוּ, וְנִשְׁמוֹתֵינוּ, מִכָּל סִיג וּפְגַם, וּלְטַהֲרֵנוּ וּלְקַדְּשֵׁנוּ בִּקְדֻשָּׁתְךָ הָעֶלְיוֹנָה, אָמֵן סֶלָה.
            </div>
        </div>)
    }

    return <div>
        {messageData.map(m=>m)}
    </div>


};
export default SpecialMessages;