import React, {useEffect, useState} from 'react';
import './TopLevel.scss';
import Icon from '@ant-design/icons';
import {ReactComponent as bell} from "../icons/bell.svg";
import {ReactComponent as three_dots} from "../icons/three_dots.svg";
import {ReactComponent as black_main_logo} from "../icons/black_main_logo.svg";
import SingletoneStoreClass from "../../../Store/Store";
import SetLanguage from './SetLanguage/SetLanguage';
import SideMenu from './SideMenu/SideMenu';
import utils from "../../../helpers/utils";
import {Link} from 'react-router-dom';
import NotificationsManager from '../Notifications/classes/notificationsManager';
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import israelFlag from '../icons/animations/israel-flag.gif'


const store = SingletoneStoreClass.getInstance();

const TopLevel = (props) =>{

    const [loading, setLoading] = useState(true);
    const [name, setName] = useState(null);
    const [base64Logo, setBase64Logo] = useState(null);
    const [sideMenuOpen, setSideMenuOpen] = useState(false);
    const [openNotificationsCounter, setOpenNotificationsCounter] = useState(0);
    const [plasmaMode, setPlasmaMode] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [signUpMode, setSignUpMode] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if(history?.location?.pathname && history?.location?.pathname.includes('edit')) setEditMode(true);
        if(history?.location?.hash && history?.location?.hash.includes('signup')) setSignUpMode(true);
        else setEditMode(false);
        window.addEventListener("resize", ()=>{
            window.innerWidth >= 768 ? setPlasmaMode(true) : setPlasmaMode(false);
        });
        window.innerWidth >= 768 ? setPlasmaMode(true) : setPlasmaMode(false);
        const fetchData = async ()=>{
            let [{ name, extraDetails, updates }, unisynData] = await Promise.all([store.getSynagoguePublicValues({keys: ['name', 'extraDetails', 'updates']}), store.getUnisynData()]);
            const { attachmentUrl, externalFormUrl} = extraDetails;
            setName(name);
            if(extraDetails?.base64Logo){
                setBase64Logo(extraDetails?.base64Logo);
            }

            const notificationsManager = NotificationsManager.getInstance({updates: extraDetails.updates || [], attachmentUrl, externalFormUrl, unisynData});
            setOpenNotificationsCounter(notificationsManager.getUnreadNotifications());
            setLoading(false);
        };
        if(utils.getSynagogueName()) fetchData();
    }, [history.location.key]);

    const location = useLocation();


    const notificationModalOpen = location.pathname && location.pathname.includes('notification');

    if(notificationModalOpen && openNotificationsCounter){
        setOpenNotificationsCounter(0);
    }


    const onNotificationClicked = ()=>{
        if(!utils.getSynagogueName()) return;
        if(notificationModalOpen) history.push(`/${utils.getSynagogueName()}`);
        else history.push(`/${utils.getSynagogueName()}/notifications`);
    }

    if(plasmaMode && !editMode && !signUpMode){
        return <div className={'top-level-main-bar-buttons-right plasma-mode-on'}>
                <Icon className={"top-level-main-bar-icon"} component={three_dots} onClick={()=>{console.log(sideMenuOpen);setSideMenuOpen(!sideMenuOpen)}}/>
                <SideMenu open={sideMenuOpen} setOpen={setSideMenuOpen} plasmaMode={plasmaMode}/>
            </div>
    }

    return <div className={'top-level-v2-container'}>
        <div className={'top-level-empty-div'}/>
        <div className={'top-level-main-bar'}>
            <div className={'top-level-main-bar-buttons'}>
                <div className={'top-level-main-bar-buttons-right'}>
                    <Icon className={"top-level-main-bar-icon"} component={three_dots} onClick={()=>{console.log(sideMenuOpen);setSideMenuOpen(!sideMenuOpen)}}/>
                    <SideMenu open={sideMenuOpen} setOpen={setSideMenuOpen} plasmaMode={plasmaMode}/>
                    <div onClick={onNotificationClicked}>
                        <Icon className={"top-level-main-bar-icon"} component={bell}/>
                        {(openNotificationsCounter > 0) ?<div className={'notification-circle'}>{openNotificationsCounter}</div> : null}
                    </div>
                    <SetLanguage/>
                </div>
                <div className={'top-level-main-bar-buttons-left'}>
                    <Link to={`/${utils.getSynagogueName()}`}>
                        <Icon className={"top-level-main-bar-icon"} component={black_main_logo}/>
                    </Link>
                    <img src={israelFlag} className={"top-level-main-bar-icon israel-flag"}/>

                </div>

            </div>

        </div>
        {signUpMode ? null :  <div className={'top-level-synagogue'}>
            <div className={'top-level-synagogue-logo-container'}>
                {base64Logo ? <img src={base64Logo} alt="avatar" style={{width: '100%'}}/> : null}
            </div>
            <div className={'top-level-synagogue-name-diveder'}></div>
            <Link to={`/${utils.getSynagogueName()}/info_v2`}><div className={'top-level-synagogue-name'}>{name}</div></Link>
        </div>}

    </div>
}

export default TopLevel;

