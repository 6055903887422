import utils from './utils'
import hebrewLanguageMap from './languages/hebrew.json';
import englishLanguageMap from './languages/english.json';

const LANG_MAP = utils.getLanguage() === 'heb' ? hebrewLanguageMap : englishLanguageMap;

const HEBREW_TO_ENGLISH_ENUM = {
    "שחרית": 'shaharit',
    "מנחה": 'mincha',
    "ערבית": 'maariv',
    "יום ראשון": 'sunday',
    "יום שני": 'monday',
    "יום שלישי": 'tuesday',
    "יום רביעי": 'wednesday',
    "יום חמישי": 'thursday',
    "יום שישי": 'friday',
    "יום שבת": 'saturday'
}

const translateText = (props)=>{
    const { text, returnString, addedString, addChar, addBefore=false, className } = props;

    let keyWord = HEBREW_TO_ENGLISH_ENUM[text] || text;
    let result = LANG_MAP[keyWord] || keyWord;
    if(addBefore){
        if(addChar) result = `${addChar}${result}`;
        if(addedString) result += `${addedString}${result}`;
    }else{
        if(addChar) result += `${addChar}`;
        if(addedString) result += ` ${addedString}`;
    }

    return result;
}

export default translateText;