import React, {useEffect, useState} from 'react';
import './SmartNotifications.scss';
import TextViewer from "../../../../helpers/textViewer";
import moment from "moment";
import SingletoneStoreClass from "../../../../Store/Store";
import getEndOfTfila from './getEndOfTfila';

const store = SingletoneStoreClass.getInstance();


const SmartNotifications = (props) => {

    const {location, loading} = props;

    const nextEndTfila = getEndOfTfila({location});

    if(loading) return <div className={'smart-notification-container'}>
        <TextViewer text={"loading"} addedString={'...'}/>
    </div>


    return <div className={'smart-notification-container'}>
        <div className={'display-full-date'}> <TextViewer text={'today'} addChar={":"} addedString={store.getHebrewDate({location, date: new Date()})}/></div>
        <div className={'display-latest-pray-container'}>
            <div className={'display-latest-pray-time'}><span>{moment(nextEndTfila.date).format('HH:mm')}</span></div>
            <div  className={'display-latest-pray-text'}>
                <TextViewer text={'end_tfila_time'} addChar={" "}/>
                <TextViewer text={nextEndTfila.hebrewName}/>
            </div>
        </div>
    </div>
}

export default SmartNotifications;