import React, {useEffect, useState} from 'react';
import './GematriaCalculator.scss'
import TextViewer from "../../../helpers/textViewer";
import Icon from "@ant-design/icons";
import {ReactComponent as calculator_svg} from "../icons/calculator.svg";
import {useHistory, useLocation} from "react-router-dom";
import {ReactComponent as share_logo} from "../icons/share.svg";
import utils from '../../../helpers/utils';
import {analyticsAddLogEvent} from "../../../firebase/firebase";


const GEMATRIA_MAP = {
    "א": 1,
    "ב": 2,
    "ג": 3,
    "ד": 4,
    "ה": 5,
    "ו": 6,
    "ז": 7,
    "ח": 8,
    "ט": 9,
    "י": 10,
    "כ": 20,
    "ל": 30,
    "מ": 40,
    "ם": 40,
    "נ": 50,
    "ן": 50,
    "ס": 60,
    "ע": 70,
    "פ": 80,
    "ף": 80,
    "צ": 90,
    "ץ": 90,
    "ק": 100,
    "ר": 200,
    "ש": 300,
    "ת": 400
}

const GematriaCalculator = (props)=>{
    const [input, setInput] = useState('');
    const [gematriaValue, setGematriaValue] = useState(0);

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        analyticsAddLogEvent({eventName: 'gematria_calculator_event', params: { name: utils.getSynagogueName()}});

        const search = location.search;
        const inputFromUrl = new URLSearchParams(search).get('input');
        if(inputFromUrl) setInput(inputFromUrl);
    }, []);

    useEffect(() => {

        history.push({
            // pathname: '/dresses',
            search: `?input=${input}`
        })

        let sum = 0;
        for(let digit of input){
            if(GEMATRIA_MAP[digit]){
                sum += GEMATRIA_MAP[digit];
            }
        }
        setGematriaValue(sum);
    }, [input]);


    const onInputChange = (event)=>{
        setInput(event.target.value);
    }

    return <div className={'gematria-calculator-container'}>
        <div className={'gematria-calculator-title'}>
            <TextViewer text={"gematria_calculator"} className={'side-menu-item-text'}/>
        </div>
        <div className={'gematria-calculator-icon'}>
            <Icon component={calculator_svg}/>
        </div>
        <div className={'gematria-calculator-body'}>
            <textarea type={'text'} className={'gematria-calculator-input'}
                      value={input}
                      onChange={onInputChange}/>
            <div className={'gematria-calculator-value'}>{gematriaValue}</div>
        </div>

        {input ?  <div className={'bottom-button'} onClick={()=>{
            utils.genericShare({
                url: window.location.href,
                title: 'מחשבון גימטריה',
                text: `לחץ בשביל לדעת את ערך הגימטריה של: *${input}*`
            })
        }}>
            <div className={'bottom-button-icon'}>
                <Icon component={share_logo}/>
            </div>
            <div className={'bottom-button-text'}>
                <TextViewer className={'bold-text'} text={"share_gematria"}/>
            </div>
        </div> : null}

    </div>
}

export default GematriaCalculator;