import {ReactComponent as dayTimes_logo} from "../../../icons/clock.svg";
import {ReactComponent as prayers_logo} from "../../../icons/tfilot.svg";
import {ReactComponent as studies_logo} from "../../../icons/lesson.svg";


const config = {
    FILTERS: {
        prayers: {name: 'prayers', icon: prayers_logo},
        studies: {name: 'studies', icon: studies_logo},
        dayTimes: {name: 'times_of_the_day', icon: dayTimes_logo}
    }
}

export default config;