import React, {useEffect, useState} from 'react';
import './NextShbbat.scss';
import SingletoneStoreClass from "../../../../Store/Store";
import TextViewer from "../../../../helpers/textViewer";
import candle from "../../icons/candle.svg";
import candles from "../../icons/candles.svg";
import utils from "../../../../helpers/utils";
import {Select} from "antd";
import config from "../../../../config";
import moment from "moment/moment";

const { dateToHH_MM } = utils;
const store = SingletoneStoreClass.getInstance();


let cache = [];

const NextShbbat = (props) => {

    const {loading, location, useYeshivaTimes, showOne = false, shabatEnterMinBeforeSunset, shabatExitMinAfterStarsCameOut } = props;

    const [nextTimes, setNextTimes] = useState(utils.calcNextTimes({ returnFirstItem: true, shabatEnterMinBeforeSunset, shabatExitMinAfterStarsCameOut, useYeshivaTimes, location}));


    useEffect(() => {
        // setNextTimes(calcNextTimes({nextFollowDays: 100, returnFirstItem: false}));
        setNextTimes(utils.calcNextTimes({nextFollowDays: 100, returnFirstItem: false, shabatEnterMinBeforeSunset, shabatExitMinAfterStarsCameOut, useYeshivaTimes, location}))
    }, []);


    // useEffect(() => {
    //     console.log("DEBUG---nextTimes:",nextTimes)
    // }, [nextTimes]);

    const [dateToShow, setDateToShow] = useState(nextTimes[0]);

    if(loading){
        return <div className={'next-shabbat-container'}>
            <TextViewer text={"loading"} addedString={'...'}/>
        </div>
    }



    const renderTimeItem = ({icon, time, name})=>{
        return <div className={'enter-exit-shabbat-item'}>
            <img className={"enter-exit-shabbat-item-icon"} src={icon}/>
            <div className={"enter-exit-shabbat-time bold-text"}> {dateToHH_MM(time)}</div>
            <div className={"enter-exit-shabbat-name"}><TextViewer text={name}/></div>
        </div>
    }

    const onParashaSelected = (index)=>{
        if(!nextTimes[index].shabbatEnter || !nextTimes[index].shabbatExit){
            setDateToShow({...nextTimes[index],
                shabbatEnter: store.getShabatEnter(location, null, {useYeshivaTimes, date: nextTimes[index].date, shabatEnterMinBeforeSunset}),
                shabbatExit: store.getShabatExit(location, null, {useYeshivaTimes, date: nextTimes[index].date, shabatExitMinAfterStarsCameOut})
            })
        }else{
            setDateToShow(nextTimes[index])

        }
    }


    const { parasha, shabbatEnter, shabbatExit, fast } = dateToShow || {};

    return <div className={'next-shabbat-container'}>
        <div className={'parash-name-container'}>

            {parasha?.length < 8 ? <div><TextViewer text={"torha_portion"} addChar={':'}/></div>: null}
            {showOne ? <div className={'bold-text'}>{parasha}</div> :
                <Select dropdownMatchSelectWidth={true} value={parasha} onSelect={onParashaSelected}
                        className={"select-next-parasha"}
                >
                    {nextTimes.map((nextTime, index) =>
                        <Select.Option key={`nextTimes-${nextTime.parasha}`}
                                       value={index}>{nextTime.parasha}</Select.Option>)}
                </Select>
            }

        </div>
        <div className={'enter-exit-shabbat-container'}>
            {renderTimeItem({icon: candles, time: shabbatEnter, name: fast ? 'start_fast' : (parasha === 'ראש השנה - ב' ? 'מכאן אסור לעשות מלאכות לשבת' : 'candle_lighting')})}
            {renderTimeItem({icon: candle, time: shabbatExit, name: fast ? 'end_fast' : (parasha === 'ראש השנה - א' ? 'מכאן ניתן להכין מאכלים ליום השני' : 'havdalah')})}
        </div>
    </div>
}

export default NextShbbat;