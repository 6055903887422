import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import config from "./config";
import utils from './helpers/utils'
ReactDOM.render(<App />, document.getElementById('root'));


if(!config.develop && 'serviceWorker' in navigator){
    console.log("serviceWorker available for this browser.");
    navigator.serviceWorker.register("./sw.js").then(()=>{
        console.log("Service worker registered!");
    });
}else{
    console.log("serviceWorker does't available for this browser.");
}

if(utils.getLanguage() === 'en'){
    document.documentElement.style.setProperty('--app-direction', 'ltr');
    document.documentElement.style.setProperty('--app-text-align', 'left');
}else{
    document.documentElement.style.setProperty('--app-direction', 'rtl');
    document.documentElement.style.setProperty('--app-text-align', 'right');
}


//todo: add this logic for handling add home screen banner:
//
// var deferredPrompt;
//
// window.addEventListener('beforeinstallprompt', function (event) {
//     console.log("beforeinstallprompt fired!");
//     event.preventDefault();
//     deferredPrompt = event;
//     return false;
// });
//
// //now for use it when i want:
// if(deferredPrompt){
//     deferredPrompt.prompt();
//
//     deferredPrompt.userChoice.then(function (choiceResult) {
//         if(choiceResult === 'dismissed'){
//             console.log("User cancelled installation");
//         }else{
//             console.log("User added to home screen");
//         }
//
//         deferredPrompt = false;
//     })
// }