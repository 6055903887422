import React, {useState, useEffect} from 'react';
import './PersonaInfo.scss';
import PrayerDetails from "../Management/PrayersDetails/PrayerDetails/PrayerDetails";
import utils from "../../../helpers/utils";
import {useHistory} from "react-router-dom";
import {message} from "antd";
import TextViewer from "../../../helpers/textViewer";
import SingletoneStoreClass from "../../../Store/Store";
import Loading from "../Main/Loading/Loading";

const store = SingletoneStoreClass.getInstance();


const PersonaInfo = (props)=>{

    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [prayerData, setPrayerData] = useState({});


    useEffect(() => {

        const userId = utils.getUserId();
        const fetchData = async ()=>{
            try{
                const prayerDataFromCloud = await store.getPrayer({userId});
                setPrayerData(prayerDataFromCloud);
            }catch (e){
                message.open({
                    type: 'error',
                    content: TextViewer({text: 'error', returnString: true}),
                })
            }finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const onSaveClicked = async ({itemToSave})=>{
        const itemToSaveIfError = JSON.parse(JSON.stringify(itemToSave));

        try{
            await utils.updateUserAction(true);
        }catch (e){
            console.error('onSaveClicked - userId not updated in the cloud.');
            message.open({
                type: 'error',
                content: TextViewer({text: 'userId not updated', returnString: true}),
            });
            return;
        }

        try{
            setLoading(true);
            let userId = utils.getUserId();

            const body = {...itemToSave, userId};
            const response = await store.updatePrayer(body);

            setPrayerData(response)
            message.open({
                type: 'success',
                duration: 5,
                content: TextViewer({text: 'prayer_card_have_updated', returnString: true}),
            })
            localStorage.setItem("UPDATE_PRAYER", true);
        }catch (e){
            console.error('onSaveClicked - got error: ',e);
            message.open({
                type: 'error',
                content: TextViewer({text: 'error', returnString: true}),
            })
            setPrayerData(itemToSaveIfError);
        }finally {
            setLoading(false);
        }


    }
    const onCloseClicked = ()=>{
        history.push(`/${utils.getSynagogueName()}`);
    }

    if(loading) return <Loading/>

    return <div className={'personal-info-container'}>
        <PrayerDetails userMode={true} itemToRender={prayerData} onSaveClicked={onSaveClicked} onCloseClicked={onCloseClicked}/>
    </div>
}

export default PersonaInfo;