import React, {useState, useEffect} from 'react';
import './invoices.scss';
import Invoice from './Invoice/invoice';
import RenderInvoice from './RenderInvoice';
import Loading from "../../Main/Loading/Loading";
import TextViewer from "../../../../helpers/textViewer";
import add_yellow_circle_logo from "../../icons/add_yellow_circle.svg";
import click_logo from "../../icons/click.svg";
import '../PrayersDetails/PrayersDetails.scss';
import SingletoneStoreClass from "../../../../Store/Store";
import utils from "../../../../helpers/utils";
import SubscriptionModel from "../SubscriptionModel/subscriptionModel";



const store = SingletoneStoreClass.getInstance();


const Invoices = (props)=>{

    const { users, synagogueName, base64Logo } = props;
    const [invoices, setInvoices] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(props.loading || false);
    const [createNewInvoiceMode, setCreateNewInvoiceMode] = useState(false);

    const fetchData = async ()=>{
        setLoading(true);
        let allInvoices = await store.getInvoices();
        setInvoices(allInvoices);
        setLoading(false);
    };

    const filterInvoices = (array)=>{
        if(!search) return array;
        return array.filter(invoice=>{
            if(invoice.donorFullName.includes(search.trim())) return true;
            if(invoice.id == search) return true;
            if(invoice.amount == search) return true;
        })
    }



    useEffect(() => {
        const searchParams = window.location.search;

        if(searchParams.includes('?search=')){
            setSearch(decodeURI(searchParams.split('?search=')[1]))
        }

        fetchData();

    }, []);


    if(loading) return <Loading/>


    const blockAbility = ()=>{
        if(invoices.length <= 3) return null;
        if(['habani-rosh-hayain'].includes(utils.getSynagogueName())) return null;
        return <SubscriptionModel synagogueName={synagogueName} mode={'invoiceLimited'} />;
    }

    const onCreateInvoiceClicked = ()=>{
        setCreateNewInvoiceMode(!createNewInvoiceMode);
    }

    if(createNewInvoiceMode) return <Invoice setCreateNewInvoiceMode={setCreateNewInvoiceMode} users={users} synagogueName={synagogueName} fetchData={fetchData} base64Logo={base64Logo}/>

    return <React.Fragment>
        {blockAbility()}
        <div className={'list-details-container'}>
            <div className={'list-details-container-title'}>
                <TextViewer text={'invoices'}/>
                <input value={search} placeholder={' 🔎 חיפוש'} onChange={(event)=>setSearch(event.target.value)}/>
            </div>

            <div className={'add-item-button'} onClick={onCreateInvoiceClicked}>
                <img src={add_yellow_circle_logo} alt="icon"/>
            </div>

            <div className={'list-container'}>
                {filterInvoices(invoices).map((invoice)=>{
                    return <RenderInvoice invoice={invoice} base64Logo={base64Logo}/>
                })}
                {invoices.length === 0 ? <div className={'no-invoices-container'} onClick={()=>setCreateNewInvoiceMode(true)}>
                    <TextViewer text={'no_invoices'} className={'first'}/>
                    <img src={click_logo} alt="icon"/>
                    <TextViewer text={'click_here_to_create_first_invoice'}/>
                </div> : null}
            </div>
        </div>
    </React.Fragment>
}

export default Invoices