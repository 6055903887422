import React, {useEffect, useState} from 'react';
import './SetLanguage.scss';
import {ReactComponent as il_flag} from '../../icons/il-flag.svg';
import {ReactComponent as eng_flag} from '../../icons/english-flag.svg';
import {ReactComponent as set_lang_logo} from '../../icons/set-lanaguage.svg';
import Icon from "@ant-design/icons";
import utils from "../../../../helpers/utils";

const LANGUAGES = {
    hebrew: {
        name: 'hebrew',
        symbol: 'heb',
        flag: il_flag,
        txt: 'HEB'
    },
    english: {
        name: 'english',
        symbol: 'en',
        flag: eng_flag,
        txt: 'ENG'
    }
}

const SetLanguage = (props)=>{
    const [selectedLanguage, setSelectedLanguage] = useState(utils.getLanguage() === 'heb' ? LANGUAGES.hebrew.name : LANGUAGES.english.name);
    const [open, setOpen] = useState(false);

    document.addEventListener("mousedown", (event) => {
        if(event.target.className !== 'select-language-text' && !event.target.toString().includes('SVGCircleElement')){
            setOpen(false);
        }
    });

    document.addEventListener("scroll", (event) => {
        setOpen(false);
    });

    const onChooseLangClicked = (lang) =>{
        if(selectedLanguage === lang){
            setOpen(false);
            return;
        }

        if(window.localStorage){
            window.localStorage.setItem('APP_LANGUAGE', LANGUAGES[lang].symbol);
            setOpen(false);
            window.location.reload();
            return;
        }

        if(lang === LANGUAGES.hebrew.name){
            document.documentElement.style.setProperty('--app-direction', 'rtl');
            document.documentElement.style.setProperty('--app-text-align', 'right');
        }
        if(lang === LANGUAGES.english.name){
            document.documentElement.style.setProperty('--app-direction', 'ltr');
            document.documentElement.style.setProperty('--app-text-align', 'left');
        }

        setSelectedLanguage(lang);
        setOpen(false);
    }

    if(open){
        return <div className={'temp-nameeeee'}>
            <div className={'set-language-container'}>
                <div className={'select-language-container'} onClick={()=>onChooseLangClicked(LANGUAGES.hebrew.name)}>
                    <Icon className={"icon-flag-language-select"} component={LANGUAGES.hebrew.flag}/>
                    <span className={'select-language-text'}>{LANGUAGES.hebrew.txt}</span>
                </div>
                <div className={'select-language-container'} onClick={()=>onChooseLangClicked(LANGUAGES.english.name)}>
                    <Icon className={"icon-flag-language-select"} component={LANGUAGES.english.flag}/>
                    <span className={'select-language-text'}>{LANGUAGES.english.txt}</span>
                </div>
            </div>
            <div >
                <Icon className={"icon-flag-language"} component={set_lang_logo}/>
            </div>
        </div>
    }


    return <div onClick={()=>setOpen(true)}>
        <Icon className={"icon-flag-language"} component={set_lang_logo}/>
    </div>
}


export default SetLanguage;