const NezerAharonMap = {
    "1-Tishrei":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F1-Tishrei.jpg?alt=media&token=ed87bd34-e1d6-400d-8757-5ef12dfdc934"],
    "13-Tishrei":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F13-Tishrei.jpg?alt=media&token=4c763253-221a-4b7d-b873-ffab7218409c"],
    "14-Tishrei":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F14-Tishrei.jpg?alt=media&token=0734879c-3506-4671-b4c0-ba9e4d5e01cf"],
    "16-Tishrei":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F16-Tishrei.jpg?alt=media&token=7491fe77-6d76-4434-85fa-00a857a9498a"],
    "19-Tishrei":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F19-Tishrei.jpg?alt=media&token=c7fc42e2-1bee-49c8-9d56-872dc46d9310"],
    "20-Tishrei":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F20-Tishrei.jpg?alt=media&token=b8824a7c-89c3-45a1-be1f-cae0b2b3143a"],
    "22-Tishrei":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F22-Tishrei.jpg?alt=media&token=0aa6e82e-2c11-4a98-a7a5-d5ab0fa9c8f7"],
    "30-Tishrei":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F30-Tishrei.jpg?alt=media&token=4d099e6c-76ff-4543-bce8-c6d822dc4a45"],
    "7-Cheshvan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F7-Cheshvan.jpg?alt=media&token=802fe801-06ed-44ac-bb57-902fdd0c1cee"],
    "11-Cheshvan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F11-Cheshvan.jpg?alt=media&token=25f850cb-119e-4374-834b-5a83a72bb19b"],
    "23-Cheshvan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F23-Cheshvan.jpg?alt=media&token=09a9c3fa-5238-4c3d-9e92-76d5b007ef2d"],
    "26-Cheshvan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F26-Cheshvan.jpg?alt=media&token=6fcf9fab-cf38-4095-8e7a-bdea2429eeb9"],
    "27-Cheshvan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F27-Cheshvan.jpg?alt=media&token=e2f75c3f-6c8e-4842-8c80-1956676865f4"],
    "29-Cheshvan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F29-Cheshvan.jpg?alt=media&token=52f1302a-d3af-4d0d-bd42-b2d118f56c29"],
    "20-Kislev":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F20-Kislev.jpg?alt=media&token=cea1ac02-e3d3-4f36-9cd0-30d6cd3e1a22"],
    "21-Kislev":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F21-Kislev.jpg?alt=media&token=e3327375-1449-4004-a132-ce140d008aa4"],
    "24-Kislev":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F24-Kislev.jpg?alt=media&token=b182a17b-e8a9-44da-a92c-0024715d712d"],
    "26-Kislev":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F26-Kislev.jpg?alt=media&token=92216ad8-995d-4523-9f49-be77f5350594"],
    "27-Kislev":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F27-Kislev.jpg?alt=media&token=cc932252-0c61-4599-9962-12b08149675e"],
    "1-Tevet":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F1-Tevet.jpg?alt=media&token=90629f66-eef4-4e65-84a0-2b4764c80e1a"],
    "3-Tevet":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F3-Tevet.jpg?alt=media&token=f6ee0c18-a0ae-4243-8aa7-b695b1296520"],
    "4-Tevet":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F4-Tevet.jpg?alt=media&token=6d5602be-da47-4be8-8300-489082e6889e"],
    "13-Tevet":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F13-Tevet.jpg?alt=media&token=b4de070c-8c81-4846-9732-a41d4dd885d4"],
    "14-Tevet":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F14-Tevet.jpg?alt=media&token=fa93c0c6-968a-4207-9465-1a6c880342f5"],
    "6-Tevet":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F6-Tevet.jpg?alt=media&token=770be659-b596-4876-b9ef-4f1f0e22934d"],
    "1-Sh'vat": ["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F1-Sh'vat.jpg?alt=media&token=cfb758a2-2c6a-4929-a6d6-1958a25a8d6f"],
    "7-Sh'vat":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F7-Sh'vat.jpg?alt=media&token=f00e6987-5087-40e0-9c13-a8d7ff7f3324"],
    "9-Sh'vat":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F9-Sh'vat.jpg?alt=media&token=dcd371ff-d8a0-4db4-8830-d93b2f66d6f9"],
    "26-Sh'vat":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F26-Sh'vat.jpg?alt=media&token=56ae43c0-b59f-43ff-b308-f2c7ba97cadd"],
    "5-Adar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F5-Adar.jpg?alt=media&token=f5e10d69-2947-458e-bcb5-c308d0ff05a8"],
    "6-Adar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F6-Adar.jpg?alt=media&token=e2c73f95-569e-4e4c-aaaa-cbc0ee1a6e5f"],
    "9-Adar 1":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F9-Adar_1.jpg?alt=media&token=c760e9e2-86bc-4e61-8e55-b9697fe03869"],
    "9-Adar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F9-Adar_1.jpg?alt=media&token=c760e9e2-86bc-4e61-8e55-b9697fe03869"],
    '1-Adar 1': ['https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F1-Adar_1.jpg?alt=media&token=7d373bd3-0211-4ad9-a645-c4823f9abb1d'],
    '1-Adar': ['https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F1-Adar_1.jpg?alt=media&token=7d373bd3-0211-4ad9-a645-c4823f9abb1d'],
    "19-Adar 1":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F19-Adar_1.jpg?alt=media&token=8255ec5a-62dc-4502-a0a6-c4b97194b1e0"],
    "19-Adar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F19-Adar_1.jpg?alt=media&token=8255ec5a-62dc-4502-a0a6-c4b97194b1e0"],
    "11-Adar 2":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F11-Adar_2.jpg?alt=media&token=247f3167-b9a3-45fd-9e2a-a4b132621250"],
    "11-Adar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F11-Adar_2.jpg?alt=media&token=247f3167-b9a3-45fd-9e2a-a4b132621250"],
    "13-Adar 2":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F13-Adar_2.jpg?alt=media&token=b68e73cc-7433-42df-b4fb-184559f469c8"],
    "13-Adar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F13-Adar_2.jpg?alt=media&token=b68e73cc-7433-42df-b4fb-184559f469c8"],
    "16-Adar 2":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F16-Adar_2.jpg?alt=media&token=c1961130-aa28-4368-86e6-c27709435f0a"],
    "16-Adar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F16-Adar_2.jpg?alt=media&token=c1961130-aa28-4368-86e6-c27709435f0a"],
    "2-Adar 2":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F2-Adar_2.jpg?alt=media&token=6ab25724-8515-4326-8ddb-79c5735e1f90"],
    "2-Adar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F2-Adar_2.jpg?alt=media&token=6ab25724-8515-4326-8ddb-79c5735e1f90"],
    "20-Adar 2":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F20-Adar_2.jpg?alt=media&token=30a54dbd-6b5b-4450-8ce1-ca75e68cc3d4"],
    "20-Adar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F20-Adar_2.jpg?alt=media&token=30a54dbd-6b5b-4450-8ce1-ca75e68cc3d4"],
    "21-Adar 2":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F21-Adar_2.jpg?alt=media&token=290045c1-524f-4a95-9eda-71b2c0adb06f"],
    "21-Adar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F21-Adar_2.jpg?alt=media&token=290045c1-524f-4a95-9eda-71b2c0adb06f"],
    "24-Adar 2":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F24-Adar_2.jpg?alt=media&token=1c70bc60-a2b4-4fe0-ad8d-4e9c4ddfaf39"],
    "24-Adar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F24-Adar_2.jpg?alt=media&token=1c70bc60-a2b4-4fe0-ad8d-4e9c4ddfaf39"],
    "27-Adar 2":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F27-Adar_2.jpg?alt=media&token=6cc33b39-ebc0-4860-b6e1-a413a417efaf"],
    "27-Adar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F27-Adar_2.jpg?alt=media&token=6cc33b39-ebc0-4860-b6e1-a413a417efaf"],
    "3-Nisan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F3-Nisan.jpg?alt=media&token=e3c5aeb5-8382-429f-b8cf-8ae2aed4b736"],
    "4-Nisan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F4-Nisan.jpg?alt=media&token=19fb7ea3-abbd-46ac-a3bc-ca87d5cdb009"],
    "8-Nisan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F8-Nisan.jpg?alt=media&token=9f1aba82-5599-41e8-a7bd-40271937b102"],
    "11-Nisan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F11-Nisan.jpg?alt=media&token=7a5cc6a5-d9a2-4955-a5fb-6c5107c85320"],
    "13-Nisan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F13-Nisan.jpg?alt=media&token=abd2e86b-da3e-4de9-9977-5f278a808803"],
    "14-Nisan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F14-Nisan.jpg?alt=media&token=cdbf186c-1af1-48ca-8023-dc92162d2c5f"],
    "21-Nisan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F21-Nisan.jpg?alt=media&token=e5baf34a-5a23-4dca-a14b-baa7591020e0"],
    "22-Nisan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F22-Nisan.jpg?alt=media&token=ef35a338-c174-4fa4-ad71-2236de10876e"],
    "29-Nisan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F29-Nisan.jpg?alt=media&token=c34d2664-a870-4218-9a2c-407364933aef"],
    "30-Nisan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F30-Nisan.jpg?alt=media&token=9bde484f-155b-49c3-8975-cdfc1609ab69"],
    "11-Iyyar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F11-Iyyar.jpg?alt=media&token=7ef17d43-e0fe-4160-8bf4-d014c834c578"],
    "2-Iyyar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F2-Iyyar.jpg?alt=media&token=a0b95eef-1532-4d59-be69-c9de324315ae"],
    "20-Iyyar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F20-Iyyar.jpg?alt=media&token=15aee4a2-c385-4647-9949-a3f74623e772"],
    "23-Iyyar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F23-Iyyar.jpg?alt=media&token=1ec4f747-2bc4-438e-8b82-31d4b00178c2"],
    "24-Iyyar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F24-Iyyar.jpg?alt=media&token=2fba594b-952b-498b-a6ae-6166a6983870"],
    "25-Iyyar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F25-Iyyar.jpg?alt=media&token=3b0efc03-e900-421a-9541-3fec40644b31"],
    "26-Iyyar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F26-Iyyar.jpg?alt=media&token=2c064922-5a21-41ee-91f1-e024edbc5fd1"],
    "27-Iyyar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F27-Iyyar.jpg?alt=media&token=cf7d2118-960b-4ce5-809f-e3a38795f38e"],
    "28-Iyyar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F28-Iyyar.jpg?alt=media&token=b05ec18f-467a-46b0-935f-f53b0eef78c4"],
    "29-Iyyar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F29-Iyyar.jpg?alt=media&token=cb426bdb-15d1-4068-920c-27953d7e15c9"],
    "3-Iyyar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F3-Iyyar.jpg?alt=media&token=b8e1a2c7-bcd0-4881-9e20-f1f8d8497734"],
    "4-Iyyar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F4-Iyyar.jpg?alt=media&token=554346ad-ebc9-4985-b523-f6abdf89714c",
                "https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F4b-Iyyar.jpg?alt=media&token=de57996c-e66a-4d26-9957-f365c47ab00b",
                "https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F4c-Iyyar.jpg?alt=media&token=ce22932c-b24b-470b-8c59-17fa595f960d",
                "https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F4d-Iyyar.jpg?alt=media&token=619becef-850a-4f44-873f-30df836669f6",
                "https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F4e-Iyyar.jpg?alt=media&token=1d44a02f-c129-42c9-89d7-cc7de244f661",],
    "5-Iyyar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F5-Iyyar.jpg?alt=media&token=b64b1d0c-49f2-4c79-8d94-f146acabd8aa"],
    "6-Iyyar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F6-Iyyar.jpg?alt=media&token=7b6f513f-a3cd-4b46-a830-288abd71ac88"],
    "7-Iyyar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F7-Iyyar.jpg?alt=media&token=9f0b674d-8d1e-4a32-b6c3-77c9e8cb6038"],
    "9-Iyyar":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F9-Iyyar.jpg?alt=media&token=af0958b8-97a5-4dc9-abe2-e9264de31d26"],
    "10-Sivan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F10-Sivan.jpg?alt=media&token=79e3fd20-506b-435f-8500-25aa2dbf3cd9"],
    "14-Sivan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F14-Sivan.jpg?alt=media&token=cf4c4fce-d76b-4e4e-b5b4-4888e321c80a"],
    "15-Sivan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F15-Sivan.jpg?alt=media&token=53b6e5e0-27d3-4c20-99e6-47ea633a68dd"],
    "2-Sivan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F2-Sivan.jpg?alt=media&token=ae00fe62-09b0-4e5f-beed-2fbd1c29eae1"],
    "20-Sivan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F20-Sivan.jpg?alt=media&token=2fdcd1a6-b10d-4519-96e9-989fbf23aadb"],
    "22-Sivan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F22-Sivan.jpg?alt=media&token=79446d0a-44fa-4efc-a22d-c6c71d547136"],
    "27-Sivan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F27-Sivan.jpg?alt=media&token=3451c8ad-d0be-4fc1-a950-ececb76d61d2"],
    "3-Sivan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F3-Sivan.jpg?alt=media&token=c4bbf443-9b38-4943-9d2d-721fd232dffd"],
    "4-Sivan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F4-Sivan.jpg?alt=media&token=a6edcd97-f9c6-4e31-9b59-5f7dbcca0c9c"],
    "5-Sivan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F5-Sivan.jpg?alt=media&token=5a11f523-f31e-4e45-9c2a-56ffa539b319"],
    "8-Sivan":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F8-Sivan.jpg?alt=media&token=a60a4da5-ef5f-42c9-9142-c450b3ba975a"],
    "10-Tamuz":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F10-Tamuz.jpg?alt=media&token=a8c2188a-6174-45a1-bbae-09c1b0cc8021"],
    "11-Tamuz":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F11-Tamuz.jpg?alt=media&token=947dbda1-3f40-4c74-a298-35276b933c0e",
                "https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F11b-Tamuz.jpg?alt=media&token=a2c48916-45c3-498a-a791-96607940ef58",
                "https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F11c-Tamuz.jpg?alt=media&token=60b6eee7-1d93-461a-8641-7848ad2a607d",],
    "18-Tamuz":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F18-Tamuz.jpg?alt=media&token=9bf8319f-ae9b-45bf-9313-a134bacaf6fd"],
    "20-Tamuz":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F20-Tamuz.jpg?alt=media&token=f992ab71-61a0-429c-bb50-4a9a411c80d0"],
    "22-Tamuz":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F22-Tamuz.jpg?alt=media&token=e1418ccf-ef94-456f-8f0d-e5d45eb4b54d"],
    "3-Tamuz":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F3-Tamuz.jpg?alt=media&token=940d32ba-79c7-4846-b6ff-84a1947ca9d2"],
    "4-Tamuz":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F4-Tamuz.jpg?alt=media&token=c55ef5d9-4742-4e95-91b5-f31be41665d4"],
    "5-Tamuz":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F5-Tamuz.jpg?alt=media&token=a346ba17-351c-4336-a40c-7cdd8f60409d"],
    "8-Tamuz":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F8-Tamuz.jpg?alt=media&token=ff03461a-6383-47f8-a147-980696f31ce5"],
    "9-Tamuz":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F9-Tamuz.jpg?alt=media&token=d3dd5dc7-957e-417c-a9f3-be592ab1695c"],
    "10-Av":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F10-Av.jpg?alt=media&token=47b8dc5a-d2a2-4d09-aef0-95786a010c78"],
    "14-Av":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F14-Av.jpg?alt=media&token=07e49101-6bec-43c5-959c-7dc270485198"],
    "21-Av":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F21-Av.jpg?alt=media&token=37d698b0-b8f1-4352-ad96-60f789de8524"],
    "22-Av":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F22-Av.jpg?alt=media&token=68893e82-240c-4488-b08e-12039004f058"],
    "24-Av":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F24-Av.jpg?alt=media&token=dae84fcb-68a4-4b7b-a094-0a61814bacc9"],
    "27-Elul":["https://firebasestorage.googleapis.com/v0/b/synagogue-app.appspot.com/o/NezerAharon%2F27-Elul.jpg?alt=media&token=762b6cc7-dd7e-4f99-95b7-1dd633d50c4a"]
}

export default NezerAharonMap;