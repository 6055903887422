import React, {useState, useEffect} from 'react';
import './synagogueDetails.scss'
import Loading from "../../Main/Loading/Loading";
import TextViewer from "../../../../helpers/textViewer";
import {useHistory} from "react-router-dom";
import UploadAttachment from "../UploadAttachment/uploadAttachments";
import config from '../../../../config';
import {message} from "antd";
import utils from "../../../../helpers/utils";
message.config({
    top: '90%',
    duration: 2,
    maxCount: 3,
    rtl: true
});
/**
 * SynagogueDetails object:
 */

const arrayRange = (start, stop, step) =>
    Array.from(
        { length: (stop - start) / step + 1 },
        (value, index) => start + index * step
    );

const SYNAGOGUE_DETAILS_CONFIG = {
    name : {
       keyName: 'name',
       text_view_name: 'synagogue_name',
       required: true,
       placeholder: ''
    },
    info :{
       keyName: 'info',
       type: 'textarea',
       text_view_name: 'synagogue_story',
       required: false,
       placeholder: 'synagogue_story_placeholder'
    },
    shabatEnterMinBeforeSunset: {
        keyName: 'shabatEnterMinBeforeSunset',
        text_view_name: 'shabat_enter_min_before_sunset',
        type: 'select',
        options:  arrayRange(0, 60, 1),
        required: false,
        defaultValue: config.defaultShabatEnterMinBeforeSunset
    },
    shabatExitMinAfterStarsCameOut: {
        keyName: 'shabatExitMinAfterStarsCameOut',
        text_view_name: 'shabat_exit_min_after_stars_came_out',
        type: 'select',
        options:  arrayRange(0, 60, 1),
        required: false,
        defaultValue: config.defaultShabatExitMinAfterStarsCameOut
    },
    useYeshivaTimes: {
        keyName: 'useYeshivaTimes',
        text_view_name: 'calc_shabat_times_yeshiva',
        type: 'selectYeshivaTimes',
        required: false,
    },
    base64Logo :{
       keyName: 'base64Logo',
       type: 'uploadFile',
       text_view_name: 'upload_synagogue_logo',
       required: false,
       placeholder: '',
       base64Mode: true
    },
    base64Photo :{
        keyName: 'base64Photo',
        type: 'uploadFile',
        text_view_name: 'upload_synagogue_photo',
        required: false,
        placeholder: '',
        base64Mode: true
    },
    synagogueAddressString : {
        keyName: 'synagogueAddressString',
        text_view_name: 'synagogue_address',
        required: false,
        placeholder: ''
    },
    phoneNumber : {
        keyName: 'phoneNumber',
        text_view_name: 'synagogue_phone_number',
        type: 'tel',
        required: false,
        placeholder: ''
    },
    synagogueEmailAddress : {
        keyName: 'synagogueEmailAddress',
        text_view_name: 'synagogue_email_address',
        required: false,
        placeholder: 'my_synagogue@gmail.com'
    },
    websiteUrl : {
        keyName: 'websiteUrl',
        text_view_name: 'link_to_external_web_page',
        required: false,
        placeholder: 'my_synagogue.co.il'
    },
    facebookUrl : {
        keyName: 'facebookUrl',
        text_view_name: 'link_to_facebook',
        required: false,
        placeholder: 'https://www.facebook.com/groups/XXXXX'
    },
    synagogueYoutubeUrl : {
        keyName: 'synagogueYoutubeUrl',
        text_view_name: 'link_to_youtube',
        required: false,
        placeholder: 'https://www.youtube.com/watch?v=XXXXX'
    },
    externalFormDetails : {
        keyName: 'externalFormDetails',
        text_view_name: 'external_form_url_description',
        required: false,
        placeholder: ''
    },
    externalFormUrl : {
        keyName: 'externalFormUrl',
        text_view_name: 'link_to_external_form_url',
        required: false,
        placeholder: 'https://docs.google.com/forms/ID/viewform?usp=sf_link',
        preview: 'iframe'
    },
    donationUrl : {
        keyName: 'donationUrl',
        text_view_name: 'donation_url',
        required: false,
        placeholder: 'https://awesomeco.givingfuel.com/sample-page'
    },
    donationExtra : {
        keyName: 'donationExtra',
        text_view_name: 'donation_free_text',
        type: 'textarea',
        required: false,
        placeholder: ''
    },
    donationPaybox : {
        keyName: 'donationPaybox',
        text_view_name: 'donation_paybox',
        required: false,
        placeholder: 'https://payboxapp.page.link/XXXXXXXXX'
    },
}

const SynagogueDetails = (props)=>{
    const { loading, location, cityName, name, info, donation, extraDetails = {}, updateData, updateAllExtraDetails } = props;
    const history = useHistory();
    const [itemToSave, setItemToSave] = useState({info, name, ...extraDetails});

    useEffect(() => {
        console.log("SynagogueDetails: ",{itemToSave, name, info, extraDetails});
        setItemToSave({info: props.info, name: props.name, donationUrl: donation?.url, donationExtra: donation?.extra, donationPaybox: donation?.paybox, ...props.extraDetails})
    }, [props]);


    // useEffect(() => {
    //     console.log("SynagogueDetails: ",{itemToSave, name, info, extraDetails});
    // }, [itemToSave, name, info, extraDetails]);

    const renderFields = ()=>{
        return Object.keys(SYNAGOGUE_DETAILS_CONFIG).map(key=>{
            const {keyName, type, text_view_name, required, placeholder, base64Mode, preview, options, defaultValue} = SYNAGOGUE_DETAILS_CONFIG[key];

            const onTextInputChange = (event)=>{
                // console.log("--------event.target.value=",event.target.value)
                setItemToSave({...itemToSave, [keyName] : event.target.value})
            }

            const onUploadFileChange = (data)=>{
                setItemToSave({...itemToSave, [keyName] : data})
            }

            const onCheckboxChange = (event)=>{
                setItemToSave({...itemToSave, [keyName] : event.target.checked});
            }

            const showPreview = ()=>{
                if(!itemToSave[key]) return;
                 return <div className={'external-url-iframe-container'}>
                     <TextViewer text={'preview'} addChar={':'}/>
                    <iframe
                        // onLoad={()=>setIframeLoading(false)}
                        src={utils.getExternalUrl(itemToSave[key])}
                        frameBorder="0" marginHeight="0" marginWidth="0">Loading…
                    </iframe>
                </div>
            }
            let input;
            switch (type) {
                case 'selectYeshivaTimes':
                    input = <select onChange={onTextInputChange} value={itemToSave[key]}>
                        <option value="">{TextViewer({text: 'automate_calc', returnString: true})}</option>
                        {Object.keys(config.yeshivaTimes).map(keyCityName=> <option value={config.yeshivaTimes[keyCityName].eng}>{config.yeshivaTimes[keyCityName].heb}</option>)}
                    </select>
                    break;
                case 'tel':
                    input = <input type={"tel"} placeholder={"0541234567"} onChange={(event)=>onTextInputChange(event, (num)=>num.length <= 10 && !isNaN(num))} value={itemToSave[key]}/>
                    break;
                case 'uploadFile':
                    input = <UploadAttachment onTextChange={onUploadFileChange} attachmentUrl={itemToSave[key]} base64Mode={base64Mode}/>
                    break;
                case 'checkbox':
                    input = <input type={'checkbox'} onChange={onCheckboxChange} checked={itemToSave[key]}/>
                    break;
                case 'textarea':
                    input = <textarea rows={'5'} onChange={onTextInputChange} value={itemToSave[key]}/>
                    break;
                case 'select':
                    input = <select onChange={onTextInputChange} value={itemToSave[key] || defaultValue}>
                        {options.map(option=><option value={option}>{option}</option>)}
                    </select>
                    break;
                default:
                    input = <input onChange={onTextInputChange} value={itemToSave[key]} placeholder={TextViewer({text: placeholder, returnString: true})}/>
            }

            return <div className={'synagogue-details-edit-text'}>
                <TextViewer text={text_view_name} addChar={required ? '* ' : ''} addBefore/>
                {input}
                {preview ? showPreview() : null}
            </div>
        })
    }



    if(loading) return <Loading/>

    const isEnabledToSaved = ()=>{
        const hasChanged =  JSON.stringify({info, name, donationUrl: donation?.url, donationExtra: donation?.extra, donationPaybox: donation?.paybox, ...extraDetails}) !== JSON.stringify(itemToSave);
        return hasChanged
    }

    const onSaveClicked = async ()=>{
        await updateData({fieldName: 'name', data: itemToSave.name});
        await updateData({fieldName: 'info', data: itemToSave.info});
        await updateData({fieldName: 'donation', data: {url: itemToSave.donationUrl, extra: itemToSave.donationExtra, paybox: itemToSave.donationPaybox}});
        const copyOfItemToSave = {...itemToSave};
        delete copyOfItemToSave.name;
        delete copyOfItemToSave.info;
        delete copyOfItemToSave.donationUrl;
        delete copyOfItemToSave.donationExtra;
        delete copyOfItemToSave.donationPaybox;
        await updateAllExtraDetails({data: copyOfItemToSave});
        message.open({
            type: 'success',
            content: TextViewer({text: 'saved', returnString: true}),
        });
    }

    return <div className={'synagogue-details-edit-container'}>
        <TextViewer text={'synagogue_details'}/>
        <div className={"key-value-details"}>
            <span><TextViewer text={'city'} addChar={": "}/></span>
            <span>{cityName}</span>
        </div>
        <div className={"key-value-details"}>
            <span><TextViewer text={'location'} addChar={": "}/></span>
            <a href={`http://www.google.com/maps/place/${location.latitude},${location.longitude}`}
               target="_blank" rel="noopener noreferrer">{location.latitude}/{location.longitude}</a>
        </div>
        {renderFields()}
        <div className={'bottom-buttons'}>
            <button className={'bottom-buttons-save'} disabled={!isEnabledToSaved()} onClick={onSaveClicked}>{<TextViewer text={'save'}/>}</button>
            <button className={'bottom-buttons-back'}  onClick={()=>{
                history.push({
                    hash: 'main'
                });
            }}><TextViewer text={'return'}/></button>
        </div>
    </div>
}

export default SynagogueDetails;