import React, {useEffect, useState} from 'react';
import './PlasmaHeader.scss';
import Clock from 'react-live-clock';
import SingletoneStoreClass from "../../../../../../Store/Store";
import {ReactComponent as black_main_logo} from "../../../../icons/black_main_logo.svg";
import Icon from "@ant-design/icons";
import TextViewer from "../../../../../../helpers/textViewer";
import moment from "moment";

const store = SingletoneStoreClass.getInstance();

const daysToAlpha = {
    0: "sunday",
    1: "monday",
    2: "tuesday",
    3: "wednesday",
    4: "thursday",
    5: "friday",
    6: "saturday",
};

const PlasmaHeader = (props)=>{
    const { base64Logo, location } = props;
    const currentDate = new Date();

    return <div className={'plasma-header-container'}>
        <div className={'header-time'}>
            <div className={'header-time-clock'}><Clock format="HH:mm" interval={1000} ticking={true}/></div>
            <div className={'header-time-hebrew-date'}>{store.getHebrewDate({date: currentDate, location})}</div>
            <div className={'header-time-date'}> <TextViewer text={daysToAlpha[currentDate.getDay()]} addedString={moment(currentDate).format("DD.MM.YYYY")} addChar={','} /></div>
        </div>
        <div className={'header-icon'}>
            {base64Logo ? <img src={base64Logo} alt="avatar"/> : <Icon component={black_main_logo}/>}
        </div>
    </div>
}

export default PlasmaHeader;