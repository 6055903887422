import SingletoneStoreClass from "../../../../../Store/Store";

const MAX_RANGE_TO_SHOW = 14;

const store = SingletoneStoreClass.getInstance();

const getDateRanges = ()=>{
    const currentDate = new Date();
    const datesToShow = [];

    for(let i = MAX_RANGE_TO_SHOW; i > 0; i--){
        datesToShow.push(new Date(new Date().setDate(currentDate.getDate() - i)));
    }
    datesToShow.push(currentDate);
    for(let i = 1; i < MAX_RANGE_TO_SHOW; i++){
        datesToShow.push(new Date(new Date().setDate(currentDate.getDate() + i)));
    }

    //for DEBUG ONLY! // buildHebrewDateMap();

    return {datesToShow, currentDate};
}

// const buildHebrewDateMap = ()=>{
//     const DAYS_TO_CALC = 1000;
//
//     let date = new Date();
//     const occasionsMap = {};
//
//     for(let i=0; i < DAYS_TO_CALC; i++){
//         const {monthName, dayOfMonth} = store.getHebrewDayAndMouthAndYear({date});
//         if(!occasionsMap[monthName]){
//             occasionsMap[monthName] = {}
//         }
//         if(!occasionsMap[monthName][dayOfMonth]){
//             occasionsMap[monthName][dayOfMonth] = {}
//         }
//
//         const hebcal = store.getHebcalDate(date);
//         hebcal.holidays().map(a=>a.getDesc("h"))
//         occasionsMap[monthName][dayOfMonth].text = hebcal.holidays().map(a=>a.getDesc("h")).join('.')
//         date.setDate(date.getDate() + 1);
//
//
//     }
//
//     console.log("occasionsMap=",occasionsMap)
//     console.log(JSON.stringify(occasionsMap))
//
// }

export default getDateRanges;

