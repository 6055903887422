import './unisyn.scss';
import config from '../../../config';
import {ReactComponent as WebsiteIcon} from '../../v2/icons/website-icon.svg';
import {ReactComponent as WhatsappIcon} from '../../v2/icons/whatsapp-icon.svg';
import {ReactComponent as PhoneIcon} from '../../v2/icons/phone-icon.svg';
import {ReactComponent as UnisyIcon} from '../../v2/icons/unisy-logo-no-text.svg';
import {analyticsAddLogEvent} from "../../../firebase/firebase";

import axios from 'axios';
import React, {useEffect, useState} from 'react';
import utils from '../../../helpers/utils';
import SingletoneStoreClass from "../../../Store/Store";

const store = SingletoneStoreClass.getInstance();

const {isMobile, sendWhatsAppMessage, smartStringRenderLinks} = utils;

const links = [
    {
        icon: <WebsiteIcon className={'website-icon'}/>,
        text: 'אתר אינטרנט',
        onClick: ()=>{
            window.open('https://www.unisyn.org.il', '_blank').focus();
        }
    },
    {
        icon: <PhoneIcon className={'phone-icon'}/>,
        text: 'טלפון',
        onClick: ()=>{
            window.open('tel:026259516', '_blank').focus();
        }
    },
    {
        icon: <WhatsappIcon className={'button-link-icon'}/>,
        text: 'וואצפ',
        onClick: ()=> {
            isMobile() ? sendWhatsAppMessage({msg: `היי איחוד בתי הכנסת !`, phoneNumber: `+972559907684`}) : window.open(`https://api.whatsapp.com/send?phone=+972559907684`, '_blank').focus();
        }
    }
]

const Unisyn = (props)=>{
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [data, setData] = useState(null);


    useEffect( () => {
        const getData = async ()=>{
            try{
                setData(await store.getUnisynData());
                setHasError(false);
            }catch (e){
                setHasError(true);
                throw e;
            }finally {
                setLoading(false);
            }
        }
        getData();
        analyticsAddLogEvent({eventName: 'unisyn_event', params: { name: utils.getSynagogueName()}});

    }, []);


    const renderDynamicData = ()=>{
        if(loading) return <div>טוען...</div>
        if(hasError) return <div>שגיאה!</div>
        return <div className={'dynamic-data-ontainer'}>
            <div className={'dynamic-data-title'}>{data.title}</div>
            <div className={'dynamic-data-sub-title'}> {data.subTitle}</div>
            <div className={'dynamic-data-body'} dangerouslySetInnerHTML={{ __html: smartStringRenderLinks(data.bodyMessage) }}></div>
        </div>
    }




    return <div>
        <div className={'unisyn-container'}>
            <div className={'unisny-icon'}>
                <UnisyIcon />
            </div>
            {renderDynamicData()}
            <div className={'button-links-container'}>
                {links.map(linkObj=><div className={'button-link-container'} onClick={linkObj.onClick}>
                    <div className={'button-links-circle'}>{linkObj.icon}</div>
                    <div className={'button-link-text'}>{linkObj.text}</div>
                </div>)}
            </div>
        </div>
    </div>
}

export default Unisyn;