import React, {useState, useEffect} from 'react';
import './MakePushNotification.scss';
import TextViewer from "../../../../../helpers/textViewer";
import SingletoneStoreClass from "../../../../../Store/Store";

const store = SingletoneStoreClass.getInstance();


const MakePushNotification = (props)=>{

    const [title, seTitle] = useState(TextViewer({text: 'default_push_notification_title', returnString: true}));
    const [body, setBody] = useState(TextViewer({text: 'default_push_notification_body', returnString: true}));
    const [loading, setLoading] = useState(false);
    const [successCounter, setSuccessCounter] = useState(null);

    const onTitleChange = (event)=>{
        seTitle(event.target.value);
    }

    const onBodyChange = (event)=>{
        setBody(event.target.value);
    }

    const onSendClicked = async ()=>{
        const text = TextViewer({text: 'are_u_sure_push_notifications', addChar: '?', returnString: true});

        if (window.confirm(text) == true) {
            try{
                setLoading(true)
                console.log("send....")
                const response = await store.makePushNotifications({title, body});
                console.log("----response=",response);
                setSuccessCounter(response.success)
            } catch (e){
                console.log('got error while pushing notifications: ',e);
                throw e;
            } finally {
              setLoading(false)
            }
        }
    }

    const showSuccess = ()=>{
        let text = TextViewer({text: 'has_successfully_sent', addChar: '.', returnString: true});
        return <span className={'success'}>{text.replace('X', successCounter)}</span>
    }

    return <div className={'make-push-notification'}>
        <TextViewer text={'push_notifications'} className={'title'}/>
        <input onChange={onTitleChange} value={title}/>
        <textarea rows={'2'} onChange={onBodyChange} value={body}/>
        <button  onClick={onSendClicked} disabled={!title.trim() || !body.trim() || loading}> <TextViewer text={'send'} addChar={'!'}/></button>
        {loading ? <TextViewer text={'sending'} addedString={'...'}/> : null}
        {typeof successCounter === 'number' ? showSuccess() : null}
    </div>
}

export default MakePushNotification;