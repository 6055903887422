import React, {useEffect, useState} from 'react';
import './SideMenu.scss';
import TextViewer from "../../../../helpers/textViewer";
import {ReactComponent as man} from "../../icons/man.svg";
import Icon from "@ant-design/icons";
import {Link, useHistory} from 'react-router-dom';
import utils from "../../../../helpers/utils";
import config from '../../../../config';
import {analyticsAddLogEvent} from "../../../../firebase/firebase";
import SingletoneStoreClass from "../../../../Store/Store";

const store = SingletoneStoreClass.getInstance();

const SideMenu = (props) => {

    const onShareClicked = async () => {
        if (navigator.share) await utils.shareApp();
    };
    const onFeedbackClick = () => {
        utils.feedbackApp();
    };
    const { open, setOpen, plasmaMode } = props;

    const [fullScreenOn, setFullScreenOn] = useState(false);

    const synagogueName = utils.getSynagogueName();

    useEffect(() => {
        window.addEventListener("fullscreenchange", ()=>{
            if(document.fullscreenElement) setFullScreenOn(true)
            else setFullScreenOn(false)
        });
    }, []);

    document.addEventListener("mousedown", (event) => {
        if(event.target.className && typeof event.target.className === 'string' && event.target.className.includes('side-menu')){
            return;
        }
        if(event.target.className && event.target.className.toString().includes('SVGAnimatedString')){
            return;
        }
        setOpen(false);
    });


    const history = useHistory();

    const isManagementOn = utils.isManagementOn();

    const renderRegularButtons = ()=>{
        return <div className={'top-side-menu-container'}>
            { synagogueName ? <Link>
                <div className={'top-side-menu-item'} onClick={()=>{
                    analyticsAddLogEvent({eventName: 'full_screen_event', params: { name: utils.getSynagogueName()}});
                    setOpen(false);
                    setFullScreenOn(!fullScreenOn)
                    fullScreenOn ? utils.closeFullscreen() : utils.openFullscreen()}}>
                    <TextViewer text={`full_screen_${fullScreenOn ? 'off' : 'on'}`} className={'side-menu-item-text'}/>
                </div>
            </Link> : null }
            <Link to={`/`}>
                <div className={'top-side-menu-item'} onClick={()=>{
                    setOpen(false);
                    utils.logOut();
                    setTimeout(()=>window.location.reload(), 10)
                }}>
                    <TextViewer text={"about_the_app"} className={'side-menu-item-text'}/>

                </div>
            </Link>
            <Link to={'#'}><div className={'top-side-menu-item'} onClick={()=>{
                setOpen(false);
                onFeedbackClick();
            }}>
                <TextViewer text={"feedback"} className={'side-menu-item-text'}/>
            </div></Link>
            <Link to={'#'}><div className={'top-side-menu-item'} onClick={()=>{
                setOpen(false);
                onShareClicked();
            }}>
                <TextViewer text={"share_with_friends"} className={'side-menu-item-text'}/>
            </div></Link>
            {synagogueName ? <Link to={`/${synagogueName}/gematria`} onClick={()=>{
                setOpen(false);
            }}>
                <div className={'top-side-menu-item'}>
                    <TextViewer text={"gematria_calculator"} className={'side-menu-item-text'}/>
                </div>
            </Link> : null}
            {plasmaMode ? <Link to={'#'} onClick={()=>{
                window.location.reload();
            }}>
                <div className={'top-side-menu-item'}>
                    <TextViewer text={"upload_version"} className={'side-menu-item-text'}/>
                </div>
            </Link> : null}
            {/*{utils.getMobileOperatingSystem() !== 'IOS' ? <Link to={'#'}><div className={'top-side-menu-item'} onClick={()=>{*/}
            {/*    analyticsAddLogEvent({eventName: 'android_app_link_event', params: { name: utils.getSynagogueName()}});*/}
            {/*    setOpen(false);*/}
            {/*    window.open(config.androidLink, '_blank').focus();*/}

            {/*}}>*/}
            {/*    <TextViewer text={"android_download"} className={'side-menu-item-text'}/>*/}
            {/*</div></Link> : null}*/}
            {synagogueName ? <Link to={`/${synagogueName}/jerusalem-compass`} onClick={()=>{
                setOpen(false);
            }}>
                <div className={'top-side-menu-item'}>
                    <TextViewer text={"automate_tfila_direction"} className={'side-menu-item-text'}/>
                </div>
            </Link> : null}
            {synagogueName ? <Link to={`/${synagogueName}/azkara-order`} onClick={()=>{
                setOpen(false);
            }}>
                <div className={'top-side-menu-item'}>
                    <TextViewer text={"azkara_order"} className={'side-menu-item-text'}/>
                </div>
            </Link> : null}
            {synagogueName ? <Link to={`/${synagogueName}/hebrew-date`} onClick={()=>{
                setOpen(false);
            }}>
                <div className={'top-side-menu-item'}>
                    <TextViewer text={"hebrew_dates_manager"} className={'side-menu-item-text'}/>
                </div>
            </Link> : null}
            {synagogueName ? <Link to={`/${synagogueName}/kids`} onClick={()=>{
                setOpen(false);
            }}>
                <div className={'top-side-menu-item'}>
                    <TextViewer text={"games"} className={'side-menu-item-text'}/>
                </div>
            </Link> : null}
            {synagogueName ? <Link to={`/${synagogueName}/personal_info`} onClick={()=>{
                setOpen(false);
            }}>
                <div className={'top-side-menu-item'}>
                    <TextViewer text={"update_personal_info"} className={'side-menu-item-text'}/>
                </div>
            </Link> : null}
            <Link to={`/#signup`}>
                <div className={'top-side-menu-item'} onClick={()=>{
                    setOpen(false);
                    setTimeout(()=>window.location.reload(`${window.location.origin}/#signup`), 10)
                }}>
                    <TextViewer text={"create_new_tenant"} className={'side-menu-item-text'}/>

                </div>
            </Link>

        </div>
    }

    const renderManagementButtons = ()=>{

        const renderButton = (name)=>{
            return <div onClick={()=>{
                history.push({
                    hash: name
                });
                setOpen(false);
            }}>
                <a><div className={'top-side-menu-item'}>
                    <TextViewer text={name} className={'side-menu-item-text'}/>
                </div></a>
            </div>
        }

        return <div className={'top-side-menu-container'}>
            {renderButton('main')}
            {renderButton('times')}
            {renderButton('synagogue_details')}
            {renderButton('prayers_details')}
            {renderButton('aliyah_panel')}
            {renderButton('messages_and_notifications')}
            {renderButton('donations')}
            {renderButton('dvar_tora')}
            {renderButton('plasma_setting')}
            {renderButton('reportsv2')}
            {renderButton('invoices')}
            <div className={'top-side-menu-item'} onClick={ utils.feedbackManagementV2 }>
                <TextViewer text={" שלח פידבק 💬"} className={'side-menu-item-text'}/>
            </div>
        </div>
    }


    return <div className={`side-menu-container ${open ? 'open' :''} ${isManagementOn ?' management-on' : ''}`} id={'side-menu-dots'}>
        <div className={'side-menu-app-version'}>
            App Version: {config.appVersion}
        </div>
        {isManagementOn ? renderManagementButtons() : renderRegularButtons()}
        <div className={'bottom-side-menu-container'}>
            {isManagementOn ? <div>
                <div className={'top-side-menu-item'}>
                    <div className={'side-menu-gabbai-icon-container'}>
                        <Icon className={"side-menu-gabbai-icon"} component={man}/>
                    </div>
                    <div className={'side-menu-gabbai-divider'}></div>
                    <TextViewer text={"the_gabbai"} addChar={`${store.getUserName()} `} addBefore className={'side-menu-item-text'} id2={'management-bottom-text'} />
                </div>
                <Link to={`/${utils.getSynagogueName()}`}>
                    <div className={'top-side-menu-item'} onClick={()=>{
                        setOpen(false);
                    }}>
                        <TextViewer text={"disconnect"} className={'side-menu-item-text'}/>
                    </div>
                </Link>
            </div> : synagogueName ? <Link to={`/${utils.getSynagogueName()}/edit`}>
                <div className={'top-side-menu-item'} onClick={()=>{
                    setOpen(false);
                    const sideMenu = document.getElementById('side-menu-dots');
                    sideMenu.className = 'side-menu-container'
                }}>
                    <div className={'side-menu-gabbai-icon-container'}>
                        <Icon className={"side-menu-gabbai-icon"} component={man}/>
                    </div>
                    <div className={'side-menu-gabbai-divider'}></div>
                    <TextViewer text={"gabbai_area"} className={'side-menu-item-text'} id={'side-menu-item-text-gabbai-area'}/>
                </div>
            </Link> : null}

        </div>

    </div>

}

export default SideMenu;