import React, {useEffect, useState} from 'react';
import './PlasmaMainTitle.scss';

const PlasmaMainTitle = (props)=>{
    const {title} = props;
    return <div className={'plasma-main-title-container'}>
        {title}
    </div>
}

export default PlasmaMainTitle;