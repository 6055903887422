import React, {useEffect, useState} from 'react';
import './PersonalHebrewDatesManager.scss'
import TextViewer from "../../../helpers/textViewer";
import add_yellow_circle_logo from "../icons/add_yellow_circle.svg";
import config from '../../../config';
import PHDMConfig from './config';
import PersonalHebrewDatesManagerItem from './PersonalHebrewDatesManagerItem/PersonalHebrewDatesManagerItem';
import Loading from "../Main/Loading/Loading";

const { EVENT_TYPES } = PHDMConfig;
const { hebrewNumToDay, hebrewNumToMonth } = config;


class HebrewDatesManager {
    constructor() {
        const eventsFromLocalStorage = window.localStorage.getItem('hebrewDateEvents');
        if (eventsFromLocalStorage){
            this.events = JSON.parse(eventsFromLocalStorage);
        }else{
            this.events = [];
        }
    }

    saveEvents(){
        window.localStorage.setItem('hebrewDateEvents', JSON.stringify(this.events));
    }

    saveEvent({index, event}){
        this.events[index] = {...event};
        this.saveEvents();
    }

    deleteEvent({index}){
        this.events[index] = null;
        this.events = JSON.parse(JSON.stringify(this.events.filter(event=>event)));
        this.saveEvents();
    }


    addEvent(){
        const defaultEvent = {
            type: EVENT_TYPES.BIRTHDAY.id,
            name: 'שם הארוע',
            hebrewNumToDay: hebrewNumToDay[1],
            hebrewNumToMonth: hebrewNumToMonth[1],
            description: '',
            id: new Date().getTime()
        }
        this.events.push(defaultEvent);
        this.saveEvents();
        console.log("---this.events =",this.events)
    }

    getEvents(){
        return [...this.events];
    }
}


const hebrewDatesManager = new HebrewDatesManager();



const PersonalHebrewDatesManager = (props)=>{

    const [items, setItems] = useState(hebrewDatesManager.getEvents());
    const [loading, setLoading] = useState(false);

    const renderItems = ()=>{
        setLoading(true);
        setTimeout(()=>{
            const eventsToUpdate = hebrewDatesManager.getEvents();
            console.log("eventsToUpdate:",eventsToUpdate);
            setItems(eventsToUpdate);
            setLoading(false);
        },200);


    }

    if(loading){
        return <Loading/>
    }


    return <div className={'personal-hebrew-dates-manager'}>
        <div className={'personal-hebrew-dates-manager-title'}>
            <TextViewer text={'hebrew_dates_manager'}/>
        </div>
        <div className={'personal-hebrew-dates-manager-items'}>
            {items.map((item, index)=><PersonalHebrewDatesManagerItem {...item} index={index} hebrewDatesManager={hebrewDatesManager} renderItems={renderItems}/>)}
        </div>
        <div className={'add-hebrew-date-button'} onClick={()=>{
            hebrewDatesManager.addEvent();
            setItems(hebrewDatesManager.getEvents());
        }}>
            <img src={add_yellow_circle_logo} alt="icon"/>
        </div>
    </div>
}

export default PersonalHebrewDatesManager;