import React, {useEffect, useState} from 'react';
import './Notifications.scss';

import SingletoneStoreClass from "../../../Store/Store";
import TextViewer from "../../../helpers/textViewer";
import NotificationsManager from './classes/notificationsManager';
import moment from "moment";
import {Link} from "react-router-dom";
import utils from "../../../helpers/utils";
import {analyticsAddLogEvent} from "../../../firebase/firebase";


const store = SingletoneStoreClass.getInstance();

const Notifications = (props)=>{

    const [loading, setLoading] = useState(true);
    const [notificationsManager, setNotificationsManager] = useState(null);

    useEffect(() => {
        const fetchData = async ()=>{
            let [{ extraDetails }, unisynData] = await Promise.all([store.getSynagoguePublicValues({keys: ['extraDetails', 'updates']}), store.getUnisynData()]);
            const {attachmentUrl, externalFormUrl} = extraDetails;
            const notificationsManager = NotificationsManager.getInstance({updates: extraDetails.updates || [], attachmentUrl, externalFormUrl, unisynData});
            setNotificationsManager(notificationsManager);
            notificationsManager.setAllNotificationsRead();
            setLoading(false);
        };
        fetchData();
        analyticsAddLogEvent({eventName: 'notification_event', params: { name: utils.getSynagogueName()}});
    }, []);

    if(loading || notificationsManager === null ){
        return  <TextViewer text={"loading"} addedString={'...'}/>
    }

    return <div className={'notifications-container'}>
        <TextViewer text={"notifications"} className={'notifications-title'}/>
        <div className={"notifications-array"}>
            <div className={'notification-item-end-line'}></div>
            {notificationsManager.getNotifications().sort((a,b)=>new Date(b.date) - new Date(a.date)).map((notification, counter)=>{
                console.log(notification.type);
                let body;
                const { text, type, onclick } = notification;
                switch (type){
                    case 'TEXT':
                        body = <div className={'notification-item-body'}>{text}</div>;
                        break;
                    case 'IMAGE':
                        body = <div className={'notification-item-body'}>{text}</div>;

                        if(notification.text.includes('pdf')){
                            body = <div className={'notification-item-body'}><div className={'attachment-item'} ><iframe src={text}/></div></div>
                        }else{
                            body = <div className={'notification-item-body'}><div className={'attachment-item'}><img src={text} alt="avatar" /></div></div>
                        }

                        break;
                    case 'LINK':
                        body = <Link to={`/${utils.getSynagogueName()}/external-url`}>
                            <div className={'notification-item-body'}>{<TextViewer className={'link-item'} text={'click_to_fill_out_the_online_form' }/>}</div>
                        </Link>
                        break;
                    case 'FILL_PERSONAL_INFO':
                        body = <Link to={`/${utils.getSynagogueName()}/personal_info`}>
                            <div className={'notification-item-body'}>{<TextViewer className={'link-item'} text={'please_fill_prayer_card' }/>}</div>
                        </Link>
                        break;
                    case 'UNISY':
                        body = <Link to={`/${utils.getSynagogueName()}/unisyn`}>
                            <div className={'notification-item-body'}>{<TextViewer className={'link-item'} text={'union_of_synagogues_has_change' }/>}</div>
                        </Link>
                        break;
                        case 'ENABLE_PUSH_NOTIFICATION':
                        body = <div className={'notification-item-body'} onClick={onclick}>
                            <div>{<TextViewer className={'link-item'} text={'enable_push_notifications_title' }/>}</div>
                                <div>{<TextViewer className={'link-item'} text={'enable_push_notifications_details' }/>}</div>
                        </div>
                        break;
                    default:
                        body = <div className={'notification-item-body'}>{notification.text}</div>;
                        break;
                }
                return <div className={`notification-item ${notificationsManager.isNotificationNotRead(notification.name) ? 'not-read' : ''}`}>
                    <div className={'notification-item-title'}><TextViewer text={notification.title}/></div>
                    {body}
                    <div className={'notification-item-date'}>{ moment(notification.date).format("dddd, MMMM Do YYYY, h:mm:ss")}</div>
                    <div className={'notification-item-end-line'}></div>
                </div>

            })}
        </div>
    </div>
}
export default Notifications;