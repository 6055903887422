import React, {useEffect, useState} from 'react';
import './EventItem.scss';
import utils from "../../../../../../helpers/utils";
import TextViewer from "../../../../../../helpers/textViewer";
import Icon from "@ant-design/icons";

const EventItem = (props) => {

    const { date, name, description, link, by, where, icon, isEventPass, isNearestEvent, plasmaMode=false, minyan } = props;

    const [open, setOpen] = useState(false);

    if(plasmaMode) {
        return <div className={`display-event-container ${isEventPass ? 'past-date' : ''}${isNearestEvent ? 'is-nearest-event' : ''}` } id={isNearestEvent ? 'nearest-event-time' : ''}>
            <div className={'display-event-name-and-icon'}>
                <Icon component={icon} className={'event-icon'}/>
                <div className={'display-event-name'}> <TextViewer text={name}/></div>
            </div>
            <div className={'display-event-time'}>
                {utils.dateToHH_MM(date)}
            </div>
            <div className={'divider-event'}/>
        </div>
    }

    return <div className={`display-event-container ${isEventPass ? 'past-date' : ''}${isNearestEvent ? 'is-nearest-event' : ''}` } id={isNearestEvent ? 'nearest-event-time' : ''}>
        <div className={'display-event-empty'}/>
        <Icon component={icon} className={'event-icon'}/>
        <div className={'display-event-empty'}/>
        <div className={'display-event-time'}>
            {utils.dateToHH_MM(date)}
        </div>
        <div className={'display-event-empty-middle'}/>
        <div className={'display-event-body'}>
            <div className={'display-event-name'}> <TextViewer text={name}/></div>
            {description ? <div className={'display-event-description'}>{description}</div> : null}
            {minyan ? <div className={'display-event-description'}>{minyan}</div> : null}
            {open ? <div className={'display-event-description-more'}>
                {where ? <div className={'display-event-where'}>{where}</div> : null}
                {by ? <div className={'display-event-by'}>{by}</div> : null}
            </div> : null}

        </div>
        <div className={'display-event-more-less'} onClick={()=>setOpen(!open)}>{open ?  <TextViewer text={(where || by) ? "less" : ''}/> : <TextViewer text={(where || by) ? "more" : ''}/>}</div>
        <div className={'display-event-actions'}>
            {link ? <div className={'display-event-action'} onClick={()=> window.open(link, "_blank")}>Zoom</div> : null}

        </div>
    </div>
}


export default EventItem;