import React, {Component} from 'react';
import {Input, Button, Alert} from 'antd';
import SingletoneStoreClass from "../../Store/Store";
import TextViewer from "../../helpers/textViewer";

import "./Auth.css";

const store = SingletoneStoreClass.getInstance();


class Auth extends Component {
    constructor(props) {
        super(props);

        const sessionId = localStorage.getItem('session_login');


        this.state = {
            userName: sessionId ? decodeURIComponent(atob(sessionId)).split('[::]')[0] : null,
            password: sessionId ? decodeURIComponent(atob(sessionId)).split('[::]')[1] : null,
            loading: false,
            errorMsg: null,
            isAuth: false,
            autoSavePassword: true
        }
    }

    onUserNameChange(element) {
        this.setState({userName: element.target.value, errorMsg: null});
    }

    onPasswordChange(element) {
        this.setState({password: element.target.value, errorMsg: null});
    }

    async onConnectClicked() {
        this.setState({loading: true, errorMsg: null});
        try {
            let isAuth = await store.makeAuthenticate(this.state.userName, this.state.password);
            if(isAuth && this.state.autoSavePassword) localStorage.setItem('session_login', btoa(encodeURIComponent(`${this.state.userName}[::]${this.state.password}`)));
            this.setState({loading: false, isAuth});
            this.props.rerenderParentCallback();
        } catch (e) {
            console.error(`error while trying to authenticate: ${e}`);
            this.setState({loading: false, errorMsg: "שגיאה"});
        }
    }

    _disableSending(){
        if(!this.state.userName || !this.state.password) return true;
        if(this.state.loading) return true;
        return false;
    }

    onSavePasswordClicked(event){
        this.setState({autoSavePassword: event.target.checked});
        if(!event.target.checked){
            localStorage.removeItem('session_login');
        }
    }

    render() {
        return (
            <div className={"auth-container"}>
                <Input placeholder="שם משתמש/טלפון" onChange={this.onUserNameChange.bind(this)} disabled={this.state.loading} value={this.state.userName}/>
                <Input.Password placeholder="סיסמא" onChange={this.onPasswordChange.bind(this)} disabled={this.state.loading} value={this.state.password}/>
                <Button loading={this.state.loading} disabled={this._disableSending()}
                        onClick={this.onConnectClicked.bind(this)}><TextViewer text={'connect'}/></Button>
                {this.state.errorMsg ? <Alert message={this.state.errorMsg} type="error"/> : null}
                <div className={'auto-save-password'}>
                    <input type={'checkbox'} onClick={this.onSavePasswordClicked.bind(this)} checked={this.state.autoSavePassword}/>
                    <TextViewer text={'save_password'}/>

                </div>
            </div>
        )
    };
}

export default Auth;

