import React, {useState, useEffect} from 'react';

import '../PrayersDetails/PrayersDetails.scss';
import './AliyaPanels.scss';
import utils from "../../../../helpers/utils";
import TextViewer from "../../../../helpers/textViewer";
import Loading from "../../Main/Loading/Loading";
import AliyahPanel from './AliyahPanel/AliyahPanel';
import add_yellow_circle_logo from "../../icons/add_yellow_circle.svg";
import {useHistory} from "react-router-dom";
import moment from "moment";
import SingletoneStoreClass from "../../../../Store/Store";
import PDFGenerator from "../../PDFGenerator/PDFGenerator";
import delete_icon from "../../icons/delete.svg";
import pdf_icon from "../../icons/pdf.svg";
import AliyotBulkPanel from './AliyahPanel/AliyotBulkPanel';
const store = SingletoneStoreClass.getInstance();


const AliyahPanels = (props) => {

    const { loading, users = [], updateData, synagogueName, base64Logo } = props;
    const aliyot = [];
    users.forEach((user, userIndex)=>{
        if(user.aliyot){
            user.aliyot.forEach((aliya, index)=>{
                aliyot.push({...aliya, user, aliyahIndex: index, userIndex });
            })
        }
    })
    const [checkedItemsMap, setCheckedItemsMap] = useState({});
    const [indexToRender, setIndexToRender] = useState(-1);
    const [aliyahIndex, setAliyahIndex] = useState(-1);
    const [search, setSearch] = useState('');
    const [displayOneMode, setDisplayOneMode] = useState(false);
    const [cloneMode, setCloneMode] = useState(false);

    const history = useHistory();

    const parashaMap = {};

    useEffect(() => {
        const searchParams = window.location.search;

        if(searchParams.includes('?search=')){
            setSearch(decodeURI(searchParams.split('?search=')[1]))
        }

    }, []);


    // useEffect(() => {
    //     console.log("---AliyahPanels=", {checkedItemsMap, displayOneMode, aliyot})
    // }, [checkedItemsMap, displayOneMode, aliyot]);


    const onAddItemClicked = ()=>{
        setIndexToRender(-1);
        setAliyahIndex(-1);
        setCloneMode(false);
        history.push({
            hash: 'aliyah_panel',
            search: 'display_one'
        });
        setDisplayOneMode(true);
    }

    const renderItem = (aliyah, userIndex, aliyahIndex)=>{
        const renderKeyValue = ({key, value})=>{
            return <div className={'key_value'}>
                <div className={'key'}> <TextViewer text={key} addChar={':'}/></div>
                <div className={'value'}>{value}</div>
            </div>
        }

        const onCheckClicked = (event)=>{
            const obj = JSON.parse(JSON.stringify(checkedItemsMap));
            if(event.target.checked){
                obj[aliyahIndex] = true;
            }else{
                delete obj[aliyahIndex]
            }
            setCheckedItemsMap(obj);
        }


        const onItemClicked = ()=>{
            setIndexToRender(userIndex);
            setAliyahIndex(aliyah.aliyahIndex);
            setDisplayOneMode(true);
            setCloneMode(false);
            history.push({
                hash: 'aliyah_panel',
                search: 'display_one'
            })
        }

        const onCloneClicked = ()=>{
            setIndexToRender(userIndex);
            setAliyahIndex(aliyah.aliyahIndex);
            setCloneMode(true);
            history.push({
                hash: 'aliyah_panel',
                search: 'display_one'
            });
            setDisplayOneMode(true);
        }

        const calcTimeDiff = ()=>{
            const diff = moment(new Date()).diff(moment(new Date(aliyah.aliyahTime)), 'days');
            if(diff === 0){
                return TextViewer({text: 'today', returnString: true})
            }else if(diff <= 30){
                return `${diff} ${TextViewer({text: 'days', returnString: true})}`
            }else if(diff >= 31 && diff<= 364){
                const mounts = Math.round(diff/30)
                return `${mounts} ${TextViewer({text: 'mounts', returnString: true})}`
            }else{
                const years = Math.round(diff/365)
                return `${years} ${TextViewer({text: 'years', returnString: true})}`
            }
        }

        const parash = store.getParasha(null, new Date(aliyah.aliyahTime));
        const showParash = !parashaMap[parash];
        parashaMap[parash] = true;

        return <div className={isDisplayed(aliyah) ? '' : 'dont-show'}>
            {showParash ? <div className={'parasha-viewer'}>{`-${parash}-`}</div> : null}
            <div className={'list-item'}>
                <div className={'checkbox'}>
                    <input type="checkbox" onClick={(event)=>onCheckClicked(event)} checked={checkedItemsMap[aliyahIndex]}/>
                </div>
                <div className={'preview-item'} onClick={onItemClicked}>
                    {renderKeyValue({key: 'name', value: `${aliyah.user.name} ${aliyah.user.family}`})}
                    {renderKeyValue({key: 'type', value: aliyah.user.type})}
                    {renderKeyValue({key: 'when', value: utils.calcTimeDiff({stringDate: aliyah.aliyahTime, TextViewer})})}
                </div>
                <div className={'list-item-divider'}></div>
                <button className={'clone-item-button'} onClick={onCloneClicked}><TextViewer text={'clone'}/></button>

            </div>
        </div>
    }

    if(loading) return <Loading/>

    if(window.location.search.includes('display_one')){

        try{
            let searchParams = window.location.search;
            if(searchParams.startsWith('?')) searchParams = searchParams.substring(1);
            searchParams = JSON.parse('{"' + decodeURI(searchParams).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')

            const {date, parasha} = searchParams;
            if(date && parasha){
                return <AliyotBulkPanel users={users} parasha={parasha} date={date} updateData={updateData} close={()=>{
                    setAliyahIndex(-1);
                    setIndexToRender(-1);
                    history.push({
                        hash: 'aliyah_panel',
                    })}}/>
            }
        }catch (e){
            console.warn("get parse searchParams");
        }


        return <AliyahPanel users={users} index={indexToRender} aliyahIndex={aliyahIndex} cloneMode={cloneMode} close={()=>{
            setAliyahIndex(-1);
            setIndexToRender(-1);
            history.push({
                hash: 'aliyah_panel',
            })
        }} updateData={updateData}/>
    }

    const isDisplayed = (item)=>{
        if(!search) return true;
        if(item?.user?.name?.includes(search) || item?.user?.family?.includes(search) || `${item?.user?.name} ${item?.user?.family}`.includes(search)) return true;
        return false;
    }

    const sortAliyot = ()=>{
        const sortedArray = aliyot.sort((a,b)=>new Date(b.aliyahTime) - new Date(a.aliyahTime));
        return sortedArray;
    }

    const onSelectAllClicked = (event)=>{
        if(event.target.checked){
            const newCheckedItemsMap = {};
            aliyot.forEach((aliyah, index)=>{
                newCheckedItemsMap[index] = true;
            })
            setCheckedItemsMap(newCheckedItemsMap);
        }else{
            setCheckedItemsMap({});
        }
    }

    const onDeleteClicked = ()=>{
        let deleteAmount = 0;
        Object.keys(checkedItemsMap).forEach(key=>{
            if(checkedItemsMap[key]) deleteAmount++;
        })
        const text = TextViewer({text: 'are_u_sure_delete_items', addChar: '?', returnString: true});

        if (window.confirm(text.replace('X', deleteAmount)) == true) {
            let updatedItems = JSON.parse(JSON.stringify(users));
            Object.keys(checkedItemsMap).forEach(key=>{
                const {name, family, phoneNumber} = aliyot[key].user;
                const userToUpdate = updatedItems.find(user=>user.name === name && user.family === family && user.phoneNumber === phoneNumber);
                if(!userToUpdate){
                    console.warn("can't find user to delete:", aliyot[key].user);
                    return;
                }
                userToUpdate.aliyot[aliyot[key].aliyahIndex] = null;
            })
            updatedItems.forEach(user=>{
                if(Array.isArray(user.aliyot)) user.aliyot = user.aliyot.filter(u=>u);
            })

            updateData({fieldName: 'manager', data: updatedItems});
            setCheckedItemsMap({});
        }

    }


    return <div className={'list-details-container'}>
        <div className={'list-details-container-title'}>
            <TextViewer text={'aliyah_panel'}/>
            <input value={search} placeholder={' 🔎 חיפוש'} onChange={(event)=>setSearch(event.target.value)}/>
        </div>
        <div className={'list-details-container-actions'}>
            <div className={'select-all-container'}>
                <input type="checkbox" name="scales" onClick={onSelectAllClicked}/>
                <label className={'select-all-label'}><TextViewer text={'choose_all'}/></label>
            </div>
            <button className={'delete-item-button'}  onClick={onDeleteClicked} disabled={Object.keys(checkedItemsMap).length === 0}><TextViewer text={'delete'}/></button>
        </div>
        <div className={'add-item-button'} onClick={onAddItemClicked}>
            <img src={add_yellow_circle_logo} alt="icon"/>
        </div>

        <div className={'list-container'}>
            {sortAliyot().map((aliyah, index)=>{
                return renderItem(aliyah, aliyah.userIndex, index)
            })}
        </div>
    </div>
}

export default AliyahPanels;