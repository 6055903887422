import React, {useEffect, useState} from 'react';
import './FirstOnBoarding.scss';
import TextViewer from "../../../../helpers/textViewer";
import triangle from '../../icons/backgrounds/triangle-background.webp'

const FirstOnBoarding = (props)=>{
    const [haveClicked, setHaveClicked] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setTimeout(()=>{
            setShow(true);
        }, 5000)
    }, []);

    if(!localStorage.getItem('FIRST_ON_BOARDING')) return null;
    if(haveClicked) return null;
    if(!show) return null;


    const onClick = ()=>{
        const sideMenu = document.getElementById('side-menu-dots');
        sideMenu.className = 'side-menu-container open'
        const managementBottom = document.getElementById('side-menu-item-text-gabbai-area');
        if(!managementBottom) return;
        let opacity = 0;
        let fadeIn = setInterval(() => {
            if (opacity >= 1) {
                clearInterval(fadeIn);
            }
            managementBottom.style.background = '#ECD50A';
            managementBottom.style.opacity = opacity;
            managementBottom.style['border-radius'] = '12px';
            opacity += 0.01;
        }, 10);

        setHaveClicked(true);
        localStorage.removeItem('FIRST_ON_BOARDING');

    }

    return <div className={'first-on-boarding'} style={{ backgroundImage: `url(${triangle})` }}>
        <TextViewer text={"gabbai"} addChar={','}/>
        <TextViewer text={"to_edit_mode"} addChar={':'}/>
        <TextViewer text={"click_here"} onClick={onClick} className={'click-here'}/>
    </div>
}

export default FirstOnBoarding