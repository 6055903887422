import axios from 'axios'
import config from "../config"


function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}
class ApiManager{
    constructor() {
        this.state= {
            name: null,
            cityName: null,
            donation: null,
            dvarTora: null,
            extraDetails: null,
            info: null,
            holidayTimes: null,
            location: null,
            logicalName: null,
            tfilotTimes: null,
            updates: null,
            times: null
        }
        this.loading = false;
        this.successfull = false;
    }

    _addLocationToConfig(){
        let cityExistInConfigLocations = config.cityLocations.find(cityLocation => cityLocation === this.state.name);
        if (cityExistInConfigLocations) {
            cityExistInConfigLocations.showFirst = true;
            console.log(`the city: ${this.state.name} exist on config, set show first to true!`)
        } else {
            config.cityLocations.push({
                pos: this.state.location,
                name: this.state.name,
                showFirst: true
            });
            console.log(`the city: ${this.state.name} doesn't exist on config, added + set show first to true!`);
        }
    }

    async getSynagoguePublicData({synagogueId}){
        try{
            this.loading = true;
            const response = await axios(`${config.sourceUrl}/api/v1/synagogues/${synagogueId}`);
            this.state.name = response.data.name;
            this.state.cityName = response.data.cityName;
            this.state.donation = response.data.donation;
            this.state.dvarTora = response.data.dvarTora;
            this.state.extraDetails = response.data.extraDetails;
            this.state.info = response.data.info;
            this.state.holidayTimes = response.data.holidayTimes;
            this.state.location = response.data.location;
            this.state.logicalName = response.data.logicalName;
            this.state.tfilotTimes = response.data.tfilotTimes;
            this.state.lessonsTimes = response.data.lessonsTimes;
            this.state.updates = response.data.updates;
            this.state.times = response.data.times;
            this.loading = false;
            this.successfull = true;
        }catch (e){
            throw e;
        }finally {
            this.loading = false;
        }

    }

    async getValues({keys, synagogueId, ignoreCache}){


        // if(!this.successfull && this.loading){
        //     //already have request that wait to response...
        //     console.log("%%%%%% wait!!!!!!!!");
        //     await delay(10);
        //     return this.getValues({keys, synagogueId, ignoreCache});
        // }


        if(!this.successfull || ignoreCache){
            console.info(`getting keys: ${keys} for synagogueId: ${synagogueId}`);
            await this.getSynagoguePublicData({synagogueId});
            this._addLocationToConfig();
        }else {
            console.info(`return key: ${keys} from memory!`);
        }
        return keys.reduce((result, key)=> {
            result[key] = this.state[key];
            return result;
        }, {})

    }
}

export default ApiManager;