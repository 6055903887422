const DONATION_CONFIG = {
    user: {
        keyName: 'user',
        type: 'select_user',
        text_view_name: 'select_prayer',
        required: true,
        default: ''
    },
    donationTime: {
        keyName: 'donationTime',
        text_view_name: 'donation_time',
        type: 'selectDate',
        required: true,
        default: 'DATE_TODAY'
    },
    donationReason: {
        keyName: 'donationReason',
        text_view_name: 'donation_reason',
        type: 'textarea',
        required: false,
        default: ''
    },
    donationTotalPayment: {
        keyName: 'donationTotalPayment',
        text_view_name: 'donation_total_payment',
        type: 'number',
        required: true,
    },
    donationSubPayment: {
        keyName: 'donationSubPayment',
        text_view_name: 'sub_payment',
        type: 'number',
        required: false,
    },
    isPayed: {
        keyName: 'isPayed',
        text_view_name: 'is_payed',
        type: 'checkbox',
        required: false,
        default: false,
    }
}

export default DONATION_CONFIG;