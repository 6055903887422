import { getClientToken, isPushNotificationSupported } from '../../../../firebase/firebase';
import TextViewer from "../../../../helpers/textViewer";
import React from "react";
import utils from "../../../../helpers/utils";

const NOTIFICATION_TYPES = {
    TEXT: 'TEXT',
    IMAGE: 'IMAGE',
    LINK: 'LINK',
    UNISY: 'UNISY',
    ENABLE_PUSH_NOTIFICATION: 'ENABLE_PUSH_NOTIFICATION',
    FILL_PERSONAL_INFO: 'FILL_PERSONAL_INFO',
}

class BaseNotification {
    constructor({name, title, text, objFromLocalStorage = {}}) {
        let haveRead = false;
        if(!objFromLocalStorage.date){
            //first time have created, no local storage
            haveRead = false;
        }
        if(objFromLocalStorage.text){
            //already exist on local storage
            haveRead = objFromLocalStorage.text === text;
        }

        let date = objFromLocalStorage.date || new Date();
        if(!haveRead) date = new Date();

        this.name = name;
        this.title = title;
        this.date = date;
        this.text = text;
        this.haveRead = haveRead;
        this.type = NOTIFICATION_TYPES.TEXT;
    }

    markRead(){
        this.haveRead = true;
    }

}

class AttachmentNotification extends BaseNotification {
    constructor({name, title, date, text, haveRead, objFromLocalStorage}) {
        super({name, title, date, text, haveRead, objFromLocalStorage});
        this.type = NOTIFICATION_TYPES.IMAGE;
    }
}

class ExternalFormUrlNotification extends BaseNotification {
    constructor({name, title, date, text, haveRead, objFromLocalStorage}) {
        super({name, title, date, text, haveRead, objFromLocalStorage});
        this.type = NOTIFICATION_TYPES.LINK;
    }
}

class UnisyNotification extends BaseNotification {
    constructor({name, title, date, text, haveRead, objFromLocalStorage}) {
        super({name, title, date, text, haveRead, objFromLocalStorage});
        this.type = NOTIFICATION_TYPES.UNISY;
    }
}

//notifications that don't use locaStorage:
class EnablePushNotification {
    constructor({name, title}) {

        this.name = name;
        this.title = title;
        this.date = new Date();
        this.haveRead = false;

        this.type = NOTIFICATION_TYPES.ENABLE_PUSH_NOTIFICATION;
    }

    markRead(){
        this.haveRead = true;
    }

    onclick(){
        console.log("[EnablePushNotification] - clicking to enable push notifications!")
        getClientToken();
    }

    static showPushNotification(){
        const DEBUG_MODE = window.location.pathname.includes("habani-rosh-hayain")

        if(!isPushNotificationSupported()){
             console.warn("[EnablePushNotification] - push notification not support for this device.");
            // if( DEBUG_MODE) alert("[EnablePushNotification] - push notification not support for this device.")

            return false;
         }
        let fcmToken = localStorage.getItem("fcmToken") || null;

        if( fcmToken && window.Notification.permission === 'granted'){
            console.info('[EnablePushNotification] - user have granted Notification modal :)')
            // if( DEBUG_MODE) alert("[EnablePushNotification] - user have granted Notification modal :)")

            return false;
        }
        if( window.Notification.permission === 'denied'){
            console.info('[EnablePushNotification] - user have denied Notification modal :( ')
            // if( DEBUG_MODE) alert("[EnablePushNotification] - user have denied Notification modal :( ")
            return false;
        }
        return true;

    }
}

class FillPersonalInfo {
    constructor({name, title}) {

        this.name = name;
        this.title = title;
        this.date = new Date();
        this.haveRead = false;

        this.type = NOTIFICATION_TYPES.FILL_PERSONAL_INFO;
    }

    markRead(){
        this.haveRead = true;
    }
    static showPushNotification(){
        return !localStorage.getItem("UPDATE_PRAYER");
    }
}


class NotificationsManager{
    constructor({updates, attachmentUrl, externalFormUrl, unisynData, fillPersonalInfo}) {
        const notificationsFromLocalStorage = this.getFromLocalStorage() || {};
        this.notofications = {};
        this.notificationsFirstTimeRead = {};
        if(EnablePushNotification.showPushNotification()) this.notofications.enablePushNotification = new EnablePushNotification({
            name: 'enablePushNotification',
            title: 'enable_push_notifications'
        });

        if(FillPersonalInfo.showPushNotification()) this.notofications.fillPersonalInfo = new FillPersonalInfo({
            name: 'prayer_card',
            title: 'prayer_card'
        });

        utils.filterUpdates({updates}).forEach((update)=>{
            const {text, title, attachmentUrl} = update;
            if(attachmentUrl){
                const id = attachmentUrl;
                this.notofications[`update_${id}`] = new AttachmentNotification({
                    name: 'attachmentUrl',
                    title: title || 'notice',
                    text: attachmentUrl,
                    objFromLocalStorage: notificationsFromLocalStorage[`update_${id}`]
                })
            }else{
                const id = `${text}-${title}`
                this.notofications[`update_${id}`] = new BaseNotification({
                    name: [`update_${id}`],
                    title: title || 'gabaais_updates',
                    text: text,
                    objFromLocalStorage: notificationsFromLocalStorage[`update_${id}`]
                });
            }

        })

        if(externalFormUrl) this.notofications.externalFormUrl = new ExternalFormUrlNotification({
            name: 'externalFormUrl',
            title: 'link_to_external_form_url',
            text: externalFormUrl,
            objFromLocalStorage: notificationsFromLocalStorage.externalFormUrl
        })
        if(unisynData) this.notofications.unisy = new UnisyNotification({
            name: 'unisy',
            title: 'union_of_synagogues',
            text: `${unisynData.bodyMessage}-${unisynData.subTitle}-${unisynData.title}`,
            objFromLocalStorage: notificationsFromLocalStorage.unisy
        })

    }

    isNotificationNotRead(name){
        return !!this.notificationsFirstTimeRead[name];
    }

    getNotifications(){
        return Object.values(this.notofications);
    }

    getLength(){
        return Object.keys(this.notofications).length;
    }

    getUnreadNotifications(){
        let counter = 0;
        Object.values(this.notofications).forEach(notification=>{
            if(!notification.haveRead){
                counter++;
            }
        });
        return counter;
    }

    setAllNotificationsRead(){
        Object.values(this.notofications).forEach(notification=>{
            if(!notification.haveRead){
                this.notificationsFirstTimeRead[notification.name] = true;
            }else{
                delete this.notificationsFirstTimeRead[notification.name];
            }
            notification.markRead();
        });
        this.saveToLocalStorage();
    }

    getFromLocalStorage(){
        try{
            if(window.localStorage){
                let notificationsData = window.localStorage.getItem('notificationsData');
                if(!notificationsData) return null;
                notificationsData = JSON.parse(notificationsData);
                return notificationsData;
            }
            return null;
        }catch (e){
            console.error(`NotificationsManager-getFromLocalStorage error:`,e);
            return null;
        }
    }

    saveToLocalStorage(){
        try{
            if(window.localStorage){
                window.localStorage.setItem('notificationsData', JSON.stringify(this.notofications));
            }
            return null;
        }catch (e){
            console.error(`NotificationsManager-saveToLocalStorage error:`,e);
            return null;
        }
    }

    static createInstance({updates, attachmentUrl, externalFormUrl, unisynData}) {
        var object = new NotificationsManager({updates, attachmentUrl, externalFormUrl, unisynData});
        return object;
    }

    static getInstance ({updates, attachmentUrl, externalFormUrl, unisynData}) {
        if (!NotificationsManager.instance) {
            NotificationsManager.instance = NotificationsManager.createInstance({updates, attachmentUrl, externalFormUrl, unisynData});
        }
        return NotificationsManager.instance;
    }


}

export default NotificationsManager