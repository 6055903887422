import React, {useEffect, useState} from 'react';
import './BottomButtons.scss';
import TextViewer from "../../../../helpers/textViewer";
import {ReactComponent as feedback_logo} from "../../icons/feedback.svg";
import {ReactComponent as share_logo} from "../../icons/share.svg";
import Icon from '@ant-design/icons';
import utils from "../../../../helpers/utils";

const BottomButtons = (props)=>{

    const onShareClicked = async () => {
        await utils.shareApp();
    };
    const onFeedbackClick = () => {
        utils.feedbackApp();
    };

    const {loading} = props;

    if(loading) return null;

    return <React.Fragment>
        <div className={'bottom-buttons-container'}>
            <div className={'bottom-button'} onClick={onFeedbackClick}>
                <div className={'bottom-button-icon'}>
                    <Icon component={feedback_logo}/>
                </div>
                <div className={'bottom-button-text'}>
                    <TextViewer className={'bold-text'} text={"feedback_us"}/>
                    <TextViewer text={"its_free"}/>
                </div>
            </div>
            <div className={'bottom-button'} onClick={onShareClicked}>
                <div className={'bottom-button-icon'}>
                    <Icon component={share_logo}/>
                </div>
                <div className={'bottom-button-text'}>
                    <TextViewer text={"sharing_is_caring_1"}/>
                    <TextViewer className={'bold-text'} text={"sharing_is_caring_2"}/>
                </div>
            </div>
        </div>
    </React.Fragment>

}

export default BottomButtons;