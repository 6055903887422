import config from '../../../../../config';

const arrayRange = (start, stop, step) =>
    Array.from(
        { length: (stop - start) / step + 1 },
        (value, index) => start + index * step
    );

const TIME_CONFIG = {
    type: {
        keyName: 'type',
        text_view_name: 'event_type',
        type: 'select',
        options: ["tfila","lesson"],
        required: true,
        default: 'tfila'
    },
    name: {
        keyName: 'name',
        text_view_name: 'event_name',
        required: true,
        placeholder: 'event_name_placeholder',
        default: ''
    },
    days: {
        keyName: 'days',
        text_view_name: 'days',
        type: 'select',
        multipleSelect: true,
        options: ["sunday","monday","tuesday","wednesday","thursday","friday","saturday"],
        required: true,
        default: [],
        regular: true
    },
    importantDates: {
        keyName: 'importantDates',
        text_view_name: 'enable_holiday_event',
        type: 'select',
        options: config.importantDates,
        defaultOption: 'choose_holiday',
        viewOptions: (optionObj)=>`${optionObj.name}${typeof optionObj.hebrewDayAtMonth === 'number' ? ` - ${config.hebrewNumToDay[optionObj.hebrewDayAtMonth]} ${config.englishMonthToHebrew[optionObj.hebrewMonth]}` : ''}`,
        required: true,
        holiday: true,
        default: null
    },
    location: {
        keyName: 'location',
        text_view_name: 'location',
        required: false,
        default: ''
    },
    minyan: {
        keyName: 'minyan',
        text_view_name: 'minyan_name',
        required: false,
        placeholder: 'event_name_placeholder',
        placeholderCallBack: (minyanNames)=> minyanNames.join(' / '),
        shoyMinyanList: true,
        default: ''
    },
    extraDetails: {
        keyName: 'extraDetails',
        text_view_name: 'extra_details',
        required: false,
        default: ''
    },
    when: {
        keyName: 'when',
        text_view_name: 'when',
        type: 'time',
        required: true,
        default: '08:00'
    },
    isDynamic: {
        keyName: 'isDynamic',
        text_view_name: 'is_dynamic',
        type: 'checkbox',
        required: false,
        default: false,
        dropDownView: true
    },
    dynamic: {
        keyName: 'dynamic',
        text_view_name: 'לפי',
        type: 'select',
        options: ["ENTER_SHABAT","EXIT_SHABAT","SUNRISE","SUNSET","TZEIT","PLAG_MINHA"],
        required: false,
        default: "ENTER_SHABAT",
        dynamicKey: true,
        dropDownView: true
    },
    add: {
        keyName: 'add',
        text_view_name: 'ועוד/פחות',
        type: 'select',
        options:  arrayRange(-60, 60, 1),
        required: false,
        default: '0', //may be an issue
        dynamicKey: true,
        dropDownView: true
    },
    maxPerWeek: {
        keyName: 'maxPerWeek',
        text_view_name: 'עגל את הזמן לפי השעה המאוחרת ביותר בשבוע',
        type: 'checkbox',
        required: false,
        default: false,
        dynamicKey: true,
        dropDownView: true
    },
    minPerWeek: {
        keyName: 'minPerWeek',
        text_view_name: 'עגל את הזמן לפי השעה המוקדמת ביותר בשבוע',
        type: 'checkbox',
        required: false,
        default: false,
        dynamicKey: true,
        dropDownView: true
    },
    round: {
        keyName: 'round',
        text_view_name: 'עגל את הזמן ברמת ה 5 דקות (לדוגמא: 16:23 -> 16:20, 16:24 -> 16.25 )',
        type: 'checkbox',
        required: false,
        default: false,
        dynamicKey: true,
        dropDownView: true
    },
    roundUp: {
        keyName: 'roundUp',
        text_view_name: 'עגל את הזמן ברמת ה 5 דקות למעלה (לדוגמא: 16:21 -> 16:25)',
        type: 'checkbox',
        required: false,
        default: false,
        dynamicKey: true,
        dropDownView: true
    },
    roundDown: {
        keyName: 'roundDown',
        text_view_name: 'עגל את הזמן ברמת ה 5 דקות למטה (לדוגמא: 16:24 -> 16:20)',
        type: 'checkbox',
        required: false,
        default: false,
        dynamicKey: true,
        dropDownView: true
    },
    roshHodeshMinutes: {
        keyName: 'roshHodeshMinutes',
        text_view_name: 'בראש חודש הקדם את התפילה ב X דקות לפני',
        type: 'select',
        options:  arrayRange(0, 30, 1),
        required: false,
        default: 0
    },
    disable: {
        keyName: 'disable',
        text_view_name: 'disable_event',
        type: 'checkbox',
        required: false,
        default: false
    },
    zoomLink: {
        keyName: 'zoomLink',
        text_view_name: 'zoom_link',
        required: false,
        placeholder: 'zoom_link_placeholder',
        default: ''
    }
}

export default TIME_CONFIG;